import { createStore, applyMiddleware, compose, combineReducers } from '@reduxjs/toolkit';
import session from './session';
import thunk from "redux-thunk";
import exchange from "./exchange";
import unitedStates from "./united-states";
import exchangeAttachment from "./exchange-attachment";
import message from "./message";
import ui from "./ui";
import emailTemplate from "./email-template";
import documentMappings from "./document-mappings";
import markup from "./markup";
import userAdmin from "./user-admin";
import contact from "./contact";
import smartTags from "./smart-tags";
import registration from "./registration";
import applicationSetting from "./application-setting";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  session,
  exchange,
  unitedStates,
  exchangeAttachment,
  message,
  ui,
  emailTemplate,
  documentMappings,
  markup,
  userAdmin,
  contact,
  smartTags,
  registration,
  applicationSetting
});

export const store = createStore(rootReducer,
    storeEnhancers(applyMiddleware(thunk)));
