import React, {useEffect} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Toaster} from "react-hot-toast";
import {logUserOut} from "../../actions/ui";
import {useAuth0} from "@auth0/auth0-react";
import {setToken, setUserRoles} from "../../actions/session";
import {ADMIN_ROLE, CONTACT_ROLE, USER_ROLE} from "../../constants/settings";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Menu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, logout, isAuthenticated, getAccessTokenSilently, loginWithPopup } = useAuth0();
    const location = useLocation();

    const roles = useSelector(state => state.session.roles);

    useEffect(() => {
        async function refreshToken () {
            try {
                const token = await getAccessTokenSilently();
                await dispatch(setToken(token));
                await dispatch(setUserRoles(navigate));
            } catch (err) {
                console.log(err);
            }
        }
        if(isAuthenticated){
            refreshToken();
        }
    }, [location, getAccessTokenSilently, dispatch, isAuthenticated, navigate]);

    const signout = () => {
        logout({ logoutParams: {returnTo: window.location.origin }});
        dispatch(logUserOut());
        navigate("/");
    }

    const renderMenu = function () {
        const isAdmin = roles.indexOf(ADMIN_ROLE) >= 0;
        const isUser = roles.indexOf(USER_ROLE) >= 0;
        const isReadOnly = roles.indexOf(CONTACT_ROLE) >= 0;

        if(isAdmin) {
            return (<ul className="navbar-nav">
                <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                              data-bs-toggle="dropdown" aria-expanded="false">
                            Orders
                        </span>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/orders" >New Order</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/" >Orders</Link>
                        </li>
                    </ul>
                </li>
                <li className="nav-item">
                    <Link className={location.pathname.indexOf("/calendar") === 0 ? "nav-link text-decoration-underline" : "nav-link"} to="/calendar" >Calendar</Link>
                </li>
                <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                              data-bs-toggle="dropdown" aria-expanded="false">
                            Documents
                        </span>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/documentMappings" >Document Tagging</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/tags" >Smart Tags</Link>
                        </li>
                    </ul>
                </li>
                <li className="nav-item">
                    <Link className={location.pathname.indexOf("/info") === 0 ? "nav-link text-decoration-underline" : "nav-link"} to="/info" >Company Information</Link>
                </li>
                <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                              data-bs-toggle="dropdown" aria-expanded="false">
                            Admin
                        </span>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/useradmin" >User Administration</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/orderregistrations" >Order Registrations</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="dropdown-item" to="/foldereditor" >Attachment Folder Editor</Link>
                        </li>
                    </ul>
                </li>
            </ul>);
        } else if(isUser) {
            return (<ul className="navbar-nav">
                <li className="nav-item">
                    <Link className={location.pathname.indexOf("/order") === 0 ? "nav-link text-decoration-underline" : "nav-link"} to="/orders">Orders</Link>
                </li>
                <li className="nav-item">
                    <Link className={location.pathname.indexOf("/contact") === 0 ? "nav-link text-decoration-underline" : "nav-link"} to="/contacts" >Contacts</Link>
                </li>
                <li className="nav-item">
                    <Link className={location.pathname.indexOf("/calendar") === 0 ? "nav-link text-decoration-underline" : "nav-link"} to="/calendar" >Calendar</Link>
                </li>
            </ul>);
        } else if(isReadOnly) {
            return (<ul className="navbar-nav">
                <li className="nav-item">
                    <Link className={location.pathname.indexOf("/contact") === 0 ? "nav-link text-decoration-underline" : "nav-link"} to="/contact" >Dashboard</Link>
                </li>
            </ul>);
        } else {
            return (<ul className="navbar-nav">
            </ul>);
        }
    }

    return (<nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
            <div className="container">
                <Toaster />
                <Link to="/" className="navbar-brand"><span style={{color: "#FFC107"}}>app</span>sparc</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
                        aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">&nbsp;</span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    {renderMenu()}
                    <div className="d-flex ms-auto me-2">
                        &nbsp;
                    </div>
                        {isAuthenticated ?
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                                          data-bs-toggle="dropdown" aria-expanded="false">
                                        <FontAwesomeIcon icon={faUser} size={"2x"}/>
                                    </span>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className="nav-item">
                                            <Link className="dropdown-item" to="/settings">{user.name}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <button className="dropdown-item" onClick={signout}>Log Out</button>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            : <ul className="navbar-nav">
                                <li className={"nav-item"}>
                                    <button className="btn btn-link nav-link" onClick={() => loginWithPopup()} >Log In</button>
                                </li>
                            </ul>}
                </div>
            </div>
        </nav>
    )
}

export default Menu;
