import {
    GET_BANK, GET_QI, LOG_OUT, UPDATE_BANK_FIELD, UPDATE_QI_FIELD, UPSERT_BANK, UPSERT_QI

} from '../actions/action-types';

const initialState = {
    bank: {name: "", address: "", city: "", state: "", zip: "", routingNumber: ""},
    qi: {name: "", address: "", city: "", state: "", zip: "", phone: "", pointOfContact: "", fax: ""}
}

const applicationSetting = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case GET_BANK:
        case UPSERT_BANK:
            newState = Object.assign({}, state);
            newState.bank = action.payload
            return newState;

        case GET_QI:
        case UPSERT_QI:
            newState = Object.assign({}, state);
            newState.qi = action.payload;
            return newState;

        case UPDATE_BANK_FIELD:
            newState = Object.assign({}, state);
            let tempBank = Object.assign({}, newState.bank);
            tempBank[action.payload.field] = action.payload.value;
            newState.bank = tempBank;
            return newState;

        case UPDATE_QI_FIELD:
            newState = Object.assign({}, state);
            let tempQi = Object.assign({}, newState.qi);
            tempQi[action.payload.field] = action.payload.value;
            newState.qi = tempQi;
            return newState;

        case LOG_OUT:
            return initialState;

        default:
            return state
    }
}

export default applicationSetting
