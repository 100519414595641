import React from 'react';
import AppRoutes from "./features/routes/app-routes";
import Menu from "./features/menu/menu";

function App() {
    return (
        <div>
            <Menu/>
            <div className="container-xxl" style={{marginTop: "65px"}}>
                <AppRoutes/>
            </div>
            <footer className="footer bg-primary p-1">
                <div className="container">
                    <div className={"float-start text-light"}>
                        <span>&copy; {new Date().getFullYear()} <a className={"link-light"} href={"https://appsparc.com"} target={"_blank"} rel="noreferrer">Appsparc, LLC.</a></span>
                    </div>
                    <div className={"float-end"}>
                        <a className={"link-light"} href={"https://app.termly.io/document/privacy-policy/ed6551da-40eb-4c19-a782-1a6b89428cb4"} target={"_blank"} rel="noreferrer">Privacy Policy</a>
                    </div>

                </div>
            </footer>
        </div>
    );
}

export default App;
