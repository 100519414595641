import PropertyList from "./property-list";
import PropertyAddress from "./property-address";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    clearPropertyAddress,
    loadExchangePropertiesForType,
    selectPropertyAddress,
    setPropertyTab
} from "../../actions/exchange";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import AddressContacts from "./address-contacts";

export const Properties = function (props) {

    const dispatch = useDispatch();

    const {exchangeId, section} = props;
    const propertyTabs = useSelector(state => state.exchange.propertyTabs);
    const selectedPropertyType = useSelector(state => state.exchange.selectedPropertyType);

    const [showModal, setShowModal] = useState(false);

    const generateTabs = function () {
        return propertyTabs.map((t, idx) => <li key={idx} className="nav-item" role="presentation">
            <button className={t.id === selectedPropertyType ? "nav-link active small" : "nav-link small"} type="button" role="tab" onClick={() => dispatch(setPropertyTab(t.id))}>{t.name}
            </button>
        </li>)
    }

    const savePropertyAddress = function (e){
        dispatch(loadExchangePropertiesForType(exchangeId, selectedPropertyType));
    }

    const closeModal = async function () {
        setShowModal(false);
        await dispatch(clearPropertyAddress());
    }

    const openModal = async function (e) {
        await dispatch(selectPropertyAddress(e.data));
        setShowModal(true);
    }

    const handleAddProperty = async function() {
        await dispatch(clearPropertyAddress());
        setShowModal(true);
    }

    return (<div className="card col-12 border-1 p-0 m-0 mt-2 pt-2 px-1 border-dark shadow">
        <div className={"row m-0 p-0"}>
            <div className={"col-12 p-0"}>
                <ul className="nav nav-tabs">
                    {generateTabs()}
                </ul>
            </div>
        </div>
        <div className={"row m-0 p-0 pt-1"}>
            <div className={"col-12 p-0 text-end"}>
                <button className={"btn btn-outline-dark btn-sm"} onClick={handleAddProperty}><FontAwesomeIcon icon={faPlus}/>&nbsp;Add Property</button>
            </div>
        </div>
        <PropertyList exchangeId={exchangeId} rowClickable={true} typeId={selectedPropertyType} openModal={openModal}/>
        {}
        <AddressContacts exchangeId={exchangeId}/>
        <Popup open={showModal} closeOnDocumentClick onClose={closeModal}>
            <div className={"tag-modal"}>
                <button className="btn btn-link link-dark text-decoration-none m-0 p-0 close" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimesCircle}/>
                </button>
                <div className={"content"}>
                    <PropertyAddress exchangeId={exchangeId} section={section} typeId={selectedPropertyType} saveProperty={savePropertyAddress} closeModal={closeModal}/>
                </div>
            </div>

        </Popup>
    </div>);
}