import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    clearSelectedMapping,
    loadDocumentMappings, loadFieldMappings,
    removeDocumentMapping,
    selectDocumentMapping
} from "../../actions/document-mappings";
import {faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function DocumentList () {

    const dispatch = useDispatch();
    const documents = useSelector(state => state.documentMappings.documents);


    const select = async function(mappingId) {
        await dispatch(selectDocumentMapping(mappingId));
        await dispatch(loadFieldMappings(mappingId));
    }

    const newMapping = function(){
        dispatch(clearSelectedMapping());
    }

    const deleteMapping = async function (documentId) {
        if (window.confirm("This mapping will be permanently deleted.")){
            await dispatch(removeDocumentMapping(documentId));
            await dispatch(loadDocumentMappings());
            dispatch(clearSelectedMapping());
        }
    }

    return (<div>
        <div className={"row m-0 p-0"}>
            <div className={"col-12 m-0 p-0 text-end"}>
                <button className={"btn btn-outline-dark me-2 px-1 py-0 mb-2"} onClick={newMapping}><FontAwesomeIcon icon={faPlusCircle} size={"1x"}/>&nbsp;New Tagged Document</button>
            </div>
        </div>
        {documents.map((d, idx) => <div key={idx} className={"clearfix"}><button className={"btn btn-link text-dark p-1 py-0 ms-1 float-start"} title={"Remove Document"} onClick={() => deleteMapping(d.id)}><FontAwesomeIcon icon={faTrash}/></button><button className={"btn btn-link link-dark font-small"} onClick={() => select(d.id)}>{d.name}</button></div>)}
    </div>);
}
