import {
    CLEAR_RECTANGLE, RECTANGLE_SELECTED,
    SET_DRAGGING_MOUSE,
    SET_NUMBER_OF_PAGES,
    SET_PAGE_NUMBER,
    SET_PDF_CANVAS_SIZE, SET_RECTANGLE_SIZE,
    UPDATE_START_POSITION
} from "./action-types";

export const setPdfCanvasSize = function (pdfCanvas){
    return dispatch => {
        dispatch({type: SET_PDF_CANVAS_SIZE, payload: pdfCanvas});
    }
}

export const setPageNumber = function (pageNumber){
    return dispatch => {
        dispatch({type: SET_PAGE_NUMBER, payload: pageNumber});
    }
}

export const setNumberOfPages = function (numberOfPages){
    return dispatch => {
        dispatch({type: SET_NUMBER_OF_PAGES, payload: numberOfPages});
    }
}

export const setDraggingMouse = function (isButtonDown){
    return dispatch => {
        dispatch({type: SET_DRAGGING_MOUSE, payload: isButtonDown});
    }
}

export const updateStartPosition = function (position){
    return dispatch => {
        dispatch({type: UPDATE_START_POSITION, payload: position});
    }
}

export const setRectangleSize = function (size){
    return dispatch => {
        dispatch({type: SET_RECTANGLE_SIZE, payload: size});
    }
}

export const clearRectangle = function (){
    return dispatch => {
        dispatch({type: CLEAR_RECTANGLE});
    }
}

export const rectangleSelected = function (){
    return dispatch => {
        dispatch({type: RECTANGLE_SELECTED});
    }
}