import React, {useEffect} from 'react';
import {AgGridReact} from "ag-grid-react";
import {useDispatch, useSelector} from "react-redux";
import {clearCurrentContact, loadAllContacts} from "../../actions/contact";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";
import {Link, useNavigate} from "react-router-dom";
import {defaultRow} from "../../constants/settings";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Contacts = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const contacts = useSelector(state => state.contact.allContacts);

    const columnDefs = [
        {
            field: 'id'
        },
        {
            field: 'name'
        },
        {
            field: 'emailAddress'
        },
        {
            field: 'homePhone'
        }
    ];

    useEffect(() => {
        dispatch(clearCurrentContact());
        dispatch(loadAllContacts());
    }, [dispatch]);

    const handleRowClicked = (e) => {
        navigate("/contact/" + e.data.id);
    }

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
            <div className="row m-0 p-0">
                <div className="col-1 p-0 pt-3 pt-2 header-column">
                    <h1 className="m-0 p-0 py-2 px-4">Contacts</h1>
                </div>
                <div className="col-11 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                    <div className={defaultRow}>
                        <div className={"col-12 text-end"}>
                            <Link className={"btn btn-outline-dark m-0 p-1"} to={"/contact"} title={"Add Contact"}><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add Contact</Link>
                        </div>
                    </div>
                    <div className={defaultRow}>
                        <div className={"col-12"}>
                            <div className={"ag-theme-alpine"}  style={{height: '85vh'}}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={{
                                        sortable: true,
                                        flex: 1,
                                        filter: true,
                                        resizable: true
                                    }}
                                    suppressCellFocus={true}
                                    onRowClicked={handleRowClicked}
                                    rowData={contacts}>
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(Contacts, {
    onRedirecting: () => <Loading />,
});
