import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faSave, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {
    ADMIN_ROLE,
    defaultRow, infoTabName,
    relinquishedPropertyEscrowTab, replacementPropertyEscrowTab,
    signatureAndVestingTabName,
    USER_ROLE
} from "../../constants/settings";
import {copyContact, createContactUser, loadContactById, saveContact} from "../../actions/contact";
import {
    loadContactTabs,
    updateContactProperty
} from "../../actions/exchange";

export default function Contact (props) {
    const dispatch = useDispatch();
    const {contactId, exchangeId, tabId, typeId, contactSaved, taxpayer} = props;
    const hideUserButton = props.hideUserButton;
    const unitedStates = useSelector(state => state.unitedStates.fullNames);
    const contact = useSelector(state => state.contact.currentContact);
    const tabs = useSelector(state => state.exchange.contactTabs);
    const types = useSelector(state => state.exchange.contactTypes);
    const propertyAddressId = useSelector(state => state.exchange.selectedPropertyAddress.id);

    const roles = useSelector(state => state.session.roles);
    const isAdmin = roles.indexOf(ADMIN_ROLE) >= 0;
    const isUser = roles.indexOf(USER_ROLE) >= 0;

    const canCreateUser = function () {
        return !hideUserButton && contactId &&
            (isAdmin || isUser) &&
            String(contact.emailAddress)
                .toLowerCase()
                .match(/\S+@\S+\.\S+/);
    }

    const onSignatureTab = function () {
        const currentTab = tabs.find(t => t.id === tabId);
        if(!currentTab) return false;
        return exchangeId && tabId && typeId && currentTab.name === signatureAndVestingTabName;
    }

    const onPropertyTypeInfoTab = function () {
        const currentType = types.find(t => t.id === typeId);
        const currentTab = tabs.find(t => t.id === tabId);
        if(!currentType || !currentTab) return false;
        return !taxpayer && tabId && typeId && currentTab.name === infoTabName &&
            (currentType.name === relinquishedPropertyEscrowTab || currentType.name === replacementPropertyEscrowTab);
    }

    useEffect(() => {
        if(contactId){
            dispatch(loadContactById(contactId));
        } else {
            dispatch(loadContactTabs());
        }
    }, [contactId, dispatch]);

    const handleSaveContact = async function (e) {
        if (exchangeId) e.exchangeId = exchangeId;
        if (typeId) e.typeId = typeId;
        if (tabId) e.tabId = tabId;
        if (!taxpayer){
            e.propertyAddressId = propertyAddressId;
        } else {
            e.propertyAddressId = null;
        }
        await dispatch(saveContact(e));
        if(contactSaved){
            contactSaved(e);
        }
    }

    const handleCopyContact = async function (e) {
        const criteria = {
            exchangeId: exchangeId,
            contactId: 0,
            typeId: typeId,
            tabId: tabId
        }
        if (!taxpayer) {
            criteria.propertyAddressId = propertyAddressId;
        } else {
            criteria.propertyAddressId = null;
        }
        await dispatch(copyContact(criteria));
    }

    const handleValueChange = function (e) {
        if(e.target.name === "pointOfContact"){
            let firstName, lastName;
            if(e.target.value.indexOf(" ") === -1){
                firstName = e.target.value;
                lastName = "";
            } else {
                firstName = e.target.value.substring(0, e.target.value.indexOf(" "));
                lastName = e.target.value.substring(e.target.value.indexOf(" ") + 1);
            }
            dispatch(updateContactProperty("firstName", firstName));
            dispatch(updateContactProperty("lastName", lastName));
        }
        dispatch(updateContactProperty(e.target.name, e.target.value));
    }

    const handleCreateUser = function () {
        dispatch(createContactUser(contactId))
    }

    return (<div className={"p-2"}>
        <div className={onSignatureTab() ? "row m-0 p-0 justify-content-center" : defaultRow}>
            <div className={"col-6 m-0 p-2"}>
                <div className={defaultRow}>
                    <label className="form-label small m-0 p-0 text-secondary">{onPropertyTypeInfoTab() ? "Escrow" : "Entity"}</label>
                    <input name="companyName" onChange={handleValueChange} className="form-control" value={contact.companyName ? contact.companyName : ""}/>
                </div>
                <div className={onPropertyTypeInfoTab() ? defaultRow : "display-none"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Point of Contact</label>
                    <input name="pointOfContact" onChange={handleValueChange} className="form-control" value={contact.pointOfContact ? contact.pointOfContact : ""}/>
                </div>
                <div className={onSignatureTab() ? defaultRow : "display-none"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Title</label>
                    <input name="title" onChange={handleValueChange} className="form-control" value={contact.title ? contact.title : ""}/>
                </div>
                <div className={onPropertyTypeInfoTab() ? "display-none" : defaultRow}>
                    <div className={"col-8 m-0 p-0"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">First Name</label>
                        <input name="firstName" onChange={handleValueChange} className="form-control" value={contact.firstName ? contact.firstName : ""}/>
                    </div>
                    <div className={"col-3 m-0 p-0 ms-auto"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Middle</label>
                        <input name="middleName" onChange={handleValueChange} className="form-control" value={contact.middleName ? contact.middleName : ""}/>
                    </div>
                </div>
                <div className={onPropertyTypeInfoTab() ? "display-none" : defaultRow}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Last Name</label>
                    <input name="lastName" onChange={handleValueChange} className="form-control" value={contact.lastName ? contact.lastName : ""}/>
                </div>
                <div className={onSignatureTab() ? "display-none" : defaultRow}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Email Address</label>
                    <input name="emailAddress" onChange={handleValueChange} className="form-control" value={contact.emailAddress ? contact.emailAddress : ""}/>
                </div>
                <div className={onSignatureTab() || onPropertyTypeInfoTab() ? "display-none" : defaultRow}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Home Phone</label>
                    <input name="homePhone" onChange={handleValueChange} className="form-control" value={contact.homePhone ? contact.homePhone : ""}/>
                </div>
                <div className={onSignatureTab() ? "display-none" : defaultRow}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Mobile Phone</label>
                    <input name="mobilePhone" onChange={handleValueChange} className="form-control" value={contact.mobilePhone ? contact.mobilePhone : ""}/>
                </div>
                <div className={onSignatureTab() ? "display-none" : defaultRow}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Work Phone</label>
                    <input name="workPhone" onChange={handleValueChange} className="form-control" value={contact.workPhone ? contact.workPhone : ""}/>
                </div>
            </div>
            <div className={onSignatureTab() ? "display-none" : "col-5 m-0 p-0 ms-auto"}>
                <div className={"row m-0 p-0"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Address</label>
                    <input name="address1" onChange={handleValueChange} className="form-control" value={contact.address1 ? contact.address1 : ""}/>
                </div>
                <div className={"row m-0 p-0"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Apt, Suite etc.</label>
                    <input name="address2" onChange={handleValueChange} className="form-control" value={contact.address2 ? contact.address2 : ""}/>
                </div>
                <div className={"row m-0 p-0"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">City</label>
                    <input name="city" onChange={handleValueChange} className="form-control" value={contact.city ? contact.city : ""}/>
                </div>
                <div className={"row m-0 p-0"}>
                    <div className={"col-7 m-0 p-0"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">State</label>
                        <select name="state" className="form-select" onChange={handleValueChange} value={contact.state ? contact.state : ""}>
                            <option disabled value="">Select a State</option>
                            {unitedStates.map((us, idx) => <option key={idx} value={us}>{us}</option>)}
                        </select>
                    </div>
                    <div className={"col-4 m-0 p-0 ms-auto"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Postal Code</label>
                        <input name="postalCode" onChange={handleValueChange} className="form-control" value={contact.postalCode ? contact.postalCode : ""}/>
                    </div>
                </div>
                <div className={onPropertyTypeInfoTab() ? defaultRow : "display-none"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Escrow Number</label>
                    <input name="escrowNumber" onChange={handleValueChange} className="form-control" value={contact.escrowNumber ? contact.escrowNumber : ""}/>
                </div>
            </div>
        </div>
        <div className={"row m-0 p-0 my-2 text-end"}>
            <div className={"col-12 m-0 p-0"}>
                {canCreateUser() ? <button className={contact.userId ? "btn btn-outline-light me-2 btn-disabled" : "btn btn-outline-dark me-2"} title="Create User" onClick={handleCreateUser}><FontAwesomeIcon className={""} icon={faUserCircle}/>&nbsp;Create User</button> : null}
                <button className={"btn btn-outline-dark me-2"} title="Save" onClick={() => handleSaveContact(contact)}><FontAwesomeIcon className={""} icon={faSave}/>&nbsp;Save</button>
                {onSignatureTab() ? <button className={"btn btn-outline-dark me-2"} title="Copy" onClick={() => handleCopyContact(contact)}><FontAwesomeIcon className={""} icon={faCopy}/>&nbsp;Copy Info Tab</button> : null }
            </div>
        </div>
    </div>);
}
