import {
    CLEAR_SELECTED_TEMPLATE,
    LOAD_TEMPLATE_LIST,
    LOG_OUT, SAVE_TEMPLATE, SELECT_TEMPLATE, UPDATE_TEMPLATE_FIELD
} from "../actions/action-types";

const initialState = {
    currentTemplate: {
        name: "",
        description: "",
        subjectTemplate: "",
        template: ""
    },
    templates: []
}

const emailTemplate = (state = initialState, action) => {
    let newState;
    let template;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case UPDATE_TEMPLATE_FIELD:
            newState = Object.assign({}, state);
            template = Object.assign({}, state.currentTemplate);
            template[action.payload.field] = action.payload.value;
            newState.currentTemplate = template;
            return newState;

        case LOAD_TEMPLATE_LIST:
            newState = Object.assign({}, state);
            newState.templates = action.payload;
            return newState;

        case SELECT_TEMPLATE:
            newState = Object.assign({}, state);
            newState.currentTemplate = Object.assign({}, state.templates.find(t => t.id === action.payload));
            return newState;

        case CLEAR_SELECTED_TEMPLATE:
            newState = Object.assign({}, state);
            newState.currentTemplate = Object.assign({}, initialState.currentTemplate);
            return newState;

        case SAVE_TEMPLATE:
            newState = Object.assign({}, state);
            newState.templates = Object.assign([], initialState.templates.filter(t => t.id !== action.payload.id));
            newState.templates.push(Object.assign({}, action.payload));
            return newState;

        default:
            return state
    }
}

export default emailTemplate;