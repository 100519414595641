import React, {useEffect, useRef} from 'react';
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";
import {useDispatch, useSelector} from "react-redux";
import {
    clearSelectedSmartTag,
    hideSmartTagModal,
    loadAllSmartTags, loadMappingObjectTypes,
    selectSmartTag,
    showSmartTagModal
} from "../../actions/smart-tags";
import {AgGridReact} from "ag-grid-react";
import {defaultColDef, headerHeightSetter} from "../../constants/settings";
import Popup from "reactjs-popup";
import TagEditor from "../exchange-list/tag-editor";
import {loadContactTabs, loadContactTypes, loadPropertyTabs} from "../../actions/exchange";
import {insertStringSpaces} from "../../utilities/string-manipulators";
import {loadClassMappingFields} from "../../actions/document-mappings";

const SmartTags = function () {
    const dispatch = useDispatch();

    const tags = useSelector(state => state.smartTags.tags);
    const showModal = useSelector(state => state.smartTags.showModal);
    const mappingObjectTypes = useSelector(state => state.smartTags.mappingObjectTypes);

    const gridRef = useRef();

    const columnDefs = [
        {
            headerName: 'Name',
            field: 'name',
        },
        {
            headerName: 'Tag',
            field: 'smartTag',
        },
        {
            headerName: 'Field',
            cellRenderer: "insertSpaces",
            field: 'field',
        },
        {
            headerName: 'Contact Type',
            cellRenderer: 'renderColumn',
            field: 'contactType',
        },
        {
            headerName: 'Contact Tab',
            cellRenderer: 'renderColumn',
            field: 'contactTab',
        },
        {
            headerName: 'Property Type',
            cellRenderer: 'renderColumn',
            field: 'propertyType',
        }
    ];

    useEffect(() => {
        async function loadScreen() {
            await dispatch(loadPropertyTabs());
            await dispatch(loadAllSmartTags());
            await dispatch(loadContactTabs());
            await dispatch(loadContactTypes());
            await dispatch(loadMappingObjectTypes());
            await dispatch(clearSelectedSmartTag());

        }
        loadScreen();
    }, [dispatch]);

    const renderColumn = function (ex) {
        if(ex.value) return <div>{ex.value}</div>
        return <div className={"opacity-25"}>N/A</div>
    }

    const insertSpaces = function(ex) {
        let string = ex.value;
        return insertStringSpaces(string);
    }

    const handleRowClicked = async function (row) {
        await dispatch(selectSmartTag(row.data));
        const selectedObject = mappingObjectTypes.find(mt => mt.id === parseInt(row.data.mappingObjectId));
        await dispatch(loadClassMappingFields(selectedObject.object));
        await dispatch(showSmartTagModal());
    }

    const handleAddNewTag = async function () {
        await dispatch(clearSelectedSmartTag());
        await dispatch(showSmartTagModal());
    }

    const closeModal = async function () {
        await dispatch(hideSmartTagModal());
    }

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
            <div className="row m-0 p-0">
                <div className="col-1 p-0 pt-3 pt-2 header-column">
                    <h1 className="m-0 p-0 py-2 px-4">Smart Tags</h1>
                </div>
                <div className="col-11 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                    <div className={"row m-0 p-0"}>
                        <div className={"col-12 text-end"}>
                            <button className={"btn btn-outline-primary btn-sm"} onClick={handleAddNewTag}>Add New Tag</button>
                        </div>
                    </div>
                    <div className={"row m-0 p-0"}>
                        <div className={"col"}>
                            <div className={"ag-theme-alpine"}  style={{height: '85vh'}}>
                                <AgGridReact
                                    components={{ renderColumn, insertSpaces }}
                                    defaultColDef={defaultColDef}
                                    onRowClicked={handleRowClicked}
                                    ref={gridRef}
                                    rowData={tags}
                                    suppressCellFocus={true}
                                    onFirstDataRendered={headerHeightSetter}
                                    onColumnResized={headerHeightSetter}
                                    columnDefs={columnDefs}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Popup open={showModal} closeOnDocumentClick onClose={closeModal}>
            <TagEditor closeModal={closeModal}/>
        </Popup>
    </div>);
}

export default withAuthenticationRequired(SmartTags, {
    onRedirecting: () => <Loading />,
});
