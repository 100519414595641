import {
    ADD_PROPERTY_ADDRESS,
    ADD_SETTLEMENT_AGENCY,
    CREATE_EXCHANGE,
    DELETE_EXCHANGE,
    GET_ALL_EXCHANGES,
    GET_EXCHANGE_BY_ID,
    UPDATE_EXCHANGE,
    UPDATE_PROPERTY_ADDRESS,
    UPDATE_SETTLEMENT_AGENCY,
    LOAD_EXCHANGE_CONTACTS,
    LOAD_SETTLEMENT_AGENCY,
    LOAD_PROPERTIES,
    UPDATE_EXCHANGE_PROPERTY,
    UPDATE_PROPERTY_ADDRESS_PROPERTY,
    UPDATE_SETTLEMENT_AGENCY_PROPERTY,
    CLEAR_EXCHANGE,
    LOG_OUT,
    SELECT_PROPERTY_ADDRESS,
    CLEAR_PROPERTY_ADDRESS,
    REMOVE_PROPERTY_ADDRESS,
    LOAD_CONTACT_TYPES,
    LOAD_CONTACT_TABS,
    SET_CONTACT_TYPE,
    SET_CONTACT_TAB, LOAD_EXCHANGE_TYPES, LOAD_PROPERTY_TABS, SET_PROPERTY_TYPE, TOGGLE_EXCHANGE_STATUS
} from "../actions/action-types";

const initialState = {
    allExchanges: [],
    currentExchange: { id: 0,
        exchangeId: '',
        exchangeDate: new Date(),
        taxpayerVesting: '',
        escrowFileReference: '',
        dateOfEscrowInstructions: '',
        buyerVesting: '',
        exchangeTypeId: 0,
        exchangeOfficer: '',
        agency: '',
        amountReceived: 0,
        interestAccountOpened: false,
        openDate: null,
        closeOfEscrowFundsReceived: null,
        fortyFiveDays: null,
        oneEightyDays: null,
        closed: false},
    selectedPropertyAddress: {
        id: 0,
        address1: "",
        city: "",
        state: "",
        postalCode: "",
        addressType: "",
        escrowNumber: "",
        legalDescription: ""
    },
    properties: [],
    contacts: [],
    contactTypes: [],
    contactTabs: [],
    exchangeTypes: [],
    propertyTabs: [],
    selectedType: 2,
    selectedTab: 1,
    selectedPropertyType: 1,
    exchangeDates: []
}

const exchange = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case CREATE_EXCHANGE:
            newState = Object.assign({}, state);
            newState.currentExchange = action.payload;
            return newState;

        case UPDATE_EXCHANGE:
            newState = Object.assign({}, state);
            return newState;

        case DELETE_EXCHANGE:
            newState = Object.assign({}, state);
            newState.currentExchange = Object.assign({}, initialState.currentExchange);
            return newState;

        case GET_EXCHANGE_BY_ID:
            newState = Object.assign({}, state);
            newState.currentExchange = action.payload;
            return newState;

        case GET_ALL_EXCHANGES:
            newState = Object.assign({}, state);
            newState.allExchanges = action.payload;
            newState.exchangeDates = getExchangeDates(action.payload);
            return newState;

        case ADD_PROPERTY_ADDRESS:
            newState = Object.assign({}, state);
            newState.selectedPropertyAddress = Object.assign({}, initialState.selectedPropertyAddress);
            return newState;

        case REMOVE_PROPERTY_ADDRESS:
            newState = Object.assign({}, state);
            newState.selectedPropertyAddress = Object.assign({}, initialState.selectedPropertyAddress);
            newState.properties = state.properties.filter(p => p.id !== action.payload);
            return newState;

        case UPDATE_PROPERTY_ADDRESS:
            newState = Object.assign({}, state);
            return newState;

        case ADD_SETTLEMENT_AGENCY:
            newState = Object.assign({}, state);
            newState.settlementAgency.Id = action.payload;
            newState.currentExchange.settlementAgencyId = action.payload.id;
            return newState;

        case UPDATE_SETTLEMENT_AGENCY:
            newState = Object.assign({}, state);
            newState.settlementAgency = action.payload;
            return newState;

        case LOAD_EXCHANGE_CONTACTS:
            newState = Object.assign({}, state);
            newState.contacts = Object.assign([], action.payload);
            return newState;

        case LOAD_PROPERTIES:
            newState = Object.assign({}, state);
            newState.properties = action.payload;
            return newState;

        case SELECT_PROPERTY_ADDRESS:
            newState = Object.assign({}, state);
            newState.selectedPropertyAddress = action.payload;
            return newState;

        case CLEAR_PROPERTY_ADDRESS:
            newState = Object.assign({}, state);
            newState.selectedPropertyAddress = Object.assign({}, initialState.selectedPropertyAddress);
            return newState;

        case LOAD_SETTLEMENT_AGENCY:
            newState = Object.assign({}, state);
            newState.settlementAgency = action.payload;
            return newState;

        case UPDATE_EXCHANGE_PROPERTY:
            newState = Object.assign({}, state);
            let newExchange = Object.assign({}, state.currentExchange);
            newExchange[action.payload.name] = action.payload.value;
            newState.currentExchange = newExchange;
            return newState;

        case UPDATE_PROPERTY_ADDRESS_PROPERTY:
            newState = Object.assign({}, state);
            let newPropertyAddress = Object.assign({}, state.selectedPropertyAddress);
            newPropertyAddress[action.payload.name] = action.payload.value;
            newState.selectedPropertyAddress = newPropertyAddress;
            return newState;

        case UPDATE_SETTLEMENT_AGENCY_PROPERTY:
            newState = Object.assign({}, state);
            let newSettlementAgency = Object.assign({}, state.settlementAgency);
            newSettlementAgency[action.payload.name] = action.payload.value;
            newState.settlementAgency = newSettlementAgency;
            return newState;

        case CLEAR_EXCHANGE:
            newState = Object.assign({}, initialState);
            newState.allExchanges = Object.assign([], state.allExchanges);
            newState.exchangeDates = Object.assign([], state.exchangeDates);
            return newState;

        case LOAD_CONTACT_TYPES:
            newState = Object.assign({}, state);
            newState.contactTypes = action.payload;
            return newState;

        case LOAD_CONTACT_TABS:
            newState = Object.assign({}, state);
            newState.contactTabs = action.payload;
            return newState;

        case LOAD_PROPERTY_TABS:
            newState = Object.assign({}, state);
            newState.propertyTabs = action.payload;
            return newState;

        case SET_PROPERTY_TYPE:
            newState = Object.assign({}, state);
            newState.selectedPropertyType = action.payload;
            newState.selectedPropertyAddress = Object.assign({}, initialState.selectedPropertyAddress);
            return newState;

        case SET_CONTACT_TYPE:
            newState = Object.assign({}, state);
            newState.selectedType = action.payload;
            return newState;

        case SET_CONTACT_TAB:
            newState = Object.assign({}, state);
            newState.selectedTab = action.payload;
            return newState;

        case LOAD_EXCHANGE_TYPES:
            newState = Object.assign({}, state);
            newState.exchangeTypes = action.payload;
            return newState;

        case TOGGLE_EXCHANGE_STATUS:
            newState = Object.assign({}, state);
            return newState;

        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        default:
            return state
    }
}

function getExchangeDates (exchanges) {
    const fortyFiveDates = exchanges.filter(ex => ex.fortyFiveDays).map((ex, index) => {
        let fortyFiveEnd = new Date(ex.fortyFiveDays)
        fortyFiveEnd.setTime(fortyFiveEnd.getTime() + (60*60*1000) - 1);
        return {
            event_id: ex.exchangeId,
            title: "(45) " + (ex.taxpayerCompany ? ex.taxpayerCompany : ex.taxpayer),
            exchangeId: ex.exchangeId,
            address: ex.address1,
            start: new Date(ex.fortyFiveDays),
            end: fortyFiveEnd,
            color: "#0d6efd"
        };
    });

    const oneEightyDates = exchanges.filter(ex => ex.oneEightyDays).map((ex, index) => {
        let oneEightyEnd = new Date(ex.oneEightyDays)
        oneEightyEnd.setTime(oneEightyEnd.getTime() + (60*60*1000) - 1);
        return {
            event_id: ex.exchangeId,
            title: "(180) " + (ex.taxpayerCompany ? ex.taxpayerCompany : ex.taxpayer),
            exchangeId: ex.exchangeId,
            address: ex.address1,
            start: new Date(ex.oneEightyDays),
            end: oneEightyEnd,
            color: "#dc3545"
        };
    });

    return fortyFiveDates.concat(oneEightyDates);
}

export default exchange
