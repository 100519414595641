import {baseUrl} from "../constants/settings";
import {
    ADD_PROPERTY_ADDRESS,
    CREATE_EXCHANGE,
    DELETE_EXCHANGE,
    GET_ALL_EXCHANGES,
    GET_EXCHANGE_BY_ID,
    UPDATE_EXCHANGE,
    UPDATE_PROPERTY_ADDRESS,
    LOAD_PROPERTIES,
    UPDATE_EXCHANGE_PROPERTY,
    UPDATE_PROPERTY_ADDRESS_PROPERTY,
    UPDATE_CONTACT_PROPERTY,
    SELECT_PROPERTY_ADDRESS,
    CLEAR_PROPERTY_ADDRESS,
    REMOVE_PROPERTY_ADDRESS,
    LOAD_EXCHANGE_CONTACTS,
    LOAD_CONTACT_TYPES,
    LOAD_CONTACT_TABS,
    SET_CONTACT_TYPE,
    SET_CONTACT_TAB,
    LOAD_CONTACT_FOR_TAB,
    CLEAR_CONTACT,
    LOAD_EXCHANGE_TYPES,
    LOAD_PROPERTY_TABS,
    SET_PROPERTY_TYPE,
    TOGGLE_EXCHANGE_STATUS
} from "./action-types";
import toast from "react-hot-toast";
import {ExchangeApi, ListApi, PropertyAddressApi} from "../api";

const exchangeApi =  new ExchangeApi(undefined, baseUrl);
const propertyAddressApi =  new PropertyAddressApi(undefined, baseUrl);
const listApi = new ListApi(undefined, baseUrl);

export const getAllExchanges = function(getClosed) {
    return async dispatch => {
        try {
            let response;
            if (getClosed){
                response = await exchangeApi.apiExchangeClosedGet();
            } else {
                response = await exchangeApi.apiExchangeGet();
            }
            dispatch({ type: GET_ALL_EXCHANGES, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}
export const getExchangeById = function(exchangeId) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeIdGet(exchangeId);
            dispatch({ type: GET_EXCHANGE_BY_ID, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}
export const createExchange = function(exchange, navigate) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeCreatePost(exchange);
            dispatch({ type: CREATE_EXCHANGE, payload: response.data });
            navigate("/order/" + response.data.id + "/detail");
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}
export const updateExchange = function(exchange) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeUpdatePost(exchange);
            dispatch({ type: UPDATE_EXCHANGE });
            if(response.data) toast.success("Order Information Updated");
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}
export const deleteExchange = function(exchangeId) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeDeleteIdGet(exchangeId);
            dispatch({ type: DELETE_EXCHANGE, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const addProperty = function(property) {
    return async dispatch => {
        try {
            const response = await propertyAddressApi.apiPropertyAddressPost(property);
            property.id = response.data;
            dispatch({ type: ADD_PROPERTY_ADDRESS, payload: property });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const updateProperty = function(property) {
    return async dispatch => {
        try {
            await propertyAddressApi.apiPropertyAddressPut(property);
            dispatch({ type: UPDATE_PROPERTY_ADDRESS, payload: property });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const removePropertyAddress = function(propertyId) {
    return async dispatch => {
        try {
            await propertyAddressApi.apiPropertyAddressDelete(propertyId);
            dispatch({ type: REMOVE_PROPERTY_ADDRESS, payload: propertyId });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadExchangeProperties = function(exchangeId) {
    return async dispatch => {
        try {
            const response = await propertyAddressApi.apiPropertyAddressExchangeExchangeIdGet(exchangeId);
            dispatch({ type: LOAD_PROPERTIES, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadExchangePropertiesForType = function(exchangeId, typeId) {
    return async dispatch => {
        try {
            const response = await propertyAddressApi.apiPropertyAddressExchangeExchangeIdTypeIdGet(exchangeId, typeId);
            dispatch({ type: LOAD_PROPERTIES, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const toggleExchangeStatus = function(exchangeId, closed) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeToggleStatusExchangeIdClosedGet(exchangeId, closed);
            dispatch({ type: TOGGLE_EXCHANGE_STATUS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const updateExchangeProperty = function (name, value) {
    return dispatch => {
        dispatch({ type: UPDATE_EXCHANGE_PROPERTY, payload: {name, value}});
    }
}

export const updatePropertyAddressProperty = function (name, value) {
    return dispatch => {
        dispatch({ type: UPDATE_PROPERTY_ADDRESS_PROPERTY, payload: {name, value}});
    }
}

export const updateContactProperty = function (name, value) {
    return dispatch => {
        dispatch({ type: UPDATE_CONTACT_PROPERTY, payload: {name, value}});
    }
}

export const selectPropertyAddress = function (propertyAddress){
    return dispatch => {
        dispatch({ type: SELECT_PROPERTY_ADDRESS, payload: propertyAddress});
    }
}

export const clearPropertyAddress = function (){
    return dispatch => {
        dispatch({ type: CLEAR_PROPERTY_ADDRESS});
    }
}

export const loadContactsForExchange = function(exchangeId) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeGetContactsGet(exchangeId);
            dispatch({ type: LOAD_EXCHANGE_CONTACTS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadRepresentativesForContact = function(exchangeId) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeRepresentativesPost(exchangeId);
            dispatch({ type: LOAD_EXCHANGE_CONTACTS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const selectRepresentative = function (rep) {
    return dispatch => {
        dispatch({ type: LOAD_CONTACT_FOR_TAB, payload: rep });
    }
}

export const loadContactTypes = function() {
    return async dispatch => {
        try {
            const response = await listApi.apiListExchangeContactTypesGet();
            dispatch({ type: LOAD_CONTACT_TYPES, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadContactTabs = function() {
    return async dispatch => {
        try {
            const response = await listApi.apiListExchangeContactTabsGet();
            dispatch({ type: LOAD_CONTACT_TABS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadPropertyTabs = function() {
    return async dispatch => {
        try {
            const response = await listApi.apiListPropertyTypesGet();
            dispatch({ type: LOAD_PROPERTY_TABS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadExchangeTypes = function() {
    return async dispatch => {
        try {
            const response = await listApi.apiListExchangeTypesGet();
            dispatch({ type: LOAD_EXCHANGE_TYPES, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const setContactType = function (typeId){
    return dispatch => {
        dispatch({ type: SET_CONTACT_TYPE, payload: typeId});
    }
}

export const setPropertyTab = function (propertyTypeId){
    return dispatch => {
        dispatch({ type: SET_PROPERTY_TYPE, payload: propertyTypeId});
    }
}

export const setContactTab = function (tabId){
    return dispatch => {
        dispatch({ type: SET_CONTACT_TAB, payload: tabId});
    }
}

export const loadContactForTab = function(criteria) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeGetContactForTabPost(criteria);
            if(response.data) {
                dispatch({ type: LOAD_CONTACT_FOR_TAB, payload: response.data });
            } else {
                dispatch({ type: CLEAR_CONTACT });
            }

        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}




