import {
    CONVERT_REGISTRATION,
    LOAD_ALL_REGISTRATIONS,
    LOAD_REGISTRATION_BY_ID,
    LOG_OUT,
    SUBMIT_REGISTRATION,
    UPDATE_REGISTRATION_FIELD
} from '../actions/action-types';

const initialState = {
    registrations: [],
    exchangeId: 0,
    orderRegistration: {
        id: 0,
        relinquishedStreetAddress: "",
        relinquishedCity: "",
        relinquishedState: "",
        relinquishedPercentOwnership: 100,
        relinquishedSalePrice: 0,
        exchangerEntity: "",
        exchangerName: "",
        exchangerAddress: "",
        exchangerCity: "",
        exchangerState: "",
        exchangerZip: "",
        exchangerPhone: "",
        exchangerFax: "",
        exchangerEmail: "",
        buyerEntity: "",
        buyerName: "",
        buyerAddress: "",
        buyerCity: "",
        buyerState: "",
        buyerZip: "",
        buyerPhone: "",
        buyerFax: "",
        buyerEmail: "",
        settlementAgentName: "",
        settlementAgency: "",
        settlementAgencyAddress: "",
        settlementAgencyCity: "",
        settlementAgencyState: "",
        settlementAgencyZip: "",
        settlementAgencyPhone: "",
        settlementAgencyEmail: "",
        escrowNumber: "",
        exchangeNumber: "",
        acceptanceDate: new Date().toLocaleDateString()}
}

const registration = (state = initialState, action) => {
    let newState;
    let orderReg;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case UPDATE_REGISTRATION_FIELD:
            newState = Object.assign({}, state);
            orderReg = Object.assign({}, newState.orderRegistration);
            orderReg[action.payload.name] = action.payload.value;
            newState.orderRegistration = orderReg;
            return newState;

        case SUBMIT_REGISTRATION:
            newState = Object.assign({}, state);
            orderReg = Object.assign({}, newState.orderRegistration);
            orderReg.id = action.payload;
            newState.orderRegistration = orderReg;
            return newState;

        case LOAD_ALL_REGISTRATIONS:
            newState = Object.assign({}, state);
            newState.registrations = action.payload;
            newState.exchangeId = 0;
            return newState;

        case LOAD_REGISTRATION_BY_ID:
            newState = Object.assign({}, state);
            newState.orderRegistration = action.payload;
            return newState;

        case CONVERT_REGISTRATION:
            newState = Object.assign({}, state);
            newState.exchangeId = action.payload;
            return newState;

        default:
            return state
    }
}

export default registration;