import React, {useEffect} from 'react';
import {AgGridReact} from "ag-grid-react";
import {useDispatch, useSelector} from "react-redux";
import {defaultColDef, defaultRow} from "../../constants/settings";
import {loadRepresentativesForContact, selectRepresentative} from "../../actions/exchange";
import {clearCurrentContact, removeContact} from "../../actions/contact";
import {faPlus, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Representatives = (props) => {

    const dispatch = useDispatch();
    const contacts = useSelector(state => state.exchange.contacts);
    const propertyAddressId = useSelector(state => state.exchange.selectedPropertyAddress.id);
    const {exchangeId, typeId, tabId, isTaxpayer} = props;

    const columnDefs = [
        {
            headerName: 'Entity',
            field: 'companyName',
        },{
            headerName: 'First',
            field: 'firstName',
        },
        {
            headerName: 'Middle',
            field: 'middleName',
        },
        {
            headerName: 'Last',
            field: 'lastName',
        },
        {
            headerName: '',
            cellRenderer: 'DeleteButtonComponent',
            field: 'id',
            maxWidth: 60
        }
    ];

    useEffect(() => {
        let criteria = {exchangeId, typeId, tabId, propertyAddressId};
        if(isTaxpayer){
            criteria = {exchangeId, typeId, tabId};
        }
        dispatch(loadRepresentativesForContact(criteria));
    }, [dispatch, exchangeId, typeId, tabId, propertyAddressId, isTaxpayer]);

    const handleRepresentativeClick = function (e) {
        dispatch(clearCurrentContact());
        dispatch(selectRepresentative(e.data))
    }

    return (<div className={defaultRow}>
        <div className={"col-6"}><div className={"fs-4"}>Representatives</div></div>
        <div className={"col-6 text-end"}><button className={"btn btn-outline-dark btn-sm"} onClick={() => dispatch(clearCurrentContact())}><FontAwesomeIcon icon={faPlus}/>&nbsp;Add Representative</button> </div>
            <div className={"col-12"}>
                <div className={"ag-theme-alpine"}  style={{height: '20vh'}}>
                    <AgGridReact
                        components={{
                            DeleteButtonComponent
                        }}
                        onRowClicked={handleRepresentativeClick}
                        defaultColDef={defaultColDef}
                        suppressCellFocus={true}
                        rowData={contacts}
                        columnDefs={columnDefs}>
                    </AgGridReact>
                </div>
            </div>
        </div>);
}

export default Representatives;

const DeleteButtonComponent = (params) => {
    const dispatch = useDispatch();

    const handleDeleteRepresentative = async function (row, e) {
        e.stopPropagation();
        if (window.confirm("Representative will be removed.  Are you sure?")){
            await dispatch(removeContact(row.id));
            await dispatch(clearCurrentContact());
            await dispatch(loadRepresentativesForContact({exchangeId : row.exchangeId,
                typeId: row.typeId,
                tabId: row.tabId,
                propertyAddressId: row.propertyAddressId}));
        }
    }

    return <div style={{zIndex: 10}}><button onClick={(e) => handleDeleteRepresentative(params.data, e)} className={"btn btn-link link-danger m-0 p-0"} title={"Delete"}><FontAwesomeIcon icon={faTimesCircle}/></button></div>

};
