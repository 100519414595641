import React, {useEffect, useRef} from 'react';
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";
import {AgGridReact} from "ag-grid-react";
import {defaultColDef} from "../../constants/settings";
import {useDispatch, useSelector} from "react-redux";
import {loadAllRegistrations} from "../../actions/registration";
import {useNavigate} from "react-router-dom";

const RegistrationAdmin = function () {

    const gridRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registrations = useSelector(state => state.registration.registrations);

    useEffect(() => {
        async function loadScreen() {
            await dispatch(loadAllRegistrations());
        }
        loadScreen();
    }, [dispatch]);

    const columnDefs = [
        {
            headerName: 'Exchanger',
            cellRenderer: 'renderColumn',
            field: 'exchangerName',
        },
        {
            headerName: 'Exchanger Phone',
            cellRenderer: 'renderColumn',
            field: 'exchangerPhone'
        },
        {
            headerName: 'Exchanger Email',
            cellRenderer: 'renderColumn',
            field: 'exchangerEmail',
        },
        {
            headerName: 'Buyer',
            cellRenderer: 'renderColumn',
            field: 'buyerName',
        },
        {
            headerName: 'Relinquished Property Address',
            cellRenderer: 'renderAddress',
            field: 'relinquishedStreetAddress'
        }
    ];

    const handleRowClicked = (e) => {
        navigate("/registration/" + e.data.id);
    }

    const renderAddress = function (ex) {
        const obj = ex.data;
        if (obj.relinquishedStreetAddress){
            return <div title={obj.relinquishedStreetAddress + ' ' + obj.relinquishedCity + ', ' + obj.relinquishedState}>{obj.relinquishedStreetAddress} {obj.relinquishedCity}, {obj.relinquishedState}</div>
        } else {
            return <div className={"opacity-25"}>Not Set</div>
        }

    }

    const renderDate = function (ex) {
        if(ex.value) return <div>{new Date(ex.value).toLocaleDateString()}</div>
        return <div className={"opacity-25"}>Not Set</div>
    }

    const renderColumn = function (ex) {
        if(ex.value) return <div>{ex.value}</div>
        return <div className={"opacity-25"}>Not Set</div>
    }

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
            <div className="row m-0 p-0">
                <div className="col-1 p-0 pt-3 pt-2 header-column">
                    <h1 className="m-0 p-0 py-2 px-4">Order Registrations</h1>
                </div>
                <div className="col-11 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                    <div className={"ag-theme-alpine"}  style={{height: '80vh'}}>
                        <AgGridReact
                            components={{
                                renderAddress,
                                renderDate,
                                renderColumn
                            }}
                            defaultColDef={defaultColDef}
                            ref={gridRef}
                            rowData={registrations}
                            onRowClicked={handleRowClicked}
                            suppressCellFocus={true}
                            columnDefs={columnDefs}/>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(RegistrationAdmin, {
    onRedirecting: () => <Loading />,
});