import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {
    addSmartTag,
    clearSelectedSmartTag, deleteSmartTag,
    loadAllSmartTags,
    updateSelectedSmartTagProperty,
    updateSmartTag
} from "../../actions/smart-tags";
import {loadClassMappingFields} from "../../actions/document-mappings";
import {TAXPAYER_ID} from "../../constants/settings";

const TagEditor = function (props) {
    const dispatch = useDispatch();

    const selectedTag = useSelector(state => state.smartTags.selectedTag);
    const contactTabs = useSelector(state => state.exchange.contactTabs);
    const contactTypes = useSelector(state => state.exchange.contactTypes);
    const propertyTypes = useSelector(state => state.exchange.propertyTabs);
    const mappingObjectTypes = useSelector(state => state.smartTags.mappingObjectTypes);
    const classFields = useSelector(state => state.documentMappings.classFields);
    const {closeModal} = props;

    const handleFieldChanged = async function (e){
        if (e.target.name.slice(-2) === "Id") {
            //value is numeric
            await dispatch(updateSelectedSmartTagProperty(e.target.name, parseInt(e.target.value)));
        }
        else {
            await dispatch(updateSelectedSmartTagProperty(e.target.name, e.target.value));
        }
        if (e.target.name === "mappingObjectId"){
            const selectedObject = mappingObjectTypes.find(mt => mt.id === parseInt(e.target.value));
            if(selectedObject){
                await dispatch(loadClassMappingFields(selectedObject.object));
            }

        }
    }

    const handleSaveChanges = async function (){
        if (selectedTag.id > 0){
            await dispatch(updateSmartTag(selectedTag));
        } else {
            await dispatch(addSmartTag(selectedTag));
        }
        await dispatch(loadAllSmartTags());
        await closeModal();
        await dispatch(clearSelectedSmartTag());
    }

    const handleDeleteTag = async function (){
        if(window.confirm("Tag will be permanently deleted.  Are you sure?")){
            await dispatch(deleteSmartTag(selectedTag));
            await dispatch(loadAllSmartTags());
            await closeModal();
            await dispatch(clearSelectedSmartTag());
        }
    }

    return (<div className={"tag-modal"}>
        <button className="btn btn-link link-dark text-decoration-none m-0 p-0 close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimesCircle}/>
        </button>
        <div className="header">{selectedTag.id > 0 ? selectedTag.name : "New Smart Tag"}</div>
        <div className="content">
            <div className={"row"}>
                <div className={"col-6"}>
                    <div className={"mb-3"}>
                        <label className={"form-label"}>Name</label>
                        <input className={"form-control"} name={"name"} value={selectedTag.name} onChange={handleFieldChanged}/>
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={"mb-3"}>
                        <label className={"form-label"}>Tag</label>
                        <input className={"form-control"} name={"smartTag"} value={selectedTag.smartTag} onChange={handleFieldChanged}/>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-6"}>
                    <div className={"mb-3"}>
                        <label className={"form-label"}>Exchange Section</label>
                        <select className={"form-control"} name={"mappingObjectId"} onChange={handleFieldChanged} value={selectedTag.mappingObjectId || 0}>
                            <option disabled value={0}>Select an Exchange Section</option>
                            {mappingObjectTypes.map((ot, idx) => <option key={idx} value={ot.id}>{ot.name}</option>)}
                        </select>
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={selectedTag.mappingObjectId ? "mb-3" : "display-none"}>
                        <label className={"form-label"}>Field</label>
                        <select className={"form-control"} name={"field"} onChange={handleFieldChanged} value={selectedTag.field}>
                            <option disabled value={0}>Select a Field</option>
                            {classFields.map((cf, idx) => <option key={idx} value={cf.id}>{cf.name}</option>)}
                        </select>
                    </div>
                </div>
            </div>
            <div className={selectedTag.mappingObjectId && selectedTag.mappingObjectId === 1 ? "row" : "display-none"}>
                <div className={"col-6"}>
                    <div className={"mb-3"}>
                        <label className={"form-label"}>Contact Type</label>
                        <select className={"form-control"} name={"contactTypeId"} onChange={handleFieldChanged} value={selectedTag.contactTypeId || 0}>
                            <option disabled value={0}>Select a Contact Type</option>
                            {contactTypes.map((ct, idx) => <option key={idx} value={ct.id}>{selectedTag.propertyTypeId === 2 && ct.id === 2 ? "Seller" : ct.name}</option>)}
                        </select>
                    </div>
                </div>
                <div className={"col-6"}>
                    <div className={"mb-3"}>
                        <label className={"form-label"}>Contact Tab</label>
                        <select className={"form-control"} name={"contactTabId"} onChange={handleFieldChanged} value={selectedTag.contactTabId || 0}>
                            <option disabled value={0}>Select a Contact Tab</option>
                            {contactTabs.map((c, idx) => <option key={idx} value={c.id}>{c.name}</option>)}
                        </select>
                    </div>
                </div>
            </div>
            <div className={selectedTag.mappingObjectId && selectedTag.mappingObjectId === 1 && selectedTag.contactTypeId !== TAXPAYER_ID ? "row" : "display-none"}>
                <div className={"col-6"}>
                    <div className={"mb-3"}>
                        <label className={"form-label"}>Property Type</label>
                        <select className={"form-control"} name={"propertyTypeId"} onChange={handleFieldChanged} value={selectedTag.propertyTypeId || 0}>
                            <option disabled value={0}>Select a Property Type</option>
                            {propertyTypes.map((pt, idx) => <option key={idx} value={pt.id}>{pt.name}</option>)}
                        </select>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12 text-end"}>
                    <button className={"btn btn-sm btn-outline-primary"} onClick={handleSaveChanges}>{selectedTag.id > 0 ? "Save Changes" : "Add Tag"}</button>
                    <button className={"btn btn-sm btn-outline-secondary ms-2"} onClick={closeModal}>Cancel</button>
                    <button className={selectedTag.id > 0 ? "btn btn-sm btn-outline-danger ms-2" : "display-none"} onClick={handleDeleteTag}>Delete Tag</button>
                </div>
            </div>
        </div>
    </div>);
}

export default TagEditor;