import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDeleteLeft, faSave, faTimesCircle, faUserLock} from "@fortawesome/free-solid-svg-icons";
import {
    clearUserForm,
    loadAllRoles,
    loadUserRole,
    loadUsers, removeUserFromApplication,
    saveUser, toggleUserModal,
    updateCurrentUserField
} from "../../actions/user-admin";

export const UserDetail = () => {

    const dispatch = useDispatch();

    const selectedUser = useSelector(state => state.userAdmin.selectedUser);
    const roles = useSelector(state => state.userAdmin.roles);

    useEffect(() => {
        const load = async () => {
            await dispatch(loadAllRoles());
            if (selectedUser.userId) {
                await dispatch(loadUserRole(selectedUser.userId));
            }
        }
        load();
        // eslint-disable-next-line
    }, [dispatch]);

    const handleUserFieldChanged = (e) => {
        dispatch(updateCurrentUserField(e.target.name, e.target.value));
    }

    const saveUserData = async () => {
        await dispatch(saveUser());
        await dispatch(loadUsers());
        await dispatch(toggleUserModal(false));
        await dispatch(clearUserForm());

    }

    const closeUserModal = async function () {
        await dispatch(loadUsers());
        await dispatch(toggleUserModal(false));
        await dispatch(clearUserForm());
    }

    const handleRemoveUser = async function() {
        if(window.confirm("This user will no longer have access to this application.  Do you want to continue?")){
            await dispatch(removeUserFromApplication(selectedUser.userId));
            await dispatch(loadUsers());
            await dispatch(toggleUserModal(false));
            await dispatch(clearUserForm());
        }
    }

    return (
        <div className={"p-2"}>
            <div className={"row m-0 p-0 ms-1 mb-3"}>
                <div className={"col-3 m-0 p-0 text-center"}>
                    <img src={selectedUser.picture} alt={""} style={{minHeight: "100px", height: "100px", borderRadius: "50px"}}/>
                </div>
                <div className={"col-9 m-0 p-0 pt-3"}>
                    <div><span className={"fw-bold"}>User Id: </span>{selectedUser.userId}</div>
                    <div><span className={"fw-bold"}>Last Login: </span>{selectedUser.lastLogin ? new Date(selectedUser.lastLogin).toLocaleString([], {timeZoneName: "short", hour12: true}) : <span>&nbsp;</span>}</div>
                    <div><span className={"fw-bold"}>IP Address: </span>{selectedUser.lastIpAddress}</div>
                </div>
            </div>
            <div className={"row m-0 p-0 mb-1"}>
                <label className="col-2 small p-0 m-0 text-end pe-2 pt-2">Name</label>
                <div className={"col-10 p-0 m-0"}>
                    <input type="text" name={"fullname"} className="col-10 form-control" value={selectedUser.fullname} onChange={handleUserFieldChanged}/>
                </div>
            </div>
            <div className={"row m-0 p-0 mb-1"}>
                <label className="col-2 small p-0 m-0 text-end pe-2 pt-2">Email</label>
                <div className={"col-10 p-0 m-0"}>
                    <input type="text" name={"email"} className="col-10 form-control" value={selectedUser.email} onChange={handleUserFieldChanged}/>
                </div>
            </div>
            {!selectedUser.userId ?
            <div className={"row m-0 p-0 mb-1"}>
                <label className="col-2 small p-0 m-0 text-end pe-2 pt-2">Password</label>
                <div className={"col-10 p-0 m-0"}>
                    <input type="password" name={"password"} className="col-10 form-control" value={selectedUser.password} onChange={handleUserFieldChanged}/>
                </div>
            </div> : null}
            <div className={"row m-0 p-0 mb-1"}>
                <label className="col-2 small p-0 m-0 text-end pe-2 pt-2">Role</label>
                <div className={"col-10 p-0 m-0"}>
                    <select name={"roleId"} className="form-select col-10" value={selectedUser.roleId} onChange={handleUserFieldChanged}>
                        <option disabled value={""}>Select a Role</option>
                        {roles.map((r, idx) => <option key={idx} value={r.id} className={"form-select"}>{r.name}</option>)}
                    </select>
                </div>
            </div>
            <div className={"row m-0 p-0 my-3"}>
                <div className={"col-12 m-0 p-0 text-end"}>
                    <button className={"btn btn-outline-dark me-2"} title={"Remove User"} onClick={handleRemoveUser}><FontAwesomeIcon icon={faDeleteLeft} />&nbsp;Remove User</button>
                    <button className={"btn btn-outline-dark me-2"} title={"Reset Password"}><FontAwesomeIcon icon={faUserLock} />&nbsp;Reset Password</button>
                    <button className={"btn btn-outline-dark me-2"} title={"Save Changes"}  onClick={saveUserData}><FontAwesomeIcon icon={faSave}/>&nbsp;Save</button>
                    <button className={"btn btn-outline-dark"} title={"Cancel"}  onClick={closeUserModal}><FontAwesomeIcon icon={faTimesCircle}/>&nbsp;Cancel</button>
                </div>
            </div>
        </div>
    );
}