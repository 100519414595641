import {
    ARCHIVE_TOPIC, DELETE_TOPIC,
    LOAD_TOPICS,
    LOG_OUT,
    RESET_MESSAGE_SCREEN,
    SELECT_MESSAGE_TOPIC,
    SEND_MESSAGE, START_NEW_TOPIC, TOGGLE_ARCHIVED_TOPICS, UNARCHIVE_TOPIC,
    UPDATE_MESSAGE_FIELD
} from '../actions/action-types'

const initialState = {
    activeMessage: { content: "", toEmail: "", topic: ""},
    topics: [],
    selectedTopic: {},
    messageHistory: [],
    showArchived: false
}

const session = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            newState.activeMessage = {};
            newState.topics = [];
            newState.selectedTopic = {};
            newState.messageHistory = [];
            return newState;

        case SEND_MESSAGE:
            newState = Object.assign({}, state);
            const message = Object.assign({}, state.activeMessage);
            message.content = "";
            newState.activeMessage = message;
            return newState;

        case UPDATE_MESSAGE_FIELD:
            newState = Object.assign({}, state);
            const msg = Object.assign({}, state.activeMessage);
            msg[action.payload.name] = action.payload.value;
            newState.activeMessage = Object.assign({}, msg);
            return newState;

        case LOAD_TOPICS:
            newState = Object.assign({}, state);
            newState.topics = action.payload;
            return newState;

        case SELECT_MESSAGE_TOPIC:
            newState = Object.assign({}, state);
            newState.selectedTopic = action.payload.topic;
            newState.messageHistory = action.payload.data;
            newState.activeMessage = Object.assign({}, action.payload.data[0]);
            newState.activeMessage.id = 0;
            newState.activeMessage.content = "";
            newState.activeMessage.toEmail = newState.activeMessage.to === newState.activeMessage.username ? newState.activeMessage.fromEmail : newState.activeMessage.toEmail;
            newState.sent = "";
            return newState;

        case RESET_MESSAGE_SCREEN:
            newState = Object.assign({}, initialState);
            return newState;

        case TOGGLE_ARCHIVED_TOPICS:
            newState = Object.assign({}, state);
            newState.showArchived = action.payload;
            return newState;

        case START_NEW_TOPIC:
            newState = Object.assign({}, state);
            newState.messageHistory = [];
            newState.selectedTopic = {};
            newState.activeMessage = Object.assign({}, initialState.activeMessage);
            return newState;

        case ARCHIVE_TOPIC:
            newState = Object.assign({}, state);
            if(!state.showArchived)
                newState.topics = newState.topics.filter(t => t.id !== action.payload.id);
            return newState;

        case UNARCHIVE_TOPIC:
            newState = Object.assign({}, state);
            newState.topics = newState.topics.filter(t => t.id !== action.payload.id);
            newState.topics.push(action.payload);
            return newState;

        case DELETE_TOPIC:
            newState = Object.assign({}, state);
            newState.topics = newState.topics.filter(t => t.id !== action.payload.id);
            return newState;


        default:
            return state
    }
}

export default session