import React, {useEffect} from "react";
import Exchange from "./exchange";
import ExchangeAttachments from "./exchange-attachments";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    getExchangeById,
    loadContactTabs,
    loadContactTypes,
    loadExchangeTypes,
    loadPropertyTabs
} from "../../actions/exchange";
import ExchangeToolbar from "./toolbar";
import Loading from "../loading";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {ExchangeNav} from "./left-nav";
import ExchangeContacts from "./exchange-contacts";
import {clearExchange} from "../../actions/ui";
import {ExchangeOverview} from "./exchange-overview";
import {Properties} from "./properties";

export const ExchangeInfo = function () {

    const { id, section } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearExchange());
        dispatch(getExchangeById(id));
        dispatch(loadContactTabs());
        dispatch(loadContactTypes());
        dispatch(loadExchangeTypes());
        dispatch(loadPropertyTabs());
    }, [id, dispatch]);

    const renderSection = () => {
        switch(section) {

            case "detail":       return <ExchangeOverview exchangeId={id}/>;
            case "basic":       return <Exchange id={id}/>;
            case "properties":    return <Properties exchangeId={id} section={section}/>;
            case "taxpayer":    return <ExchangeContacts exchangeId={id}/>;
            case "accounting":    return <div className="card col-12 border-1 p-0 m-0 mt-2 border-dark shadow">
                <div style={{minHeight: "40vh"}} className={"m-0 p-2"}>Accounting coming soon</div></div>;
            case "attachments":    return <ExchangeAttachments exchangeId={id}/>;

            default:          return <h1>{section}</h1>
        }
    }

    return (<div className="card border-1 shadow my-5 col container-card p-2">
        <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
            <div className="row m-0 p-0">
                <div className="col-2 p-0 pt-3 pt-2 menu-column" style={{minHeight: "80vh"}}>
                    <ExchangeNav section={section} id={id}/>
                </div>
                <div className="col-10 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                    <ExchangeToolbar section={section} exchangeId={id}/>
                    {renderSection()}
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(ExchangeInfo, {
    onRedirecting: () => <Loading />,
});
