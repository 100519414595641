import React, {useEffect} from "react";
import Contact from "../contact-info/contact";
import {useDispatch, useSelector} from "react-redux";
import {
    clearPropertyAddress,
    loadContactForTab,
    loadRepresentativesForContact,
    setContactTab
} from "../../actions/exchange";
import {clearCurrentContact} from "../../actions/contact";
import {signatureAndVestingTabName, TAXPAYER_ID} from "../../constants/settings";
import Representatives from "./representatives";

export const ExchangeContacts = function (props) {

    const dispatch = useDispatch();
    const {exchangeId} = props;
    const tabs = useSelector(state => state.exchange.contactTabs);
    const selectedTab = useSelector(state => state.exchange.selectedTab);
    const currentTab = tabs.find(t => t.id === selectedTab);


    useEffect(() => {
        if(currentTab) {
            const lookupParams = {exchangeId, typeId: TAXPAYER_ID, tabId: currentTab.id};
            dispatch(clearCurrentContact());
            dispatch(clearPropertyAddress());
            if(currentTab.name !== signatureAndVestingTabName){
                dispatch(loadContactForTab(lookupParams));
            }
        }
    }, [exchangeId, selectedTab, dispatch, currentTab]);

    const generateTabs = function () {
        return tabs.map((t, idx) => <li key={idx} className="nav-item" role="presentation">
            <button className={t.id === selectedTab ? "nav-link active small" : "nav-link small"} type="button" role="tab" onClick={() => dispatch(setContactTab(t.id))}>{t.name}
            </button>
        </li>)
    }

    const handleContactSaved = function (e) {
        if(currentTab && currentTab.name === signatureAndVestingTabName){
            dispatch(loadRepresentativesForContact({exchangeId, typeId: TAXPAYER_ID, tabId: selectedTab}));
            dispatch(clearCurrentContact());
        }
    }

    return (<div className="card col-12 border-1 p-0 m-0 mt-2 pt-2 px-1 border-dark shadow vtabs">
        <div className={"row m-0 p-0"}>
            <div className={"col-10 offset-1 p-0"}>
                <ul className="nav nav-tabs">
                    {generateTabs()}
                </ul>
            </div>
        </div>
            <div className="tab-content border-end-1 p-0">
                <div className={"row m-0 p-0"}>
                    <div className={"col-10 offset-1 p-0 pt-1"}>
                        {currentTab && currentTab.name === signatureAndVestingTabName ? <div>
                                <Representatives exchangeId={exchangeId} typeId={TAXPAYER_ID} tabId={selectedTab} taxpayer={true}/>
                            <hr/>
                                <Contact hideUserButton={true} exchangeId={exchangeId} typeId={TAXPAYER_ID} tabId={selectedTab} contactSaved={handleContactSaved} taxpayer={true}/>
                            </div>:
                        <Contact hideUserButton={true} exchangeId={exchangeId} typeId={TAXPAYER_ID} tabId={selectedTab} taxpayer={true}/>}
                    </div>
                </div>
            </div>
    </div>);
}

export default ExchangeContacts;
