import React, {useEffect} from 'react';
import {AgGridReact} from "ag-grid-react";
import {AvatarFormatter, BooleanFormatter, DateTimeFormatter} from "../../utilities/grid-renderers";
import {useDispatch, useSelector} from "react-redux";
import {loadUserRole, loadUsers, selectUser, toggleUserModal} from "../../actions/user-admin";

export const UserGrid = () => {

    const dispatch = useDispatch();
    const users = useSelector(state => state.userAdmin.users);

    const columnDefs = [
        {
            headerName: '',
            field: 'picture',
            maxWidth: 50,
            sortable: false,
            filter: false,
            cellRenderer: 'AvatarFormatter',
            cellClass: 'mt-1'
        },
        {
            headerName: 'Name',
            field: 'fullname',
            cellClass: "mt-2"
        },
        {
            headerName: 'Email Verified',
            field: 'emailVerified',
            cellClass: "mt-2",
            maxWidth: 150,
            cellRenderer: 'BooleanFormatter',
            headerClass: 'text-center'
        },
        {
            headerName: 'Last IP Address',
            field: 'lastIpAddress',
            cellClass: "mt-2",
            headerClass: 'text-center'
        },
        {
            headerName: 'Last Login',
            field: 'lastLogin',
            cellClass: "mt-2",
            cellRenderer: "DateTimeFormatter",
            headerClass: "text-center"
        }
    ];

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch])

    const userSelected = async (e) => {
        await dispatch(selectUser(e.data));
        await dispatch(loadUserRole(e.data.userId));
        await dispatch(toggleUserModal(true));
    }

    return (
        <div className={"ag-theme-alpine"}  style={{height: '85vh'}}>
            <AgGridReact
                components={{
                    AvatarFormatter: AvatarFormatter,
                    BooleanFormatter: BooleanFormatter,
                    DateTimeFormatter: DateTimeFormatter
                }}
                defaultColDef={{
                    sortable: true,
                    flex: 1,
                    filter: true,
                    resizable: true
                }}
                rowData={users}
                columnDefs={columnDefs}
                suppressCellFocus={true}
                onRowClicked={userSelected}>
            </AgGridReact>
        </div>
    );
}
