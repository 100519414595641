/* tslint:disable */
/* eslint-disable */
/**
 * AppSparc.Ten31.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationSettingModel
 */
export interface ApplicationSettingModel {
    /**
     * 
     * @type {string}
     * @memberof ApplicationSettingModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSettingModel
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface AppsparcRole
 */
export interface AppsparcRole {
    /**
     * 
     * @type {string}
     * @memberof AppsparcRole
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcRole
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcRole
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AppsparcUser
 */
export interface AppsparcUser {
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AppsparcUser
     */
    'emailVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'fullname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'lastIpAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'lastLogin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'nickName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'picture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'roleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AppsparcUser
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface BankModel
 */
export interface BankModel {
    /**
     * 
     * @type {string}
     * @memberof BankModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankModel
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankModel
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankModel
     */
    'routingNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactModel
 */
export interface ContactModel {
    /**
     * 
     * @type {number}
     * @memberof ContactModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'entity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'homePhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'workPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'mobilePhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'userId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactModel
     */
    'exchangeId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContactModel
     */
    'typeId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContactModel
     */
    'tabId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContactModel
     */
    'propertyAddressId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'tab'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'escrowNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'pointOfContact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'signatureLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'oneLineAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactTabModel
 */
export interface ContactTabModel {
    /**
     * 
     * @type {number}
     * @memberof ContactTabModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactTabModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactTypeModel
 */
export interface ContactTypeModel {
    /**
     * 
     * @type {number}
     * @memberof ContactTypeModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactTypeModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentFolderModel
 */
export interface DocumentFolderModel {
    /**
     * 
     * @type {number}
     * @memberof DocumentFolderModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFolderModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentMappingModel
 */
export interface DocumentMappingModel {
    /**
     * 
     * @type {number}
     * @memberof DocumentMappingModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentMappingModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentMappingModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentMappingModel
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentMappingModel
     */
    'fileData'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailTemplateModel
 */
export interface EmailTemplateModel {
    /**
     * 
     * @type {number}
     * @memberof EmailTemplateModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateModel
     */
    'subjectTemplate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateModel
     */
    'template'?: string | null;
}
/**
 * 
 * @export
 * @interface ExchangeAttachmentModel
 */
export interface ExchangeAttachmentModel {
    /**
     * 
     * @type {number}
     * @memberof ExchangeAttachmentModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeAttachmentModel
     */
    'fileData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeAttachmentModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeAttachmentModel
     */
    'attachmentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeAttachmentModel
     */
    'fileContentBytes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeAttachmentModel
     */
    'exchangeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeAttachmentModel
     */
    'documentFolderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeAttachmentModel
     */
    'filename'?: string | null;
}
/**
 * 
 * @export
 * @interface ExchangeContactModel
 */
export interface ExchangeContactModel {
    /**
     * 
     * @type {number}
     * @memberof ExchangeContactModel
     */
    'exchangeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeContactModel
     */
    'contactId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeContactModel
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeContactModel
     */
    'tabId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangeContactModel
     */
    'propertyAddressId'?: number | null;
}
/**
 * 
 * @export
 * @interface ExchangeFullModel
 */
export interface ExchangeFullModel {
    /**
     * 
     * @type {number}
     * @memberof ExchangeFullModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'exchangeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'exchangeDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerVesting'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeFullModel
     */
    'taxpayerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerPostalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'taxpayerHomePhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'escrowFileReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'dateOfEscrowInstructions'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeFullModel
     */
    'propertyAddressId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'propertyAddressAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'propertyAddressAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'propertyAddressCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'propertyAddressState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'propertyAddressPostalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'buyerVesting'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyAddress1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyAddress2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyPostalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyWorkPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'escrowCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'closing'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeFullModel
     */
    'closed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'currentDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'escrowClose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'sellerAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'propertyAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeFullModel
     */
    'settlementAgencyAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface ExchangeInfo
 */
export interface ExchangeInfo {
    /**
     * 
     * @type {number}
     * @memberof ExchangeInfo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeInfo
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'exchangeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'exchangeDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'taxpayerVesting'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'escrowFileReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'dateOfEscrowInstructions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'buyerVesting'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeInfo
     */
    'exchangeTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'exchangeOfficer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'agency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeInfo
     */
    'amountReceived'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeInfo
     */
    'interestAccountOpened'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'openDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'closeOfEscrowFundsReceived'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'fortyFiveDays'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'oneEightyDays'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeInfo
     */
    'propertyAddressId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'county'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'addressType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'exchangeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'taxpayerCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'taxpayer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'buyerCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeInfo
     */
    'buyer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeInfo
     */
    'closed'?: boolean;
}
/**
 * 
 * @export
 * @interface ExchangeModel
 */
export interface ExchangeModel {
    /**
     * 
     * @type {number}
     * @memberof ExchangeModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'exchangeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'exchangeDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'taxpayerVesting'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'escrowFileReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'dateOfEscrowInstructions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'buyerVesting'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeModel
     */
    'exchangeTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'exchangeOfficer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'agency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExchangeModel
     */
    'amountReceived'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeModel
     */
    'interestAccountOpened'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'openDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'closeOfEscrowFundsReceived'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'fortyFiveDays'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'oneEightyDays'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeModel
     */
    'closed'?: boolean;
}
/**
 * 
 * @export
 * @interface ExchangeTypeModel
 */
export interface ExchangeTypeModel {
    /**
     * 
     * @type {number}
     * @memberof ExchangeTypeModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeTypeModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface FieldMappingModel
 */
export interface FieldMappingModel {
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldMappingModel
     */
    'contents'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'documentMappingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'scaleWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'scaleHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof FieldMappingModel
     */
    'pageNumber'?: number;
}
/**
 * 
 * @export
 * @interface ListItemModel
 */
export interface ListItemModel {
    /**
     * 
     * @type {string}
     * @memberof ListItemModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListItemModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MappingObjectModel
 */
export interface MappingObjectModel {
    /**
     * 
     * @type {number}
     * @memberof MappingObjectModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MappingObjectModel
     */
    'object'?: string | null;
}
/**
 * 
 * @export
 * @interface MappingOptionModel
 */
export interface MappingOptionModel {
    /**
     * 
     * @type {number}
     * @memberof MappingOptionModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MappingOptionModel
     */
    'field'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MappingOptionModel
     */
    'smartTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MappingOptionModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MappingOptionModel
     */
    'mappingObjectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MappingOptionModel
     */
    'mappingObject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MappingOptionModel
     */
    'contactTabId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MappingOptionModel
     */
    'contactTab'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MappingOptionModel
     */
    'contactTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MappingOptionModel
     */
    'contactType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MappingOptionModel
     */
    'propertyTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MappingOptionModel
     */
    'propertyType'?: string | null;
}
/**
 * 
 * @export
 * @interface MessageModel
 */
export interface MessageModel {
    /**
     * 
     * @type {number}
     * @memberof MessageModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'fromEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'to'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'toEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'toName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'sent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'topic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'username'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageModel
     */
    'isSender'?: boolean;
}
/**
 * 
 * @export
 * @interface MessageTopicModel
 */
export interface MessageTopicModel {
    /**
     * 
     * @type {number}
     * @memberof MessageTopicModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageTopicModel
     */
    'topic'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageTopicModel
     */
    'archived'?: boolean;
}
/**
 * 
 * @export
 * @interface OrderRegistration
 */
export interface OrderRegistration {
    /**
     * 
     * @type {number}
     * @memberof OrderRegistration
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRegistration
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangeNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'agreementDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'relinquishedStreetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'relinquishedCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'relinquishedState'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderRegistration
     */
    'relinquishedPercentOwnership'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRegistration
     */
    'relinquishedSalePrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'exchangerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'buyerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'settlementAgentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'settlementAgency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'settlementAgencyAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'settlementAgencyCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'settlementAgencyState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'settlementAgencyZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'settlementAgencyPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'settlementAgencyEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRegistration
     */
    'escrowNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface PropertyAddressModel
 */
export interface PropertyAddressModel {
    /**
     * 
     * @type {number}
     * @memberof PropertyAddressModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyAddressModel
     */
    'exchangeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'addressType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'county'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'legalDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyAddressModel
     */
    'propertyTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'escrowNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressModel
     */
    'oneLineAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface PropertyTypeModel
 */
export interface PropertyTypeModel {
    /**
     * 
     * @type {number}
     * @memberof PropertyTypeModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyTypeModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface QualifiedIntermediaryModel
 */
export interface QualifiedIntermediaryModel {
    /**
     * 
     * @type {string}
     * @memberof QualifiedIntermediaryModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualifiedIntermediaryModel
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualifiedIntermediaryModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualifiedIntermediaryModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualifiedIntermediaryModel
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualifiedIntermediaryModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualifiedIntermediaryModel
     */
    'pointOfContact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QualifiedIntermediaryModel
     */
    'fax'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSettingModel
 */
export interface UserSettingModel {
    /**
     * 
     * @type {number}
     * @memberof UserSettingModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSettingModel
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettingModel
     */
    'setting'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettingModel
     */
    'value'?: string | null;
}

/**
 * ApplicationSettingApi - axios parameter creator
 * @export
 */
export const ApplicationSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingBankGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationSetting/Bank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BankModel} [bankModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingBankPost: async (bankModel?: BankModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationSetting/Bank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bankModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [settingName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingDelete: async (settingName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (settingName !== undefined) {
                localVarQueryParameter['settingName'] = settingName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationSettingModel} [applicationSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingPost: async (applicationSettingModel?: ApplicationSettingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationSettingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingQIGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationSetting/QI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QualifiedIntermediaryModel} [qualifiedIntermediaryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingQIPost: async (qualifiedIntermediaryModel?: QualifiedIntermediaryModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationSetting/QI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qualifiedIntermediaryModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingSettingNameGet: async (settingName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingName' is not null or undefined
            assertParamExists('apiApplicationSettingSettingNameGet', 'settingName', settingName)
            const localVarPath = `/api/ApplicationSetting/{settingName}`
                .replace(`{${"settingName"}}`, encodeURIComponent(String(settingName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationSettingApi - functional programming interface
 * @export
 */
export const ApplicationSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSettingBankGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSettingBankGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BankModel} [bankModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSettingBankPost(bankModel?: BankModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSettingBankPost(bankModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [settingName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSettingDelete(settingName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSettingDelete(settingName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSettingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationSettingModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSettingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationSettingModel} [applicationSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSettingPost(applicationSettingModel?: ApplicationSettingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSettingPost(applicationSettingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSettingQIGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifiedIntermediaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSettingQIGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QualifiedIntermediaryModel} [qualifiedIntermediaryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSettingQIPost(qualifiedIntermediaryModel?: QualifiedIntermediaryModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSettingQIPost(qualifiedIntermediaryModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSettingSettingNameGet(settingName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationSettingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSettingSettingNameGet(settingName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationSettingApi - factory interface
 * @export
 */
export const ApplicationSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationSettingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingBankGet(options?: any): AxiosPromise<BankModel> {
            return localVarFp.apiApplicationSettingBankGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BankModel} [bankModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingBankPost(bankModel?: BankModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiApplicationSettingBankPost(bankModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [settingName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingDelete(settingName?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiApplicationSettingDelete(settingName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingGet(options?: any): AxiosPromise<Array<ApplicationSettingModel>> {
            return localVarFp.apiApplicationSettingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationSettingModel} [applicationSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingPost(applicationSettingModel?: ApplicationSettingModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiApplicationSettingPost(applicationSettingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingQIGet(options?: any): AxiosPromise<QualifiedIntermediaryModel> {
            return localVarFp.apiApplicationSettingQIGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QualifiedIntermediaryModel} [qualifiedIntermediaryModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingQIPost(qualifiedIntermediaryModel?: QualifiedIntermediaryModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiApplicationSettingQIPost(qualifiedIntermediaryModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSettingSettingNameGet(settingName: string, options?: any): AxiosPromise<ApplicationSettingModel> {
            return localVarFp.apiApplicationSettingSettingNameGet(settingName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationSettingApi - object-oriented interface
 * @export
 * @class ApplicationSettingApi
 * @extends {BaseAPI}
 */
export class ApplicationSettingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationSettingApi
     */
    public apiApplicationSettingBankGet(options?: AxiosRequestConfig) {
        return ApplicationSettingApiFp(this.configuration).apiApplicationSettingBankGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankModel} [bankModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationSettingApi
     */
    public apiApplicationSettingBankPost(bankModel?: BankModel, options?: AxiosRequestConfig) {
        return ApplicationSettingApiFp(this.configuration).apiApplicationSettingBankPost(bankModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [settingName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationSettingApi
     */
    public apiApplicationSettingDelete(settingName?: string, options?: AxiosRequestConfig) {
        return ApplicationSettingApiFp(this.configuration).apiApplicationSettingDelete(settingName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationSettingApi
     */
    public apiApplicationSettingGet(options?: AxiosRequestConfig) {
        return ApplicationSettingApiFp(this.configuration).apiApplicationSettingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationSettingModel} [applicationSettingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationSettingApi
     */
    public apiApplicationSettingPost(applicationSettingModel?: ApplicationSettingModel, options?: AxiosRequestConfig) {
        return ApplicationSettingApiFp(this.configuration).apiApplicationSettingPost(applicationSettingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationSettingApi
     */
    public apiApplicationSettingQIGet(options?: AxiosRequestConfig) {
        return ApplicationSettingApiFp(this.configuration).apiApplicationSettingQIGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QualifiedIntermediaryModel} [qualifiedIntermediaryModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationSettingApi
     */
    public apiApplicationSettingQIPost(qualifiedIntermediaryModel?: QualifiedIntermediaryModel, options?: AxiosRequestConfig) {
        return ApplicationSettingApiFp(this.configuration).apiApplicationSettingQIPost(qualifiedIntermediaryModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationSettingApi
     */
    public apiApplicationSettingSettingNameGet(settingName: string, options?: AxiosRequestConfig) {
        return ApplicationSettingApiFp(this.configuration).apiApplicationSettingSettingNameGet(settingName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactByUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact/ByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactCopyPost: async (exchangeContactModel?: ExchangeContactModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact/Copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeContactModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactCreateUserContactIdGet: async (contactId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('apiContactCreateUserContactIdGet', 'contactId', contactId)
            const localVarPath = `/api/Contact/CreateUser/{contactId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [contactId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactDelete: async (contactId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contactId !== undefined) {
                localVarQueryParameter['contactId'] = contactId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContactIdGet', 'id', id)
            const localVarPath = `/api/Contact/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactPost: async (contactModel?: ContactModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactPut: async (contactModel?: ContactModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactByUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactByUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactCopyPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactCopyPost(exchangeContactModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactCreateUserContactIdGet(contactId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsparcUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactCreateUserContactIdGet(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [contactId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactDelete(contactId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactDelete(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactPost(contactModel?: ContactModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactPost(contactModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactPut(contactModel?: ContactModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactPut(contactModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactByUserGet(options?: any): AxiosPromise<ContactModel> {
            return localVarFp.apiContactByUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactCopyPost(exchangeContactModel?: ExchangeContactModel, options?: any): AxiosPromise<ContactModel> {
            return localVarFp.apiContactCopyPost(exchangeContactModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactCreateUserContactIdGet(contactId: number, options?: any): AxiosPromise<AppsparcUser> {
            return localVarFp.apiContactCreateUserContactIdGet(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [contactId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactDelete(contactId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiContactDelete(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactGet(options?: any): AxiosPromise<Array<ContactModel>> {
            return localVarFp.apiContactGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactIdGet(id: number, options?: any): AxiosPromise<ContactModel> {
            return localVarFp.apiContactIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactPost(contactModel?: ContactModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiContactPost(contactModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactPut(contactModel?: ContactModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiContactPut(contactModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactByUserGet(options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactByUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeContactModel} [exchangeContactModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactCopyPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactCopyPost(exchangeContactModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactCreateUserContactIdGet(contactId: number, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactCreateUserContactIdGet(contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [contactId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactDelete(contactId?: number, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactDelete(contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactGet(options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactIdGet(id: number, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactModel} [contactModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactPost(contactModel?: ContactModel, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactPost(contactModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactModel} [contactModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactPut(contactModel?: ContactModel, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactPut(contactModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentFolderApi - axios parameter creator
 * @export
 */
export const DocumentFolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentFolder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentFolderIdDelete', 'id', id)
            const localVarPath = `/api/DocumentFolder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentFolderIdGet', 'id', id)
            const localVarPath = `/api/DocumentFolder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DocumentFolderModel} [documentFolderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderIdPut: async (id: number, documentFolderModel?: DocumentFolderModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentFolderIdPut', 'id', id)
            const localVarPath = `/api/DocumentFolder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentFolderModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentFolderModel} [documentFolderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderPost: async (documentFolderModel?: DocumentFolderModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentFolder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentFolderModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentFolderApi - functional programming interface
 * @export
 */
export const DocumentFolderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentFolderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentFolderGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentFolderModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentFolderGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentFolderIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentFolderIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentFolderIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentFolderModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentFolderIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DocumentFolderModel} [documentFolderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentFolderIdPut(id: number, documentFolderModel?: DocumentFolderModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentFolderIdPut(id, documentFolderModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DocumentFolderModel} [documentFolderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentFolderPost(documentFolderModel?: DocumentFolderModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentFolderPost(documentFolderModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentFolderApi - factory interface
 * @export
 */
export const DocumentFolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentFolderApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderGet(options?: any): AxiosPromise<Array<DocumentFolderModel>> {
            return localVarFp.apiDocumentFolderGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderIdDelete(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDocumentFolderIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderIdGet(id: number, options?: any): AxiosPromise<DocumentFolderModel> {
            return localVarFp.apiDocumentFolderIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DocumentFolderModel} [documentFolderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderIdPut(id: number, documentFolderModel?: DocumentFolderModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDocumentFolderIdPut(id, documentFolderModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentFolderModel} [documentFolderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentFolderPost(documentFolderModel?: DocumentFolderModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiDocumentFolderPost(documentFolderModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentFolderApi - object-oriented interface
 * @export
 * @class DocumentFolderApi
 * @extends {BaseAPI}
 */
export class DocumentFolderApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentFolderApi
     */
    public apiDocumentFolderGet(options?: AxiosRequestConfig) {
        return DocumentFolderApiFp(this.configuration).apiDocumentFolderGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentFolderApi
     */
    public apiDocumentFolderIdDelete(id: number, options?: AxiosRequestConfig) {
        return DocumentFolderApiFp(this.configuration).apiDocumentFolderIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentFolderApi
     */
    public apiDocumentFolderIdGet(id: number, options?: AxiosRequestConfig) {
        return DocumentFolderApiFp(this.configuration).apiDocumentFolderIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DocumentFolderModel} [documentFolderModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentFolderApi
     */
    public apiDocumentFolderIdPut(id: number, documentFolderModel?: DocumentFolderModel, options?: AxiosRequestConfig) {
        return DocumentFolderApiFp(this.configuration).apiDocumentFolderIdPut(id, documentFolderModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentFolderModel} [documentFolderModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentFolderApi
     */
    public apiDocumentFolderPost(documentFolderModel?: DocumentFolderModel, options?: AxiosRequestConfig) {
        return DocumentFolderApiFp(this.configuration).apiDocumentFolderPost(documentFolderModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentMappingApi - axios parameter creator
 * @export
 */
export const DocumentMappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DocumentMappingModel} [documentMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingAddPost: async (documentMappingModel?: DocumentMappingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentMapping/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentMappingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingClassFieldsClassNameGet: async (className: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'className' is not null or undefined
            assertParamExists('apiDocumentMappingClassFieldsClassNameGet', 'className', className)
            const localVarPath = `/api/DocumentMapping/ClassFields/{className}`
                .replace(`{${"className"}}`, encodeURIComponent(String(className)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} documentMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingDocumentFieldsDocumentMappingIdGet: async (documentMappingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentMappingId' is not null or undefined
            assertParamExists('apiDocumentMappingDocumentFieldsDocumentMappingIdGet', 'documentMappingId', documentMappingId)
            const localVarPath = `/api/DocumentMapping/DocumentFields/{documentMappingId}`
                .replace(`{${"documentMappingId"}}`, encodeURIComponent(String(documentMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [documentMappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingDownloadGet: async (documentMappingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentMapping/Download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (documentMappingId !== undefined) {
                localVarQueryParameter['documentMappingId'] = documentMappingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [documentMappingId] 
         * @param {number} [exchangeId] 
         * @param {number} [folderId] 
         * @param {number} [propertyAddressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingGenerateExchangeGet: async (documentMappingId?: number, exchangeId?: number, folderId?: number, propertyAddressId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentMapping/GenerateExchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (documentMappingId !== undefined) {
                localVarQueryParameter['documentMappingId'] = documentMappingId;
            }

            if (exchangeId !== undefined) {
                localVarQueryParameter['exchangeId'] = exchangeId;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (propertyAddressId !== undefined) {
                localVarQueryParameter['propertyAddressId'] = propertyAddressId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentMapping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} documentMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingRemoveDocumentMappingIdGet: async (documentMappingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentMappingId' is not null or undefined
            assertParamExists('apiDocumentMappingRemoveDocumentMappingIdGet', 'documentMappingId', documentMappingId)
            const localVarPath = `/api/DocumentMapping/Remove/{documentMappingId}`
                .replace(`{${"documentMappingId"}}`, encodeURIComponent(String(documentMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentMappingModel} [documentMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingUpdatePost: async (documentMappingModel?: DocumentMappingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentMapping/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentMappingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentMappingApi - functional programming interface
 * @export
 */
export const DocumentMappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentMappingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DocumentMappingModel} [documentMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentMappingAddPost(documentMappingModel?: DocumentMappingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentMappingAddPost(documentMappingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentMappingClassFieldsClassNameGet(className: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListItemModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentMappingClassFieldsClassNameGet(className, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} documentMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentMappingDocumentFieldsDocumentMappingIdGet(documentMappingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListItemModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentMappingDocumentFieldsDocumentMappingIdGet(documentMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [documentMappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentMappingDownloadGet(documentMappingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentMappingDownloadGet(documentMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [documentMappingId] 
         * @param {number} [exchangeId] 
         * @param {number} [folderId] 
         * @param {number} [propertyAddressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentMappingGenerateExchangeGet(documentMappingId?: number, exchangeId?: number, folderId?: number, propertyAddressId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentMappingGenerateExchangeGet(documentMappingId, exchangeId, folderId, propertyAddressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentMappingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentMappingModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentMappingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} documentMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentMappingRemoveDocumentMappingIdGet(documentMappingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentMappingRemoveDocumentMappingIdGet(documentMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DocumentMappingModel} [documentMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentMappingUpdatePost(documentMappingModel?: DocumentMappingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentMappingUpdatePost(documentMappingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentMappingApi - factory interface
 * @export
 */
export const DocumentMappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentMappingApiFp(configuration)
    return {
        /**
         * 
         * @param {DocumentMappingModel} [documentMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingAddPost(documentMappingModel?: DocumentMappingModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiDocumentMappingAddPost(documentMappingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingClassFieldsClassNameGet(className: string, options?: any): AxiosPromise<Array<ListItemModel>> {
            return localVarFp.apiDocumentMappingClassFieldsClassNameGet(className, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} documentMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingDocumentFieldsDocumentMappingIdGet(documentMappingId: number, options?: any): AxiosPromise<Array<ListItemModel>> {
            return localVarFp.apiDocumentMappingDocumentFieldsDocumentMappingIdGet(documentMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [documentMappingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingDownloadGet(documentMappingId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocumentMappingDownloadGet(documentMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [documentMappingId] 
         * @param {number} [exchangeId] 
         * @param {number} [folderId] 
         * @param {number} [propertyAddressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingGenerateExchangeGet(documentMappingId?: number, exchangeId?: number, folderId?: number, propertyAddressId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDocumentMappingGenerateExchangeGet(documentMappingId, exchangeId, folderId, propertyAddressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingGet(options?: any): AxiosPromise<Array<DocumentMappingModel>> {
            return localVarFp.apiDocumentMappingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} documentMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingRemoveDocumentMappingIdGet(documentMappingId: number, options?: any): AxiosPromise<number> {
            return localVarFp.apiDocumentMappingRemoveDocumentMappingIdGet(documentMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentMappingModel} [documentMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentMappingUpdatePost(documentMappingModel?: DocumentMappingModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiDocumentMappingUpdatePost(documentMappingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentMappingApi - object-oriented interface
 * @export
 * @class DocumentMappingApi
 * @extends {BaseAPI}
 */
export class DocumentMappingApi extends BaseAPI {
    /**
     * 
     * @param {DocumentMappingModel} [documentMappingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMappingApi
     */
    public apiDocumentMappingAddPost(documentMappingModel?: DocumentMappingModel, options?: AxiosRequestConfig) {
        return DocumentMappingApiFp(this.configuration).apiDocumentMappingAddPost(documentMappingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} className 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMappingApi
     */
    public apiDocumentMappingClassFieldsClassNameGet(className: string, options?: AxiosRequestConfig) {
        return DocumentMappingApiFp(this.configuration).apiDocumentMappingClassFieldsClassNameGet(className, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} documentMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMappingApi
     */
    public apiDocumentMappingDocumentFieldsDocumentMappingIdGet(documentMappingId: number, options?: AxiosRequestConfig) {
        return DocumentMappingApiFp(this.configuration).apiDocumentMappingDocumentFieldsDocumentMappingIdGet(documentMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [documentMappingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMappingApi
     */
    public apiDocumentMappingDownloadGet(documentMappingId?: number, options?: AxiosRequestConfig) {
        return DocumentMappingApiFp(this.configuration).apiDocumentMappingDownloadGet(documentMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [documentMappingId] 
     * @param {number} [exchangeId] 
     * @param {number} [folderId] 
     * @param {number} [propertyAddressId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMappingApi
     */
    public apiDocumentMappingGenerateExchangeGet(documentMappingId?: number, exchangeId?: number, folderId?: number, propertyAddressId?: number, options?: AxiosRequestConfig) {
        return DocumentMappingApiFp(this.configuration).apiDocumentMappingGenerateExchangeGet(documentMappingId, exchangeId, folderId, propertyAddressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMappingApi
     */
    public apiDocumentMappingGet(options?: AxiosRequestConfig) {
        return DocumentMappingApiFp(this.configuration).apiDocumentMappingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} documentMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMappingApi
     */
    public apiDocumentMappingRemoveDocumentMappingIdGet(documentMappingId: number, options?: AxiosRequestConfig) {
        return DocumentMappingApiFp(this.configuration).apiDocumentMappingRemoveDocumentMappingIdGet(documentMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentMappingModel} [documentMappingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMappingApi
     */
    public apiDocumentMappingUpdatePost(documentMappingModel?: DocumentMappingModel, options?: AxiosRequestConfig) {
        return DocumentMappingApiFp(this.configuration).apiDocumentMappingUpdatePost(documentMappingModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailTemplateApi - axios parameter creator
 * @export
 */
export const EmailTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailTemplateModel} [emailTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplatePost: async (emailTemplateModel?: EmailTemplateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [templateId] 
         * @param {string} [removedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateRemoveGet: async (templateId?: number, removedBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }

            if (removedBy !== undefined) {
                localVarQueryParameter['removedBy'] = removedBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailTemplateModel} [emailTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateUpdatePost: async (emailTemplateModel?: EmailTemplateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailTemplateApi - functional programming interface
 * @export
 */
export const EmailTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmailTemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailTemplateModel} [emailTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplatePost(emailTemplateModel?: EmailTemplateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplatePost(emailTemplateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [templateId] 
         * @param {string} [removedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateRemoveGet(templateId?: number, removedBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateRemoveGet(templateId, removedBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailTemplateModel} [emailTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateUpdatePost(emailTemplateModel?: EmailTemplateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateUpdatePost(emailTemplateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailTemplateApi - factory interface
 * @export
 */
export const EmailTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateGet(options?: any): AxiosPromise<Array<EmailTemplateModel>> {
            return localVarFp.apiEmailTemplateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailTemplateModel} [emailTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplatePost(emailTemplateModel?: EmailTemplateModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiEmailTemplatePost(emailTemplateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [templateId] 
         * @param {string} [removedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateRemoveGet(templateId?: number, removedBy?: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiEmailTemplateRemoveGet(templateId, removedBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailTemplateModel} [emailTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateUpdatePost(emailTemplateModel?: EmailTemplateModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiEmailTemplateUpdatePost(emailTemplateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailTemplateApi - object-oriented interface
 * @export
 * @class EmailTemplateApi
 * @extends {BaseAPI}
 */
export class EmailTemplateApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateGet(options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailTemplateModel} [emailTemplateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplatePost(emailTemplateModel?: EmailTemplateModel, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplatePost(emailTemplateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [templateId] 
     * @param {string} [removedBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateRemoveGet(templateId?: number, removedBy?: string, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateRemoveGet(templateId, removedBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailTemplateModel} [emailTemplateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateUpdatePost(emailTemplateModel?: EmailTemplateModel, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateUpdatePost(emailTemplateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExchangeApi - axios parameter creator
 * @export
 */
export const ExchangeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAddContactPost: async (exchangeContactModel?: ExchangeContactModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/AddContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeContactModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeClosedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/Closed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeModel} [exchangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeCreatePost: async (exchangeModel?: ExchangeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeDeleteIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExchangeDeleteIdGet', 'id', id)
            const localVarPath = `/api/Exchange/Delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeForUserContactIdGet: async (contactId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('apiExchangeForUserContactIdGet', 'contactId', contactId)
            const localVarPath = `/api/Exchange/ForUser/{contactId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeForUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/ForUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeGetContactForTabPost: async (exchangeContactModel?: ExchangeContactModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/GetContactForTab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeContactModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [exchangeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeGetContactsGet: async (exchangeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/GetContacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exchangeId !== undefined) {
                localVarQueryParameter['exchangeId'] = exchangeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExchangeIdGet', 'id', id)
            const localVarPath = `/api/Exchange/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeRemoveContactPost: async (exchangeContactModel?: ExchangeContactModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/RemoveContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeContactModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeRepresentativesPost: async (exchangeContactModel?: ExchangeContactModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/Representatives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeContactModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {boolean} closed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeToggleStatusExchangeIdClosedGet: async (exchangeId: number, closed: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeId' is not null or undefined
            assertParamExists('apiExchangeToggleStatusExchangeIdClosedGet', 'exchangeId', exchangeId)
            // verify required parameter 'closed' is not null or undefined
            assertParamExists('apiExchangeToggleStatusExchangeIdClosedGet', 'closed', closed)
            const localVarPath = `/api/Exchange/ToggleStatus/{exchangeId}/{closed}`
                .replace(`{${"exchangeId"}}`, encodeURIComponent(String(exchangeId)))
                .replace(`{${"closed"}}`, encodeURIComponent(String(closed)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeModel} [exchangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeUpdatePost: async (exchangeModel?: ExchangeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exchange/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeApi - functional programming interface
 * @export
 */
export const ExchangeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeAddContactPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeAddContactPost(exchangeContactModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeClosedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExchangeInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeClosedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeModel} [exchangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeCreatePost(exchangeModel?: ExchangeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeCreatePost(exchangeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeDeleteIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeDeleteIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeForUserContactIdGet(contactId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExchangeFullModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeForUserContactIdGet(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeForUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExchangeFullModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeForUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExchangeInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeGetContactForTabPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeGetContactForTabPost(exchangeContactModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [exchangeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeGetContactsGet(exchangeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeGetContactsGet(exchangeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeRemoveContactPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeRemoveContactPost(exchangeContactModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeRepresentativesPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeRepresentativesPost(exchangeContactModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {boolean} closed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeToggleStatusExchangeIdClosedGet(exchangeId: number, closed: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeToggleStatusExchangeIdClosedGet(exchangeId, closed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeModel} [exchangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeUpdatePost(exchangeModel?: ExchangeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeUpdatePost(exchangeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExchangeApi - factory interface
 * @export
 */
export const ExchangeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeApiFp(configuration)
    return {
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAddContactPost(exchangeContactModel?: ExchangeContactModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiExchangeAddContactPost(exchangeContactModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeClosedGet(options?: any): AxiosPromise<Array<ExchangeInfo>> {
            return localVarFp.apiExchangeClosedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeModel} [exchangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeCreatePost(exchangeModel?: ExchangeModel, options?: any): AxiosPromise<ExchangeModel> {
            return localVarFp.apiExchangeCreatePost(exchangeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeDeleteIdGet(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiExchangeDeleteIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeForUserContactIdGet(contactId: number, options?: any): AxiosPromise<Array<ExchangeFullModel>> {
            return localVarFp.apiExchangeForUserContactIdGet(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeForUserGet(options?: any): AxiosPromise<Array<ExchangeFullModel>> {
            return localVarFp.apiExchangeForUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeGet(options?: any): AxiosPromise<Array<ExchangeInfo>> {
            return localVarFp.apiExchangeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeGetContactForTabPost(exchangeContactModel?: ExchangeContactModel, options?: any): AxiosPromise<ContactModel> {
            return localVarFp.apiExchangeGetContactForTabPost(exchangeContactModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [exchangeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeGetContactsGet(exchangeId?: number, options?: any): AxiosPromise<Array<ContactModel>> {
            return localVarFp.apiExchangeGetContactsGet(exchangeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeIdGet(id: number, options?: any): AxiosPromise<ExchangeModel> {
            return localVarFp.apiExchangeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeRemoveContactPost(exchangeContactModel?: ExchangeContactModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiExchangeRemoveContactPost(exchangeContactModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeContactModel} [exchangeContactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeRepresentativesPost(exchangeContactModel?: ExchangeContactModel, options?: any): AxiosPromise<Array<ContactModel>> {
            return localVarFp.apiExchangeRepresentativesPost(exchangeContactModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {boolean} closed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeToggleStatusExchangeIdClosedGet(exchangeId: number, closed: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiExchangeToggleStatusExchangeIdClosedGet(exchangeId, closed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeModel} [exchangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeUpdatePost(exchangeModel?: ExchangeModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiExchangeUpdatePost(exchangeModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeApi - object-oriented interface
 * @export
 * @class ExchangeApi
 * @extends {BaseAPI}
 */
export class ExchangeApi extends BaseAPI {
    /**
     * 
     * @param {ExchangeContactModel} [exchangeContactModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeAddContactPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeAddContactPost(exchangeContactModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeClosedGet(options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeClosedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeModel} [exchangeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeCreatePost(exchangeModel?: ExchangeModel, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeCreatePost(exchangeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeDeleteIdGet(id: number, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeDeleteIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeForUserContactIdGet(contactId: number, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeForUserContactIdGet(contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeForUserGet(options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeForUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeGet(options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeContactModel} [exchangeContactModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeGetContactForTabPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeGetContactForTabPost(exchangeContactModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [exchangeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeGetContactsGet(exchangeId?: number, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeGetContactsGet(exchangeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeIdGet(id: number, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeContactModel} [exchangeContactModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeRemoveContactPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeRemoveContactPost(exchangeContactModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeContactModel} [exchangeContactModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeRepresentativesPost(exchangeContactModel?: ExchangeContactModel, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeRepresentativesPost(exchangeContactModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exchangeId 
     * @param {boolean} closed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeToggleStatusExchangeIdClosedGet(exchangeId: number, closed: boolean, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeToggleStatusExchangeIdClosedGet(exchangeId, closed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeModel} [exchangeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeApi
     */
    public apiExchangeUpdatePost(exchangeModel?: ExchangeModel, options?: AxiosRequestConfig) {
        return ExchangeApiFp(this.configuration).apiExchangeUpdatePost(exchangeModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExchangeAttachmentApi - axios parameter creator
 * @export
 */
export const ExchangeAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentDownloadAttachmentIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExchangeAttachmentDownloadAttachmentIdGet', 'id', id)
            const localVarPath = `/api/ExchangeAttachment/DownloadAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExchangeAttachmentIdGet', 'id', id)
            const localVarPath = `/api/ExchangeAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentMetaForExchangeIdFolderIdGet: async (id: number, folderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExchangeAttachmentMetaForExchangeIdFolderIdGet', 'id', id)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('apiExchangeAttachmentMetaForExchangeIdFolderIdGet', 'folderId', folderId)
            const localVarPath = `/api/ExchangeAttachment/MetaForExchange/{id}/{folderId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet: async (attachmentId: number, folderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet', 'attachmentId', attachmentId)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet', 'folderId', folderId)
            const localVarPath = `/api/ExchangeAttachment/MoveAttachment/{attachmentId}/{folderId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeAttachmentModel} [exchangeAttachmentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentPost: async (exchangeAttachmentModel?: ExchangeAttachmentModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExchangeAttachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeAttachmentModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentRemoveAttachmentIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExchangeAttachmentRemoveAttachmentIdGet', 'id', id)
            const localVarPath = `/api/ExchangeAttachment/RemoveAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeAttachmentApi - functional programming interface
 * @export
 */
export const ExchangeAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeAttachmentDownloadAttachmentIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeAttachmentDownloadAttachmentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeAttachmentIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeAttachmentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeAttachmentMetaForExchangeIdFolderIdGet(id: number, folderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExchangeAttachmentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeAttachmentMetaForExchangeIdFolderIdGet(id, folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet(attachmentId: number, folderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet(attachmentId, folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeAttachmentModel} [exchangeAttachmentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeAttachmentPost(exchangeAttachmentModel?: ExchangeAttachmentModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeAttachmentPost(exchangeAttachmentModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExchangeAttachmentRemoveAttachmentIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExchangeAttachmentRemoveAttachmentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExchangeAttachmentApi - factory interface
 * @export
 */
export const ExchangeAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeAttachmentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentDownloadAttachmentIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiExchangeAttachmentDownloadAttachmentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiExchangeAttachmentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentMetaForExchangeIdFolderIdGet(id: number, folderId: number, options?: any): AxiosPromise<Array<ExchangeAttachmentModel>> {
            return localVarFp.apiExchangeAttachmentMetaForExchangeIdFolderIdGet(id, folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attachmentId 
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet(attachmentId: number, folderId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet(attachmentId, folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeAttachmentModel} [exchangeAttachmentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentPost(exchangeAttachmentModel?: ExchangeAttachmentModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiExchangeAttachmentPost(exchangeAttachmentModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExchangeAttachmentRemoveAttachmentIdGet(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiExchangeAttachmentRemoveAttachmentIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeAttachmentApi - object-oriented interface
 * @export
 * @class ExchangeAttachmentApi
 * @extends {BaseAPI}
 */
export class ExchangeAttachmentApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeAttachmentApi
     */
    public apiExchangeAttachmentDownloadAttachmentIdGet(id: number, options?: AxiosRequestConfig) {
        return ExchangeAttachmentApiFp(this.configuration).apiExchangeAttachmentDownloadAttachmentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeAttachmentApi
     */
    public apiExchangeAttachmentIdGet(id: number, options?: AxiosRequestConfig) {
        return ExchangeAttachmentApiFp(this.configuration).apiExchangeAttachmentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeAttachmentApi
     */
    public apiExchangeAttachmentMetaForExchangeIdFolderIdGet(id: number, folderId: number, options?: AxiosRequestConfig) {
        return ExchangeAttachmentApiFp(this.configuration).apiExchangeAttachmentMetaForExchangeIdFolderIdGet(id, folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attachmentId 
     * @param {number} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeAttachmentApi
     */
    public apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet(attachmentId: number, folderId: number, options?: AxiosRequestConfig) {
        return ExchangeAttachmentApiFp(this.configuration).apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet(attachmentId, folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeAttachmentModel} [exchangeAttachmentModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeAttachmentApi
     */
    public apiExchangeAttachmentPost(exchangeAttachmentModel?: ExchangeAttachmentModel, options?: AxiosRequestConfig) {
        return ExchangeAttachmentApiFp(this.configuration).apiExchangeAttachmentPost(exchangeAttachmentModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeAttachmentApi
     */
    public apiExchangeAttachmentRemoveAttachmentIdGet(id: number, options?: AxiosRequestConfig) {
        return ExchangeAttachmentApiFp(this.configuration).apiExchangeAttachmentRemoveAttachmentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldMappingApi - axios parameter creator
 * @export
 */
export const FieldMappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FieldMappingModel} [fieldMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldMappingAddPost: async (fieldMappingModel?: FieldMappingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FieldMapping/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldMappingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldMappingDocumentIdGet: async (documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiFieldMappingDocumentIdGet', 'documentId', documentId)
            const localVarPath = `/api/FieldMapping/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fieldMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldMappingRemoveFieldMappingIdGet: async (fieldMappingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldMappingId' is not null or undefined
            assertParamExists('apiFieldMappingRemoveFieldMappingIdGet', 'fieldMappingId', fieldMappingId)
            const localVarPath = `/api/FieldMapping/Remove/{fieldMappingId}`
                .replace(`{${"fieldMappingId"}}`, encodeURIComponent(String(fieldMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldMappingModel} [fieldMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldMappingUpdatePost: async (fieldMappingModel?: FieldMappingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FieldMapping/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldMappingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldMappingApi - functional programming interface
 * @export
 */
export const FieldMappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldMappingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FieldMappingModel} [fieldMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFieldMappingAddPost(fieldMappingModel?: FieldMappingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFieldMappingAddPost(fieldMappingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFieldMappingDocumentIdGet(documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldMappingModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFieldMappingDocumentIdGet(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fieldMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFieldMappingRemoveFieldMappingIdGet(fieldMappingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFieldMappingRemoveFieldMappingIdGet(fieldMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FieldMappingModel} [fieldMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFieldMappingUpdatePost(fieldMappingModel?: FieldMappingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFieldMappingUpdatePost(fieldMappingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldMappingApi - factory interface
 * @export
 */
export const FieldMappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldMappingApiFp(configuration)
    return {
        /**
         * 
         * @param {FieldMappingModel} [fieldMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldMappingAddPost(fieldMappingModel?: FieldMappingModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiFieldMappingAddPost(fieldMappingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldMappingDocumentIdGet(documentId: number, options?: any): AxiosPromise<Array<FieldMappingModel>> {
            return localVarFp.apiFieldMappingDocumentIdGet(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fieldMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldMappingRemoveFieldMappingIdGet(fieldMappingId: number, options?: any): AxiosPromise<number> {
            return localVarFp.apiFieldMappingRemoveFieldMappingIdGet(fieldMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldMappingModel} [fieldMappingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldMappingUpdatePost(fieldMappingModel?: FieldMappingModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiFieldMappingUpdatePost(fieldMappingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldMappingApi - object-oriented interface
 * @export
 * @class FieldMappingApi
 * @extends {BaseAPI}
 */
export class FieldMappingApi extends BaseAPI {
    /**
     * 
     * @param {FieldMappingModel} [fieldMappingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldMappingApi
     */
    public apiFieldMappingAddPost(fieldMappingModel?: FieldMappingModel, options?: AxiosRequestConfig) {
        return FieldMappingApiFp(this.configuration).apiFieldMappingAddPost(fieldMappingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldMappingApi
     */
    public apiFieldMappingDocumentIdGet(documentId: number, options?: AxiosRequestConfig) {
        return FieldMappingApiFp(this.configuration).apiFieldMappingDocumentIdGet(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fieldMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldMappingApi
     */
    public apiFieldMappingRemoveFieldMappingIdGet(fieldMappingId: number, options?: AxiosRequestConfig) {
        return FieldMappingApiFp(this.configuration).apiFieldMappingRemoveFieldMappingIdGet(fieldMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldMappingModel} [fieldMappingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldMappingApi
     */
    public apiFieldMappingUpdatePost(fieldMappingModel?: FieldMappingModel, options?: AxiosRequestConfig) {
        return FieldMappingApiFp(this.configuration).apiFieldMappingUpdatePost(fieldMappingModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ListApi - axios parameter creator
 * @export
 */
export const ListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListExchangeContactTabsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/List/ExchangeContactTabs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListExchangeContactTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/List/ExchangeContactTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListExchangeTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/List/ExchangeTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListMappingObjectsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/List/MappingObjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListPropertyTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/List/PropertyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListApi - functional programming interface
 * @export
 */
export const ListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiListExchangeContactTabsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactTabModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiListExchangeContactTabsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiListExchangeContactTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiListExchangeContactTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiListExchangeTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExchangeTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiListExchangeTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiListMappingObjectsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MappingObjectModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiListMappingObjectsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiListPropertyTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiListPropertyTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListApi - factory interface
 * @export
 */
export const ListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListExchangeContactTabsGet(options?: any): AxiosPromise<Array<ContactTabModel>> {
            return localVarFp.apiListExchangeContactTabsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListExchangeContactTypesGet(options?: any): AxiosPromise<Array<ContactTypeModel>> {
            return localVarFp.apiListExchangeContactTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListExchangeTypesGet(options?: any): AxiosPromise<Array<ExchangeTypeModel>> {
            return localVarFp.apiListExchangeTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListMappingObjectsGet(options?: any): AxiosPromise<Array<MappingObjectModel>> {
            return localVarFp.apiListMappingObjectsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiListPropertyTypesGet(options?: any): AxiosPromise<Array<PropertyTypeModel>> {
            return localVarFp.apiListPropertyTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListApi - object-oriented interface
 * @export
 * @class ListApi
 * @extends {BaseAPI}
 */
export class ListApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    public apiListExchangeContactTabsGet(options?: AxiosRequestConfig) {
        return ListApiFp(this.configuration).apiListExchangeContactTabsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    public apiListExchangeContactTypesGet(options?: AxiosRequestConfig) {
        return ListApiFp(this.configuration).apiListExchangeContactTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    public apiListExchangeTypesGet(options?: AxiosRequestConfig) {
        return ListApiFp(this.configuration).apiListExchangeTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    public apiListMappingObjectsGet(options?: AxiosRequestConfig) {
        return ListApiFp(this.configuration).apiListMappingObjectsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListApi
     */
    public apiListPropertyTypesGet(options?: AxiosRequestConfig) {
        return ListApiFp(this.configuration).apiListPropertyTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MappingOptionApi - axios parameter creator
 * @export
 */
export const MappingOptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [mappingOptionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMappingOptionDeleteGet: async (mappingOptionId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MappingOption/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mappingOptionId !== undefined) {
                localVarQueryParameter['mappingOptionId'] = mappingOptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMappingOptionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MappingOption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MappingOptionModel} [mappingOptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMappingOptionPost: async (mappingOptionModel?: MappingOptionModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MappingOption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mappingOptionModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MappingOptionModel} [mappingOptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMappingOptionUpdatePost: async (mappingOptionModel?: MappingOptionModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MappingOption/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mappingOptionModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MappingOptionApi - functional programming interface
 * @export
 */
export const MappingOptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MappingOptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [mappingOptionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMappingOptionDeleteGet(mappingOptionId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMappingOptionDeleteGet(mappingOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMappingOptionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MappingOptionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMappingOptionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MappingOptionModel} [mappingOptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMappingOptionPost(mappingOptionModel?: MappingOptionModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMappingOptionPost(mappingOptionModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MappingOptionModel} [mappingOptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMappingOptionUpdatePost(mappingOptionModel?: MappingOptionModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMappingOptionUpdatePost(mappingOptionModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MappingOptionApi - factory interface
 * @export
 */
export const MappingOptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MappingOptionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [mappingOptionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMappingOptionDeleteGet(mappingOptionId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiMappingOptionDeleteGet(mappingOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMappingOptionGet(options?: any): AxiosPromise<Array<MappingOptionModel>> {
            return localVarFp.apiMappingOptionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MappingOptionModel} [mappingOptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMappingOptionPost(mappingOptionModel?: MappingOptionModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiMappingOptionPost(mappingOptionModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MappingOptionModel} [mappingOptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMappingOptionUpdatePost(mappingOptionModel?: MappingOptionModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiMappingOptionUpdatePost(mappingOptionModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MappingOptionApi - object-oriented interface
 * @export
 * @class MappingOptionApi
 * @extends {BaseAPI}
 */
export class MappingOptionApi extends BaseAPI {
    /**
     * 
     * @param {number} [mappingOptionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingOptionApi
     */
    public apiMappingOptionDeleteGet(mappingOptionId?: number, options?: AxiosRequestConfig) {
        return MappingOptionApiFp(this.configuration).apiMappingOptionDeleteGet(mappingOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingOptionApi
     */
    public apiMappingOptionGet(options?: AxiosRequestConfig) {
        return MappingOptionApiFp(this.configuration).apiMappingOptionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MappingOptionModel} [mappingOptionModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingOptionApi
     */
    public apiMappingOptionPost(mappingOptionModel?: MappingOptionModel, options?: AxiosRequestConfig) {
        return MappingOptionApiFp(this.configuration).apiMappingOptionPost(mappingOptionModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MappingOptionModel} [mappingOptionModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingOptionApi
     */
    public apiMappingOptionUpdatePost(mappingOptionModel?: MappingOptionModel, options?: AxiosRequestConfig) {
        return MappingOptionApiFp(this.configuration).apiMappingOptionUpdatePost(mappingOptionModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageArchiveTopicIdGet: async (topicId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('apiMessageArchiveTopicIdGet', 'topicId', topicId)
            const localVarPath = `/api/Message/Archive/{topicId}`
                .replace(`{${"topicId"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageDeleteTopicTopicIdGet: async (topicId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('apiMessageDeleteTopicTopicIdGet', 'topicId', topicId)
            const localVarPath = `/api/Message/DeleteTopic/{topicId}`
                .replace(`{${"topicId"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageMessagesTopicIdGet: async (topicId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('apiMessageMessagesTopicIdGet', 'topicId', topicId)
            const localVarPath = `/api/Message/Messages/{topicId}`
                .replace(`{${"topicId"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageRemoveGet: async (messageId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Message/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageSendPost: async (messageModel?: MessageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Message/Send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} showArchived 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageTopicsShowArchivedGet: async (showArchived: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'showArchived' is not null or undefined
            assertParamExists('apiMessageTopicsShowArchivedGet', 'showArchived', showArchived)
            const localVarPath = `/api/Message/Topics/{showArchived}`
                .replace(`{${"showArchived"}}`, encodeURIComponent(String(showArchived)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageUnArchiveTopicIdGet: async (topicId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('apiMessageUnArchiveTopicIdGet', 'topicId', topicId)
            const localVarPath = `/api/Message/UnArchive/{topicId}`
                .replace(`{${"topicId"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageUpdatePost: async (messageModel?: MessageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Message/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageArchiveTopicIdGet(topicId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageArchiveTopicIdGet(topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageDeleteTopicTopicIdGet(topicId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageDeleteTopicTopicIdGet(topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageMessagesTopicIdGet(topicId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageMessagesTopicIdGet(topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageRemoveGet(messageId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageRemoveGet(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageSendPost(messageModel?: MessageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageSendPost(messageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} showArchived 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageTopicsShowArchivedGet(showArchived: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageTopicModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageTopicsShowArchivedGet(showArchived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageUnArchiveTopicIdGet(topicId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageUnArchiveTopicIdGet(topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageUpdatePost(messageModel?: MessageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageUpdatePost(messageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageArchiveTopicIdGet(topicId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiMessageArchiveTopicIdGet(topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageDeleteTopicTopicIdGet(topicId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiMessageDeleteTopicTopicIdGet(topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageMessagesTopicIdGet(topicId: number, options?: any): AxiosPromise<Array<MessageModel>> {
            return localVarFp.apiMessageMessagesTopicIdGet(topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageRemoveGet(messageId?: number, options?: any): AxiosPromise<number> {
            return localVarFp.apiMessageRemoveGet(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageSendPost(messageModel?: MessageModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiMessageSendPost(messageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} showArchived 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageTopicsShowArchivedGet(showArchived: boolean, options?: any): AxiosPromise<Array<MessageTopicModel>> {
            return localVarFp.apiMessageTopicsShowArchivedGet(showArchived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageUnArchiveTopicIdGet(topicId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiMessageUnArchiveTopicIdGet(topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageUpdatePost(messageModel?: MessageModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiMessageUpdatePost(messageModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @param {number} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageArchiveTopicIdGet(topicId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageArchiveTopicIdGet(topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageDeleteTopicTopicIdGet(topicId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageDeleteTopicTopicIdGet(topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageMessagesTopicIdGet(topicId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageMessagesTopicIdGet(topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [messageId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageRemoveGet(messageId?: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageRemoveGet(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessageModel} [messageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageSendPost(messageModel?: MessageModel, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageSendPost(messageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} showArchived 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageTopicsShowArchivedGet(showArchived: boolean, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageTopicsShowArchivedGet(showArchived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageUnArchiveTopicIdGet(topicId: number, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageUnArchiveTopicIdGet(topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessageModel} [messageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageUpdatePost(messageModel?: MessageModel, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageUpdatePost(messageModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderRegistrationApi - axios parameter creator
 * @export
 */
export const OrderRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationAddPost: async (orderRegistration?: OrderRegistration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderRegistration/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationConvertPost: async (orderRegistration?: OrderRegistration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderRegistration/Convert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationOrderIdGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('apiOrderRegistrationOrderIdGet', 'orderId', orderId)
            const localVarPath = `/api/OrderRegistration/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationUpdatePost: async (orderRegistration?: OrderRegistration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderRegistration/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderRegistrationApi - functional programming interface
 * @export
 */
export const OrderRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRegistrationAddPost(orderRegistration?: OrderRegistration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRegistrationAddPost(orderRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRegistrationConvertPost(orderRegistration?: OrderRegistration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRegistrationConvertPost(orderRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRegistrationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderRegistration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRegistrationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRegistrationOrderIdGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRegistrationOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRegistrationUpdatePost(orderRegistration?: OrderRegistration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRegistrationUpdatePost(orderRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderRegistrationApi - factory interface
 * @export
 */
export const OrderRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationAddPost(orderRegistration?: OrderRegistration, options?: any): AxiosPromise<number> {
            return localVarFp.apiOrderRegistrationAddPost(orderRegistration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationConvertPost(orderRegistration?: OrderRegistration, options?: any): AxiosPromise<number> {
            return localVarFp.apiOrderRegistrationConvertPost(orderRegistration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationGet(options?: any): AxiosPromise<Array<OrderRegistration>> {
            return localVarFp.apiOrderRegistrationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationOrderIdGet(orderId: number, options?: any): AxiosPromise<OrderRegistration> {
            return localVarFp.apiOrderRegistrationOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderRegistration} [orderRegistration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRegistrationUpdatePost(orderRegistration?: OrderRegistration, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiOrderRegistrationUpdatePost(orderRegistration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderRegistrationApi - object-oriented interface
 * @export
 * @class OrderRegistrationApi
 * @extends {BaseAPI}
 */
export class OrderRegistrationApi extends BaseAPI {
    /**
     * 
     * @param {OrderRegistration} [orderRegistration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRegistrationApi
     */
    public apiOrderRegistrationAddPost(orderRegistration?: OrderRegistration, options?: AxiosRequestConfig) {
        return OrderRegistrationApiFp(this.configuration).apiOrderRegistrationAddPost(orderRegistration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderRegistration} [orderRegistration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRegistrationApi
     */
    public apiOrderRegistrationConvertPost(orderRegistration?: OrderRegistration, options?: AxiosRequestConfig) {
        return OrderRegistrationApiFp(this.configuration).apiOrderRegistrationConvertPost(orderRegistration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRegistrationApi
     */
    public apiOrderRegistrationGet(options?: AxiosRequestConfig) {
        return OrderRegistrationApiFp(this.configuration).apiOrderRegistrationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRegistrationApi
     */
    public apiOrderRegistrationOrderIdGet(orderId: number, options?: AxiosRequestConfig) {
        return OrderRegistrationApiFp(this.configuration).apiOrderRegistrationOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderRegistration} [orderRegistration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRegistrationApi
     */
    public apiOrderRegistrationUpdatePost(orderRegistration?: OrderRegistration, options?: AxiosRequestConfig) {
        return OrderRegistrationApiFp(this.configuration).apiOrderRegistrationUpdatePost(orderRegistration, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertyAddressApi - axios parameter creator
 * @export
 */
export const PropertyAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [contactId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressDelete: async (contactId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PropertyAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contactId !== undefined) {
                localVarQueryParameter['contactId'] = contactId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressExchangeExchangeIdGet: async (exchangeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeId' is not null or undefined
            assertParamExists('apiPropertyAddressExchangeExchangeIdGet', 'exchangeId', exchangeId)
            const localVarPath = `/api/PropertyAddress/Exchange/{exchangeId}`
                .replace(`{${"exchangeId"}}`, encodeURIComponent(String(exchangeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {number} typeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressExchangeExchangeIdTypeIdGet: async (exchangeId: number, typeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeId' is not null or undefined
            assertParamExists('apiPropertyAddressExchangeExchangeIdTypeIdGet', 'exchangeId', exchangeId)
            // verify required parameter 'typeId' is not null or undefined
            assertParamExists('apiPropertyAddressExchangeExchangeIdTypeIdGet', 'typeId', typeId)
            const localVarPath = `/api/PropertyAddress/Exchange/{exchangeId}/{typeId}`
                .replace(`{${"exchangeId"}}`, encodeURIComponent(String(exchangeId)))
                .replace(`{${"typeId"}}`, encodeURIComponent(String(typeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PropertyAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPropertyAddressIdGet', 'id', id)
            const localVarPath = `/api/PropertyAddress/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PropertyAddressModel} [propertyAddressModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressPost: async (propertyAddressModel?: PropertyAddressModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PropertyAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyAddressModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PropertyAddressModel} [propertyAddressModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressPut: async (propertyAddressModel?: PropertyAddressModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PropertyAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyAddressModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyAddressApi - functional programming interface
 * @export
 */
export const PropertyAddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyAddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [contactId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPropertyAddressDelete(contactId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPropertyAddressDelete(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPropertyAddressExchangeExchangeIdGet(exchangeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyAddressModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPropertyAddressExchangeExchangeIdGet(exchangeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {number} typeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPropertyAddressExchangeExchangeIdTypeIdGet(exchangeId: number, typeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyAddressModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPropertyAddressExchangeExchangeIdTypeIdGet(exchangeId, typeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPropertyAddressGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyAddressModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPropertyAddressGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPropertyAddressIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyAddressModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPropertyAddressIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PropertyAddressModel} [propertyAddressModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPropertyAddressPost(propertyAddressModel?: PropertyAddressModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPropertyAddressPost(propertyAddressModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PropertyAddressModel} [propertyAddressModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPropertyAddressPut(propertyAddressModel?: PropertyAddressModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPropertyAddressPut(propertyAddressModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyAddressApi - factory interface
 * @export
 */
export const PropertyAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyAddressApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [contactId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressDelete(contactId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiPropertyAddressDelete(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressExchangeExchangeIdGet(exchangeId: number, options?: any): AxiosPromise<Array<PropertyAddressModel>> {
            return localVarFp.apiPropertyAddressExchangeExchangeIdGet(exchangeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exchangeId 
         * @param {number} typeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressExchangeExchangeIdTypeIdGet(exchangeId: number, typeId: number, options?: any): AxiosPromise<Array<PropertyAddressModel>> {
            return localVarFp.apiPropertyAddressExchangeExchangeIdTypeIdGet(exchangeId, typeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressGet(options?: any): AxiosPromise<Array<PropertyAddressModel>> {
            return localVarFp.apiPropertyAddressGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressIdGet(id: number, options?: any): AxiosPromise<PropertyAddressModel> {
            return localVarFp.apiPropertyAddressIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PropertyAddressModel} [propertyAddressModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressPost(propertyAddressModel?: PropertyAddressModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiPropertyAddressPost(propertyAddressModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PropertyAddressModel} [propertyAddressModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPropertyAddressPut(propertyAddressModel?: PropertyAddressModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiPropertyAddressPut(propertyAddressModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyAddressApi - object-oriented interface
 * @export
 * @class PropertyAddressApi
 * @extends {BaseAPI}
 */
export class PropertyAddressApi extends BaseAPI {
    /**
     * 
     * @param {number} [contactId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyAddressApi
     */
    public apiPropertyAddressDelete(contactId?: number, options?: AxiosRequestConfig) {
        return PropertyAddressApiFp(this.configuration).apiPropertyAddressDelete(contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exchangeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyAddressApi
     */
    public apiPropertyAddressExchangeExchangeIdGet(exchangeId: number, options?: AxiosRequestConfig) {
        return PropertyAddressApiFp(this.configuration).apiPropertyAddressExchangeExchangeIdGet(exchangeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exchangeId 
     * @param {number} typeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyAddressApi
     */
    public apiPropertyAddressExchangeExchangeIdTypeIdGet(exchangeId: number, typeId: number, options?: AxiosRequestConfig) {
        return PropertyAddressApiFp(this.configuration).apiPropertyAddressExchangeExchangeIdTypeIdGet(exchangeId, typeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyAddressApi
     */
    public apiPropertyAddressGet(options?: AxiosRequestConfig) {
        return PropertyAddressApiFp(this.configuration).apiPropertyAddressGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyAddressApi
     */
    public apiPropertyAddressIdGet(id: number, options?: AxiosRequestConfig) {
        return PropertyAddressApiFp(this.configuration).apiPropertyAddressIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PropertyAddressModel} [propertyAddressModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyAddressApi
     */
    public apiPropertyAddressPost(propertyAddressModel?: PropertyAddressModel, options?: AxiosRequestConfig) {
        return PropertyAddressApiFp(this.configuration).apiPropertyAddressPost(propertyAddressModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PropertyAddressModel} [propertyAddressModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyAddressApi
     */
    public apiPropertyAddressPut(propertyAddressModel?: PropertyAddressModel, options?: AxiosRequestConfig) {
        return PropertyAddressApiFp(this.configuration).apiPropertyAddressPut(propertyAddressModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAssignToRoleRoleIdUserIdGet: async (roleId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiUserAssignToRoleRoleIdUserIdGet', 'roleId', roleId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserAssignToRoleRoleIdUserIdGet', 'userId', userId)
            const localVarPath = `/api/User/AssignToRole/{roleId}/{userId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAssignedRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/AssignedRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppsparcUser} [appsparcUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreatePost: async (appsparcUser?: AppsparcUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appsparcUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDeleteGet: async (userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRemoveFromApplicationGet: async (userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/RemoveFromApplication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRemoveRoleUserIdRoleIdGet: async (userId: string, roleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserRemoveRoleUserIdRoleIdGet', 'userId', userId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiUserRemoveRoleUserIdRoleIdGet', 'roleId', roleId)
            const localVarPath = `/api/User/RemoveRole/{userId}/{roleId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRolesForUserUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserRolesForUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/User/RolesForUser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppsparcUser} [appsparcUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdatePost: async (appsparcUser?: AppsparcUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appsparcUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAssignToRoleRoleIdUserIdGet(roleId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAssignToRoleRoleIdUserIdGet(roleId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAssignedRolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAssignedRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppsparcUser} [appsparcUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreatePost(appsparcUser?: AppsparcUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsparcUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreatePost(appsparcUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDeleteGet(userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDeleteGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppsparcUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRemoveFromApplicationGet(userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRemoveFromApplicationGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRemoveRoleUserIdRoleIdGet(userId: string, roleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRemoveRoleUserIdRoleIdGet(userId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRolesForUserUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppsparcRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRolesForUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppsparcRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppsparcUser} [appsparcUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdatePost(appsparcUser?: AppsparcUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsparcUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdatePost(appsparcUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAssignToRoleRoleIdUserIdGet(roleId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAssignToRoleRoleIdUserIdGet(roleId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAssignedRolesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiUserAssignedRolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppsparcUser} [appsparcUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreatePost(appsparcUser?: AppsparcUser, options?: any): AxiosPromise<AppsparcUser> {
            return localVarFp.apiUserCreatePost(appsparcUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDeleteGet(userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserDeleteGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<AppsparcUser>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRemoveFromApplicationGet(userId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserRemoveFromApplicationGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRemoveRoleUserIdRoleIdGet(userId: string, roleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserRemoveRoleUserIdRoleIdGet(userId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRolesForUserUserIdGet(userId: string, options?: any): AxiosPromise<Array<AppsparcRole>> {
            return localVarFp.apiUserRolesForUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRolesGet(options?: any): AxiosPromise<Array<AppsparcRole>> {
            return localVarFp.apiUserRolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppsparcUser} [appsparcUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdatePost(appsparcUser?: AppsparcUser, options?: any): AxiosPromise<AppsparcUser> {
            return localVarFp.apiUserUpdatePost(appsparcUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} roleId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAssignToRoleRoleIdUserIdGet(roleId: string, userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAssignToRoleRoleIdUserIdGet(roleId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAssignedRolesGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAssignedRolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppsparcUser} [appsparcUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreatePost(appsparcUser?: AppsparcUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreatePost(appsparcUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDeleteGet(userId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserDeleteGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRemoveFromApplicationGet(userId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRemoveFromApplicationGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRemoveRoleUserIdRoleIdGet(userId: string, roleId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRemoveRoleUserIdRoleIdGet(userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRolesForUserUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRolesForUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRolesGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppsparcUser} [appsparcUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdatePost(appsparcUser?: AppsparcUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUpdatePost(appsparcUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserSettingApi - axios parameter creator
 * @export
 */
export const UserSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSettingModel} [userSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingPost: async (userSettingModel?: UserSettingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSettingModel} [userSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingUpdatePost: async (userSettingModel?: UserSettingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserSetting/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSettingApi - functional programming interface
 * @export
 */
export const UserSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSettingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserSettingModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSettingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSettingModel} [userSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSettingPost(userSettingModel?: UserSettingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSettingPost(userSettingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSettingModel} [userSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSettingUpdatePost(userSettingModel?: UserSettingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSettingUpdatePost(userSettingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSettingApi - factory interface
 * @export
 */
export const UserSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSettingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingGet(options?: any): AxiosPromise<Array<UserSettingModel>> {
            return localVarFp.apiUserSettingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSettingModel} [userSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingPost(userSettingModel?: UserSettingModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiUserSettingPost(userSettingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSettingModel} [userSettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingUpdatePost(userSettingModel?: UserSettingModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserSettingUpdatePost(userSettingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSettingApi - object-oriented interface
 * @export
 * @class UserSettingApi
 * @extends {BaseAPI}
 */
export class UserSettingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingApi
     */
    public apiUserSettingGet(options?: AxiosRequestConfig) {
        return UserSettingApiFp(this.configuration).apiUserSettingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserSettingModel} [userSettingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingApi
     */
    public apiUserSettingPost(userSettingModel?: UserSettingModel, options?: AxiosRequestConfig) {
        return UserSettingApiFp(this.configuration).apiUserSettingPost(userSettingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserSettingModel} [userSettingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingApi
     */
    public apiUserSettingUpdatePost(userSettingModel?: UserSettingModel, options?: AxiosRequestConfig) {
        return UserSettingApiFp(this.configuration).apiUserSettingUpdatePost(userSettingModel, options).then((request) => request(this.axios, this.basePath));
    }
}


