import React from "react";
import {useDropzone} from "react-dropzone";
import {useDispatch} from "react-redux";
import {updateDocumentMappingField} from "../../actions/document-mappings";

export default function AttachedDocument() {

    const dispatch = useDispatch();


    const filesDropped = async function (files) {
        const f = files[0];
        dispatch(updateDocumentMappingField('fileName', f.name));
        const base64 = await convertBase64(f);
        dispatch(updateDocumentMappingField('fileData', window.btoa(base64)));
    }

    const {getRootProps, getInputProps, isDragAccept, isDragReject} = useDropzone({
        onDrop: filesDropped,
        accept: {'application/pdf' : ['.pdf'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-word.document.macroEnabled.12': ['.docm']}
    });

    const convertBase64 = file => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsBinaryString(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    const getActiveClass = function () {
        if (isDragAccept) {
            return {className: 'dropzone border-success p-2 my-1'}
        } else if (isDragReject) {
            return {className: 'dropzone border-danger p-2 my-1'}
        } else {
            return {className: 'dropzone p-2 my-1'}
        }

    }

    const renderDropMessage = function () {
        if (isDragAccept) {
            return <div className="text-success fw-bold text-center">Drop PDF, DOCX, or DOCM file here or click to select</div>
        } else if (isDragReject) {
            return <div className="text-danger fw-bold text-center">Only PDF, DOCX, or DOCM files can be attached</div>
        } else {
            return <div className="text-center">Drop PDF, DOCX, or DOCM file here or click to select</div>
        }

    }

    return (<div className="row m-0 p-0 justify-content-between">
        <div className="col-12 m-0 p-0">
            <div {...getRootProps(getActiveClass())}>
                <input {...getInputProps()} />
                {renderDropMessage()}
            </div>
        </div>
    </div>);
}