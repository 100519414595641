import React, {useEffect} from "react";
import {
    faPaperPlane, faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {
    loadTopics,
    resetMessageScreen,
    selectMessageTopic,
    sendMessage,
    updateMessageField
} from "../../actions/message";
import Topics from "./topics";
import {toggleLoadingMessages, toggleLoadingTopics} from "../../actions/ui";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";
import {loadUsers} from "../../actions/user-admin";
import Select from "react-select";
import {CONTACT_ROLE} from "../../constants/settings";

export const MessagePortal = function () {

    const dispatch = useDispatch();

    const activeMessage = useSelector(state => state.message.activeMessage);
    const messageHistory = useSelector(state => state.message.messageHistory);
    const selectedTopic = useSelector(state => state.message.selectedTopic);
    const showArchivedTopics = useSelector(state => state.message.showArchived);
    const loadingMessages = useSelector(state => state.ui.loadingMessages);
    const users = useSelector(state => state.userAdmin.users);
    const options = users.map(u => ({value: u.userId, label: u.fullname}));
    const {user} = useAuth0();

    const roles = useSelector(state => state.session.roles);
    const isReadOnly = roles.indexOf(CONTACT_ROLE) >= 0;

    useEffect(() => {
        dispatch(resetMessageScreen());
        dispatch(loadUsers());
    }, [dispatch]);

    const send = async function () {
        dispatch(toggleLoadingMessages());
        dispatch(toggleLoadingTopics())
        await dispatch(sendMessage(activeMessage, user));
        await dispatch(loadTopics(showArchivedTopics));
        await dispatch(selectMessageTopic(activeMessage));
        dispatch(toggleLoadingTopics());
        dispatch(toggleLoadingMessages());
    }

    const updateMessage = async function (e) {
        if(e.target === undefined){
            await dispatch(updateMessageField("to", e.value));
        } else {
            await dispatch(updateMessageField(e.target.name, e.target.value));
        }
    }

    const renderMessage = function (message, idx) {
        let dt = new Date(message.sent);
        dt = new Date(dt.toLocaleDateString() + " " + dt.toLocaleTimeString() + " UTC");
        const dateString = dt.toLocaleDateString() + " " + dt.toLocaleTimeString();
        if(message.from === user.sub){
            return (<div className="row p-0 m-1 justify-content-end" key={idx}>
                <div className="card p-2 col-8"><div className="fst-italic small">{dateString} - Me</div>{message.content}</div>
            </div>);
        } else {
            return (<div className="row p-0 m-1 justify-content-start" key={idx}>
                <div className="card p-2 col-8 bg-success bg-opacity-25"><div className="fst-italic small">{dateString} - {message.fromName}</div>{message.content}</div>
            </div>);
        }
    }

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="row m-0 p-0 mb-1">
            <div className="card col-12 float-end border-1 border-dark blue-body"><h4>Secure Messaging</h4></div>
        </div>
        <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
            <div className="row m-0 p-0">
                <div className="col-3 p-0 pt-3 pt-2 menu-column" style={{minHeight: "65vh"}}>
                    <Topics/>
                </div>
                <div className="col-9 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                    <div className={isReadOnly && !selectedTopic.topic ? "display-none" : ""}>
                        <div className="row p-0 m-0">
                            <div className="col-1 m-0 p-0 pt-3 text-end">
                                <label>To</label>
                            </div>
                            <div className="col-11 m-0 p-2">
                                {selectedTopic.topic ? <div className={"pt-2 ps-2"}>{activeMessage.to === user.sub ? activeMessage.fromName : activeMessage.toName}</div> : <Select name={"to"} options={options} disabled={selectedTopic.topic} placeholder="To:" defaultValue={activeMessage.to} onChange={updateMessage}/>}
                            </div>
                        </div>
                        <div className="row p-0 m-0">
                            <div className="col-1 m-0 p-0 pt-3 text-end">
                                <label>Subject</label>
                            </div>
                            <div className="col-11 m-0 p-2">
                                <input className="form-control" disabled={selectedTopic.topic} name="topic" placeholder="Subject:" value={activeMessage.topic} onChange={updateMessage}/>
                            </div>
                        </div>
                        <div className="row p-0 m-0 justify-content-end">
                            <div className="col-1 m-0 p-0 pt-3 text-end">
                                <label>Message</label>
                            </div>
                            <div className="col-9 m-0 p-2">
                                <textarea className="form-control" name="content" rows="5" placeholder="Message" value={activeMessage.content} onChange={updateMessage}/>
                            </div>
                            <div className="col-2 m-0 p-2">
                                <button className="btn btn-outline-dark" title="Send Message" onClick={send}><FontAwesomeIcon icon={faPaperPlane} />&nbsp;Send</button>
                            </div>
                        </div>
                        <div className="row p-0 m-0">
                            <div className="col-1 m-0 p-0 pt-3 text-end">
                                <label>{selectedTopic.topic ? "History" : ""}</label>
                            </div>
                            <div className="col-11 m-0 p-2">
                                <div className="card border-0 bg-white bg-opacity-25">
                                    {loadingMessages ? <div className={"text-center p-2"}><FontAwesomeIcon size="1x" icon={faSpinner} className={"fa-spin"} /> Loading Message History</div> : messageHistory.map((m, idx) => renderMessage(m, idx))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(MessagePortal, {
    onRedirecting: () => <Loading />,
});