import {
    CLEAR_SESSION,
    INSERT_SETTING,
    LOAD_SETTINGS,
    LOG_OUT,
    SET_TOKEN, SET_USER_ROLES,
    UPDATE_SETTING
} from '../actions/action-types';
import {OptOutEmailSetting} from "../constants/settings";

const initialState = {
    token: "",
    settings: [],
    roles: []
}

const session = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case SET_TOKEN:
            newState = Object.assign({}, state, state);
            newState.token = action.payload
            return newState;

        case CLEAR_SESSION:
            newState = Object.assign({}, initialState);
            return newState;

        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case LOAD_SETTINGS:
            newState = Object.assign({}, state);
            newState.settings = action.payload;
            newState[OptOutEmailSetting] = action.payload.find(s => s.setting === OptOutEmailSetting).value === "true";
            return newState;

        case INSERT_SETTING:
        case UPDATE_SETTING:
            newState = Object.assign({}, state);
            const settings = state.settings.filter(s => s.setting !== action.payload.setting);
            settings.push(action.payload);
            newState.settings = settings;
            newState[OptOutEmailSetting] = settings.find(s => s.setting === OptOutEmailSetting).value === "true";
            return newState;

        case SET_USER_ROLES:
            newState = Object.assign({}, state);
            newState.roles = action.payload;
            return newState;

        default:
            return state
    }
}

export default session