import {
    CLEAR_USER_FORM,
    LOAD_ROLES, LOAD_SELECTED_USER_ROLE,
    LOAD_USERS,
    LOG_OUT, REMOVE_USER, SELECT_USER, TOGGLE_USER_MODAL, UPDATE_CURRENT_USER_FIELD
} from '../actions/action-types';

const initialState = {
    users: [],
    selectedUser: {fullname: "", email: "", roleId: "", userId:"", password:""},
    roles: [],
    showModal: false
}

const userAdmin = (state = initialState, action) => {
    let newState, selectedUser;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case LOAD_USERS:
            newState = Object.assign({}, state);
            newState.users = action.payload;
            return newState;

        case SELECT_USER:
            newState = Object.assign({}, state);
            newState.selectedUser = action.payload;
            return newState;

        case LOAD_ROLES:
            newState = Object.assign({}, state);
            newState.roles = action.payload;
            return newState;

        case LOAD_SELECTED_USER_ROLE:
            newState = Object.assign({}, state);
            selectedUser = Object.assign({}, state.selectedUser);
            selectedUser.roleId = action.payload.id;
            newState.selectedUser = selectedUser;
            return newState;

        case CLEAR_USER_FORM:
            newState = Object.assign({}, state);
            newState.selectedUser = Object.assign({}, initialState.selectedUser);
            return newState;

        case UPDATE_CURRENT_USER_FIELD:
            newState = Object.assign({}, state);
            selectedUser = Object.assign({}, state.selectedUser);
            selectedUser[action.payload.field] = action.payload.value;
            newState.selectedUser = selectedUser;
            return newState;

        case TOGGLE_USER_MODAL:
            newState = Object.assign({}, state);
            newState.showModal = action.payload;
            return newState;

        case REMOVE_USER:
            newState = Object.assign({}, state);
            return newState;

        default:
            return state
    }
}

export default userAdmin