import toast from "react-hot-toast";
import {ApplicationSettingApi} from "../api";
import {baseUrl} from "../constants/settings";
import {GET_BANK, GET_QI, UPDATE_BANK_FIELD, UPDATE_QI_FIELD, UPSERT_BANK, UPSERT_QI} from "./action-types";

const settingApi = new ApplicationSettingApi(undefined, baseUrl);

export const loadBank = function() {
    return async dispatch => {
        try
        {
            const response = await settingApi.apiApplicationSettingBankGet();
            dispatch({ type: GET_BANK, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadQI = function() {
    return async dispatch => {
        try
        {
            const response = await settingApi.apiApplicationSettingQIGet();
            dispatch({ type: GET_QI, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const upsertBank = function(bankInfo) {
    return async dispatch => {
        try
        {
            await settingApi.apiApplicationSettingBankPost(bankInfo);
            toast.success("Bank Saved Successfully");
            dispatch({ type: UPSERT_BANK, payload: bankInfo });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const upsertQI = function(qiInfo) {
    return async dispatch => {
        try
        {
            await settingApi.apiApplicationSettingQIPost(qiInfo);
            toast.success("Qualified Intermediary Saved Successfully");
            dispatch({ type: UPSERT_QI, payload: qiInfo });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const updateBankField = function(field, value) {
    return async dispatch => {
        dispatch({ type: UPDATE_BANK_FIELD, payload: {field, value} });
    }
}

export const updateQIField = function(field, value) {
    return async dispatch => {
        dispatch({ type: UPDATE_QI_FIELD, payload: {field, value} });
    }
}
