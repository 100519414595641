import {baseUrl} from "../constants/settings";
import {
    ARCHIVE_TOPIC, DELETE_TOPIC,
    LOAD_TOPICS,
    RESET_MESSAGE_SCREEN,
    SELECT_MESSAGE_TOPIC,
    SEND_MESSAGE, START_NEW_TOPIC, TOGGLE_ARCHIVED_TOPICS, UNARCHIVE_TOPIC,
    UPDATE_MESSAGE_FIELD
} from "./action-types";
import toast from "react-hot-toast";
import {MessageApi} from "../api";

const messageApi = new MessageApi(undefined, baseUrl);


export const sendMessage = function(message, user) {
    return async dispatch => {
        try {
            if(user.sub === message.to){
                message.to = message.from;
                message.toEmail = message.fromEmail;
                message.toName = message.fromName;
                message.from = user.sub;
                message.fromEmail = user.email;
                message.fromName = user.name;
            }
            const response = await messageApi.apiMessageSendPost(message);
            message.id = response.data;
            dispatch({ type: SEND_MESSAGE, payload: message });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const updateMessageField = function(name, value) {
    return dispatch => {
       dispatch({ type: UPDATE_MESSAGE_FIELD, payload: {name: name, value: value} });
    }
}

export const loadTopics = function(includeArchived) {
    return async dispatch => {
        try {
            const response = await messageApi.apiMessageTopicsShowArchivedGet(includeArchived);
            dispatch({ type: LOAD_TOPICS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const selectMessageTopic = function(topic) {
    return async dispatch => {
        try {
            const response = await messageApi.apiMessageMessagesTopicIdGet(topic.id);
            dispatch({ type: SELECT_MESSAGE_TOPIC, payload: {data: response.data, topic} });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const archiveTopic = function(topic) {
    return async dispatch => {
        try {
            // eslint-disable-next-line
            const response = await messageApi.apiMessageArchiveTopicIdGet(topic.id);
            dispatch({ type: ARCHIVE_TOPIC, payload: topic });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const unArchiveTopic = function(topic) {
    return async dispatch => {
        try {
            // eslint-disable-next-line
            const response = await messageApi.apiMessageUnArchiveTopicIdGet(topic.id);
            dispatch({ type: UNARCHIVE_TOPIC, payload: topic });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const deleteMessageTopic = function(topic) {
    return async dispatch => {
        try {
            // eslint-disable-next-line
            const response = await messageApi.apiMessageRemoveGet(topic.id);
            dispatch({ type: DELETE_TOPIC, payload: topic });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const resetMessageScreen = function () {
    return dispatch => {
        dispatch({type: RESET_MESSAGE_SCREEN});
    }
}

export const startNewTopic = function () {
    return dispatch => {
        dispatch({type: START_NEW_TOPIC});
    }
}

export const toggleArchivedTopics = function (archived) {
    return dispatch => {
        dispatch({type: TOGGLE_ARCHIVED_TOPICS, payload: archived});
    }
}