import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createExchange, loadExchangeTypes, updateExchangeProperty} from "../../actions/exchange";
import {clearExchange} from "../../actions/ui";
import {useNavigate} from "react-router-dom";

const NewOrder = function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const exchangeData = useSelector(state => state.exchange.currentExchange);
    const exchangeTypes = useSelector(state => state.exchange.exchangeTypes);

    useEffect(() => {
        dispatch(clearExchange());
        dispatch(loadExchangeTypes());
    }, [dispatch]);

    const handleValueChange = function (e) {
        dispatch(updateExchangeProperty(e.target.name, e.target.value));
    }

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
            <div className="row m-0 p-0">
                <div className="col-1 p-0 pt-3 pt-2 header-column">
                    <h1 className="m-0 p-0 py-2 px-4">New Order</h1>
                </div>
                <div className="col-11 border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                    <div className={"row m-0 p-0 justify-content-center"}>
                        <div className="card border-1 border-dark shadow p-2 col-6">
                            <div className={"row m-0 p-0"}>
                                <label className="form-label small m-0 p-0 mt-2 text-secondary">Exchange Type</label>
                                <select name="exchangeTypeId" className="form-select" onChange={handleValueChange} value={exchangeData.exchangeTypeId ? exchangeData.exchangeTypeId : ""}>
                                    <option disabled value="">Select an Exchange Type</option>
                                    {exchangeTypes.map((ex, idx) => <option key={idx} value={ex.id}>{ex.name}</option>)}
                                </select>
                            </div>
                            <div className={"row m-0 p-0"}>
                                <label className="form-label small m-0 p-0 mt-2 text-secondary">Order Number</label>
                                <input name="exchangeId" className="form-control" onChange={handleValueChange} value={exchangeData.exchangeId ? exchangeData.exchangeId : ""}/>
                            </div>
                            <div className={"row m-0 p-0"}>
                                <label className="form-label small m-0 p-0 mt-2 text-secondary">Exchange Officer</label>
                                <input name="exchangeOfficer" className="form-control" onChange={handleValueChange} value={exchangeData.exchangeOfficer ? exchangeData.exchangeOfficer : ""}/>
                            </div>
                            <div className={"row m-0 p-0"}>
                                <label className="form-label small m-0 p-0 mt-2 text-secondary">Agency</label>
                                <input name="agency" className="form-control" onChange={handleValueChange} value={exchangeData.agency ? exchangeData.agency : ""}/>
                            </div>
                            <div className={"row m-0 p-0"}>
                                <div className={"col-12 m-0 p-0 pt-3 text-end"}>
                                    <button className={"btn btn-outline-dark"} onClick={() => dispatch(createExchange(exchangeData, navigate))}>Create</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(NewOrder, {
    onRedirecting: () => <Loading />,
});
