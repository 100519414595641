import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {clearFieldMappingForm, saveFieldMapping, updateCurrentFieldMapping} from "../../actions/document-mappings";
import {clearRectangle} from "../../actions/markup";

export default function FieldMappings () {

    const dispatch = useDispatch();
    const currentField = useSelector(state => state.documentMappings.currentField);
    const classFields = useSelector(state => state.documentMappings.classFields);

    const handleFieldChange = function (e) {
        dispatch(updateCurrentFieldMapping(e.target.name, e.target.value));
    }

    const saveChanges = async function () {
        await dispatch(saveFieldMapping());
        dispatch(clearFieldMappingForm());
        dispatch(clearRectangle());
    }

    const cancelAdd = function () {
        dispatch(clearFieldMappingForm());
        dispatch(clearRectangle());
    }

    const addMappingfield = function (field) {
        dispatch(updateCurrentFieldMapping("contents", currentField.contents + "{" + field + "}"));
    }

    return (<div>
        <div>
            <label className={"form-label"}>Tag Contents</label>
            <textarea name={"contents"} className={"form-control font-small"} rows={4} value={currentField.contents} onChange={handleFieldChange}/>
        </div>
        <div className={"text-end"}>
            <button className={"btn btn-outline-danger py-0 px-1 me-2 mt-1"} title={"Clear"} onClick={cancelAdd}><FontAwesomeIcon icon={faTimes}/></button>
            <button className={"btn btn-outline-dark py-0 px-1 mt-1"} title={"Add Field Mapping"} onClick={saveChanges}><FontAwesomeIcon icon={faSave}/></button>
        </div>
        <div>
            <h6>Available Fields</h6>
        </div>
        <div className={"d-grid gap-2 mx-auto mb-3"}><button className={"btn btn-outline-dark py-0 px-1"} title={"Add Date to Smart Tag"} onClick={() => addMappingfield("DATE_GENERATED")}>Generated Date</button></div>
        <div className={"d-grid gap-2 mx-auto mb-3"}><button className={"btn btn-outline-dark py-0 px-1"} title={"Add Date to Smart Tag"} onClick={() => addMappingfield("TIME_GENERATED")}>Generated Time</button></div>
        {classFields.map((f, idx) => <div key={idx} className={"d-grid gap-2 mx-auto mb-1"}>
            <button className={"btn btn-outline-dark py-0 px-1"} title={"Add Field to Smart Tag"} onClick={() => addMappingfield(f.id)}>{f.name}</button>
        </div>)}

    </div>);
}