import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'reactjs-popup/dist/index.css';
import './index.css';
import './vertical-tabs.min.css';
import './utilities/extensions';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import axios from 'axios';
import {createRoot} from "react-dom/client";


// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams:{
        ...(config.audience ? { audience: config.audience } : null),
        redirect_uri: window.location.origin
    }

};

axios.interceptors.request.use(
    config => {
        // Do something before request is sent
        config.headers["Authorization"] = "Bearer " + store.getState().session.token;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
        <Provider store={store}>
                <Router>
                    <App />
                </Router>
        </Provider>
    </Auth0Provider>
  </React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
