import React, {useEffect} from "react";
import DocumentList from "./document-list";
import DocumentBody from "./document-body";
import {useDispatch, useSelector} from "react-redux";
import {
    downloadDocument,
    loadClassMappingFields,
    loadDocumentMappings
} from "../../actions/document-mappings";
import FieldMappings from "./field-mappings";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";
import SinglePage from "../pdf/single-page";

export const DocumentMappings = function () {

    const dispatch = useDispatch();
    const currentDocument = useSelector(state => state.documentMappings.currentDocument);
    const mappingId = useSelector(state => state.documentMappings.currentDocument.id);
    const documentUrl = useSelector(state => state.documentMappings.documentUrl);
    const rectangleSelected = useSelector(state => state.markup.rectangleSelected);

    useEffect(() => {
        dispatch(loadDocumentMappings());
        if (mappingId > 0 && currentDocument.fileName && currentDocument.fileName.substr(-3).toUpperCase() === "PDF"){
            dispatch(loadClassMappingFields(currentDocument.className));
            dispatch(downloadDocument(mappingId));
        }

    }, [dispatch, mappingId, currentDocument.className, currentDocument.fileName]);
    return (<div className="card border-0 shadow my-5 p-2">
        <div className="row m-0 p-0 mb-1">
            <div className="card col-12 float-end border-1 border-dark blue-body"><h4>Document Tagging</h4></div>
        </div>
        <div className={"row m-0 p-0"}>
            <div className={"col-12 card border-1 p-0 m-0 border-dark shadow"}>
                <div className={"row p-0 m-0"}>
                    <div className="col-3 p-0 pt-3 menu-column">
                        <DocumentList/>
                    </div>
                    <div className="col-9 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                        <div className={"row m-0 p-0"}>
                            <div className={"col-12 m-0 p-0"}>
                                <DocumentBody/>
                            </div>
                        </div>
                        <hr/>

                        {currentDocument.fileName && currentDocument.fileName.substr(-3).toUpperCase() === "PDF" ?  <div className={"row m-0 p-0"}>
                            <div className={"col-8 m-0 p-0 pb-2"}>
                                {documentUrl ? <SinglePage pdf={documentUrl}/> : <div>&nbsp;</div>}
                            </div>
                            <div className={"col-4 m-0 p-0"}>
                                {mappingId && rectangleSelected ? <FieldMappings/> : <div>&nbsp;</div>}
                            </div>
                        </div>: <div>&nbsp;</div>}
                        {currentDocument.fileName && (currentDocument.fileName.substr(-4).toUpperCase() === "DOCX" || currentDocument.fileName.substr(-4).toUpperCase() === "DOCM") ?  <div className={"row m-0 p-0"}>
                            <div>
                                <h4>To update the document: </h4>
                                <ol>
                                    <li>Click the document name above to download a copy of the document.</li>
                                    <li>Open the downloaded copy in Microsoft Word and make any necessary changes.</li>
                                    <li>Drop the updated document in the dashed box above to update the document.</li>
                                    <li>Click the save button to save the updated document in the system.</li>
                                </ol>
                            </div>
                        </div>: <div>&nbsp;</div>}
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(DocumentMappings, {
    onRedirecting: () => <Loading />,
});
