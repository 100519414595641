import React from "react";
import {Link, useParams} from "react-router-dom";

export const ExchangeNav = function () {

    const {id, section} = useParams();

    return (<div className="d-flex flex-column flex-shrink-0 p-3 text-dark">
                <Link to={"/order/" + id + "/detail"}  className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <span className={section === "detail" ? "fs-5 text-decoration-underline" : "fs-5"}>Order Information</span>
                </Link>
                <ul className="nav nav-pills flex-column mb-auto">
                    <li className="nav-item">
                        <Link to={"/order/" + id + "/basic"} className={section === "basic" ? "nav-link text-dark text-decoration-underline" : "nav-link text-dark"} aria-current="page">
                            Basic Info
                        </Link>
                    </li>
                    <li>
                        <Link to={"/order/" + id + "/properties"} className={section === "properties" ? "nav-link text-dark text-decoration-underline" : "nav-link text-dark"}>
                            Properties
                        </Link>
                    </li>
                    <li>
                        <Link to={"/order/" + id + "/taxpayer"} className={section === "taxpayer" ? "nav-link text-dark text-decoration-underline" : "nav-link text-dark"}>
                            Taxpayer
                        </Link>
                    </li>
                </ul>
                <span className="fs-5 mt-4">Closing</span>
                <ul className="nav nav-pills flex-column mb-auto">
                    <li className="nav-item">
                        <Link to={"/order/" + id + "/accounting"} className={section === "accounting" ? "nav-link text-dark text-decoration-underline" : "nav-link text-dark"} aria-current="page">
                            Accounting
                        </Link>
                    </li>
                </ul>
                <Link to={"/order/" + id + "/attachments"} className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <span className={section === "attachments" ? "fs-5 mt-4 text-decoration-underline" : "fs-5 mt-4"}>Documents</span>
                </Link>
            </div>);
}

export default ExchangeNav;