import {
    CLEAR_CONTACT, CREATE_CONTACT_USER,
    LOAD_ALL_CONTACTS,
    LOAD_EXCHANGES_FOR_USER,
    LOAD_CONTACT_BY_ID,
    SAVE_CONTACT, EDIT_EXCHANGE_CONTACT, COPY_CONTACT, DELETE_CONTACT
} from "./action-types";
import toast from "react-hot-toast";
import {ContactApi, ExchangeApi} from "../api";
import {baseUrl} from "../constants/settings";

const contactApi = new ContactApi(undefined, baseUrl);
const exchangeApi = new ExchangeApi(undefined, baseUrl);

export const loadAllContacts = function() {
    return async dispatch => {
        try
        {
            const response = await contactApi.apiContactGet();
            dispatch({ type: LOAD_ALL_CONTACTS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadContactById = function(contactId) {
    return async dispatch => {
        try
        {
            const response = await contactApi.apiContactIdGet(contactId);
            if(response.data){
                dispatch({ type: LOAD_CONTACT_BY_ID, payload: response.data });
            } else {
                dispatch({ type: CLEAR_CONTACT});
            }

        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadContactByUserId = function() {
    return async dispatch => {
        try
        {
            const response = await contactApi.apiContactByUserGet();
            dispatch({ type: LOAD_CONTACT_BY_ID, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const saveContact = function(contact) {
    return async (dispatch) => {
        try
        {
            let response;
            if (contact.id === 0){
                response = await contactApi.apiContactPost(contact);
                contact.id = response.data;
            } else {
                response = await contactApi.apiContactPut(contact);
            }
            dispatch({ type: SAVE_CONTACT, payload: contact });
            toast.success("Contact saved successfully");
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const copyContact = function(criteria) {
    return async (dispatch) => {
        try
        {
            const response = await contactApi.apiContactCopyPost(criteria);
            dispatch({ type: COPY_CONTACT, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const clearCurrentContact = () => {
    return dispatch => {
        dispatch({type: CLEAR_CONTACT});
    }
}



export const loadExchangesForUser = function(contactId) {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeForUserTaxpayerIdGet(contactId);
            dispatch({ type: LOAD_EXCHANGES_FOR_USER, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadExchangesForCurrentUser = function() {
    return async dispatch => {
        try {
            const response = await exchangeApi.apiExchangeForUserGet();
            dispatch({ type: LOAD_EXCHANGES_FOR_USER, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const createContactUser = function(contactId) {
    return async dispatch => {
        try {
            const response = await contactApi.apiContactCreateUserContactIdGet(contactId);
            dispatch({ type: CREATE_CONTACT_USER, payload: response.data.userId });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const editExchangeContact = (contact) => {
    return dispatch => {
        dispatch({type: EDIT_EXCHANGE_CONTACT, payload: contact});
    }
}

export const removeContact = function(contactId) {
    return async (dispatch) => {
        try
        {
            await contactApi.apiContactDelete(contactId);
            dispatch({ type: DELETE_CONTACT });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}


