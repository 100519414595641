import {
    CLEAR_EXCHANGE,
    LOG_OUT,
    START_CREATING_EXCHANGE,
    START_EDITING_EXCHANGE,
    TOGGLE_EDIT, TOGGLE_LOADING_MESSAGES, TOGGLE_LOADING_TOPICS, TOGGLE_MESSAGE_ARCHIVE_BUTTONS,
    TOGGLE_SPINNER
} from "./action-types";


export const toggleEditExchange = function (sectionName) {
    return dispatch => {
        dispatch({ type: TOGGLE_EDIT, payload: sectionName });
    }
}

export const clearExchange = function () {
    return dispatch => {
        dispatch({ type: CLEAR_EXCHANGE });
    }
}

export const startEditingExchange = function () {
    return dispatch => {
        dispatch({ type: START_EDITING_EXCHANGE });
    }
}

export const startCreatingExchange = function () {
    return dispatch => {
        dispatch({ type: START_CREATING_EXCHANGE });
    }
}

export const logUserOut = function () {
    return dispatch => {
        dispatch({type: LOG_OUT });
    }
}

export const toggleSpinner = function (spinnerName) {
    return dispatch => {
        dispatch({type: TOGGLE_SPINNER, payload: spinnerName})
    }
}

export const toggleMessageArchiveButtons = function () {
    return dispatch => {
        dispatch({type: TOGGLE_MESSAGE_ARCHIVE_BUTTONS});
    }
}

export const toggleLoadingTopics = function () {
    return dispatch => {
        dispatch({type: TOGGLE_LOADING_TOPICS})
    }
}

export const toggleLoadingMessages = function () {
    return dispatch => {
        dispatch({type: TOGGLE_LOADING_MESSAGES});
    }
}