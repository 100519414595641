import {
    CLEAR_SELECTED_TEMPLATE,
    LOAD_TEMPLATE_LIST, SAVE_TEMPLATE, SELECT_TEMPLATE,
    UPDATE_TEMPLATE_FIELD
} from "./action-types";
import {baseUrl} from "../constants/settings";
import toast from "react-hot-toast";
import {EmailTemplateApi} from "../api";

const emailTemplateApi = new EmailTemplateApi(undefined, baseUrl);

export const updateTemplateField = function (field, value) {
    return dispatch => {
        dispatch({type: UPDATE_TEMPLATE_FIELD, payload: {field, value}});
    }
}

export const clearSelectedTemplate = function () {
    return dispatch => {
        dispatch({type: CLEAR_SELECTED_TEMPLATE});
    }
}

export const selectTemplate = function (template) {
    return dispatch => {
        dispatch({type: SELECT_TEMPLATE, payload: template});
    }
}

export const loadEmailTemplates = function() {
    return async dispatch => {
        try {
            const response = await emailTemplateApi.apiEmailTemplateGet();
            dispatch({ type: LOAD_TEMPLATE_LIST, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const saveEmailTemplate = function(template) {
    return async dispatch => {
        try {
            let response;
            if (template.id > 0) {
                response = await emailTemplateApi.apiEmailTemplateUpdatePost(template);
            } else {
                response = await emailTemplateApi.apiEmailTemplatePost(template);
            }
            template.id = response.data;
            dispatch({ type: SAVE_TEMPLATE, payload: template });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}