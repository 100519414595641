import {
    TOGGLE_EDIT,
    CLEAR_EXCHANGE,
    START_EDITING_EXCHANGE,
    START_CREATING_EXCHANGE,
    LOG_OUT,
    TOGGLE_SPINNER, TOGGLE_MESSAGE_ARCHIVE_BUTTONS, TOGGLE_LOADING_TOPICS, TOGGLE_LOADING_MESSAGES
} from "../actions/action-types";

const initialState = {
    editing: "",
    spinning: "",
    canEditTaxpayer: true,
    canEditAddress: true,
    canEditSettlementAgency: true,
    canEditExchange: true,
    showMessageArchiveButtons: false,
    loadingTopics: false,
    loadingMessages: false
}

const ui = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case TOGGLE_EDIT:
            newState = Object.assign({}, state);
            if (state.editing === action.payload){
                newState.editing = "";
            } else {
                newState.editing = action.payload;
            }
            return newState;

        case CLEAR_EXCHANGE:
            newState = Object.assign({}, state);
            newState.canEditSettlementAgency = true;
            newState.canEditTaxpayer = true;
            newState.canEditAddress = true;
            newState.canEditExchange = true;
            newState.editing = "";
            return newState;

        case START_CREATING_EXCHANGE:
            newState = Object.assign({}, state);
            newState.canEditSettlementAgency = false;
            newState.canEditTaxpayer = false;
            newState.canEditAddress = false;
            newState.canEditExchange = true;
            newState.editing = "Exchange";
            return newState;

        case START_EDITING_EXCHANGE:
            newState = Object.assign({}, state);
            newState.canEditSettlementAgency = true;
            newState.canEditTaxpayer = true;
            newState.canEditAddress = true;
            newState.canEditExchange = true;
            newState.editing = "";
            return newState;

        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case TOGGLE_SPINNER:
            newState = Object.assign({}, state);
            if (state.spinning === action.payload){
                newState.spinning = "";
            } else {
                newState.spinning = action.payload;
            }
            return newState;

        case TOGGLE_MESSAGE_ARCHIVE_BUTTONS:
            newState = Object.assign({}, state);
            newState.showMessageArchiveButtons = !state.showMessageArchiveButtons;
            return newState;

        case TOGGLE_LOADING_TOPICS:
            newState = Object.assign({}, state);
            newState.loadingTopics = !state.loadingTopics;
            return newState;

        case TOGGLE_LOADING_MESSAGES:
            newState = Object.assign({}, state);
            newState.loadingMessages = !state.loadingMessages;
            return newState;

        default:
            return state
    }
}

export default ui;