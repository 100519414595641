import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {insertUserSetting, loadUserSettings, updateUserSetting} from "../../actions/session";
import {OptOutEmailSetting} from "../../constants/settings";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";

export const Settings = function () {

    const dispatch = useDispatch();
    const settings = useSelector(state => state.session.settings);
    const optOutOfEmail = useSelector(state => state.session[OptOutEmailSetting]);

    useEffect(() => {
        dispatch(loadUserSettings());
    }, [dispatch]);

    const checkboxSettingChanged = async function (e) {
        const settingObj = settings.find(s => s.setting === e.target.name);
        settingObj.value = e.target.checked.toString();
        if(settingObj.id === 0){
            await dispatch(insertUserSetting(settingObj));
        } else {
            await dispatch(updateUserSetting(settingObj));
        }
    }

    const renderOptOut = function () {
        return (<tr>
            <td>Opt out of Emails </td>
            <td>
                <input type="checkbox" name={OptOutEmailSetting} defaultChecked={optOutOfEmail} onClick={checkboxSettingChanged}/>
            </td>
        </tr>)
    }

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="row m-0 p-0 mb-1">
            <div className="card col-12 float-end border-1 border-dark blue-body"><h4>User Profile</h4></div>
        </div>
        <div className={"row m-0 p-0"}>
            <div className={"col-5 card border-1 p-0 m-0 border-dark shadow"}>
                <div className={"row p-0 m-0"}>
                    <div className="col-1 p-0 pt-3 header-column">
                        <h2 className="m-0 p-0 px-2">Settings</h2>
                    </div>
                    <div className="col-11 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                        <table className={"table table-borderless"}>
                            <tbody>
                            {renderOptOut()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(Settings, {
    onRedirecting: () => <Loading />,
});