import {
    CLEAR_CONTACT, CREATE_CONTACT_USER,
    LOAD_ALL_CONTACTS, LOAD_EXCHANGES_FOR_USER, LOAD_CONTACT_BY_ID,
    LOG_OUT, SAVE_CONTACT, UPDATE_CONTACT_PROPERTY, EDIT_EXCHANGE_CONTACT, LOAD_CONTACT_FOR_TAB, COPY_CONTACT
} from '../actions/action-types';

const initialState = {
    allContacts: [],
    currentContact: {
        id: 0,
        companyName: "",
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        emailAddress: "",
        homePhone: "",
        workPhone: "",
        mobilePhone: "",
        userId: ""
    },
    exchanges: []
}

const contact = (state = initialState, action) => {
    let newState;
    let taxpayer;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case LOAD_ALL_CONTACTS:
            newState = Object.assign({}, state);
            newState.allContacts = action.payload;
            return newState;

        case LOAD_CONTACT_BY_ID:
            newState = Object.assign({}, state);
            newState.currentContact = action.payload;
            return newState;

        case UPDATE_CONTACT_PROPERTY:
            newState = Object.assign({}, state);
            taxpayer = Object.assign({}, state.currentContact);
            taxpayer[action.payload.name] = action.payload.value;
            newState.currentContact = taxpayer;
            return newState;

        case SAVE_CONTACT:
            newState = Object.assign({}, state);
            newState.currentContact = action.payload;
            return newState;

        case CLEAR_CONTACT:
            newState = Object.assign({}, state);
            newState.currentContact = Object.assign({}, initialState.currentContact);
            return newState;

        case LOAD_EXCHANGES_FOR_USER:
            newState = Object.assign({}, state);
            newState.exchanges = action.payload;
            return newState;

        case CREATE_CONTACT_USER:
            newState = Object.assign({}, state);
            taxpayer = Object.assign({}, state.currentContact);
            taxpayer.userId = action.payload;
            newState.currentContact = taxpayer;
            return newState;

        case EDIT_EXCHANGE_CONTACT:
            newState = Object.assign({}, state);
            newState.currentContact = action.payload;
            return newState;

        case LOAD_CONTACT_FOR_TAB:
            newState = Object.assign({}, state);
            newState.currentContact = action.payload;
            return newState;

        case COPY_CONTACT:
            newState = Object.assign({}, state);
            newState.currentContact = action.payload;
            return newState;

        default:
            return state
    }
}

export default contact
