export const GET_ALL_EXCHANGES = "exchange/GET_ALL_EXCHANGES";
export const GET_EXCHANGE_BY_ID = "exchange/GET_EXCHANGE_BY_ID";
export const CREATE_EXCHANGE = "exchange/CREATE_EXCHANGE";
export const UPDATE_EXCHANGE = "exchange/UPDATE_EXCHANGE";
export const DELETE_EXCHANGE = "exchange/DELETE_EXCHANGE";
export const ADD_PROPERTY_ADDRESS = "exchange/ADD_PROPERTY_ADDRESS";
export const SELECT_PROPERTY_ADDRESS = "exchange/SELECT_PROPERTY_ADDRESS";
export const UPDATE_PROPERTY_ADDRESS = "exchange/UPDATE_PROPERTY_ADDRESS";
export const CLEAR_PROPERTY_ADDRESS = "exchange/CLEAR_PROPERTY_ADDRESS";
export const REMOVE_PROPERTY_ADDRESS = "exchange/REMOVE_PROPERTY_ADDRESS";
export const ADD_SETTLEMENT_AGENCY = "exchange/ADD_SETTLEMENT_AGENCY";
export const UPDATE_SETTLEMENT_AGENCY = "exchange/UPDATE_SETTLEMENT_AGENCY";
export const LOAD_EXCHANGE_CONTACTS = "exchange/LOAD_EXCHANGE_CONTACTS";
export const LOAD_PROPERTIES = "exchange/LOAD_PROPERTIES";
export const LOAD_SETTLEMENT_AGENCY = "exchange/LOAD_SETTLEMENT_AGENCY";
export const UPDATE_EXCHANGE_PROPERTY = "exchange/UPDATE_EXCHANGE_PROPERTY";
export const UPDATE_PROPERTY_ADDRESS_PROPERTY = "exchange/UPDATE_PROPERTY_ADDRESS_PROPERTY";
export const UPDATE_SETTLEMENT_AGENCY_PROPERTY = "exchange/UPDATE_SETTLEMENT_AGENCY_PROPERTY";
export const EDIT_EXCHANGE_CONTACT = "exchange/EDIT_EXCHANGE_CONTACT";
export const LOAD_CONTACT_TYPES = "exchange/LOAD_CONTACT_TYPES";
export const SET_CONTACT_TYPE = "exchange/SET_CONTACT_TYPE";
export const SET_PROPERTY_TYPE = "exchange/SET_PROPERTY_TYPE";
export const LOAD_CONTACT_TABS = "exchange/LOAD_CONTACT_TABS";
export const LOAD_PROPERTY_TABS = "exchange/LOAD_PROPERTY_TABS";
export const SET_CONTACT_TAB = "exchange/SET_CONTACT_TAB";
export const LOAD_CONTACT_FOR_TAB = "exchange/LOAD_CONTACT_FOR_TAB";
export const LOAD_EXCHANGE_TYPES = "exchange/LOAD_EXCHANGE_TYPES";
export const TOGGLE_EXCHANGE_STATUS = "exchange/TOGGLE_EXCHANGE_STATUS";

export const CLEAR_SESSION = 'session/CLEAR_SESSION';
export const SET_TOKEN = 'session/SET_TOKEN';
export const LOAD_SETTINGS = "session/LOAD_SETTINGS";
export const INSERT_SETTING = "session/INSERT_SETTING";
export const UPDATE_SETTING = "session/UPDATE_SETTING";
export const SET_USER_ROLES = "session/SET_USER_ROLES";

export const TOGGLE_EDIT = "ui/TOGGLE_EDIT";
export const CLEAR_EXCHANGE = "ui/CLEAR_EXCHANGE";
export const START_CREATING_EXCHANGE = "ui/START_CREATING_EXCHANGE";
export const START_EDITING_EXCHANGE = "ui/START_EDITING_EXCHANGE";
export const LOG_OUT = "ui/LOG_OUT";
export const TOGGLE_SPINNER = "ui/TOGGLE_SPINNER";
export const TOGGLE_MESSAGE_ARCHIVE_BUTTONS = "ui/TOGGLE_MESSAGE_ARCHIVE_BUTTONS";
export const TOGGLE_LOADING_TOPICS = "ui/TOGGLE_LOADING_TOPICS";
export const TOGGLE_LOADING_MESSAGES = "ui/TOGGLE_LOADING_MESSAGES";

export const LIST_ATTACHMENTS_FOR_EXCHANGE = "exchangeAttachment/LIST_ATTACHMENTS_FOR_EXCHANGE";
export const UPLOAD_ATTACHMENT = "exchangeAttachment/UPLOAD_ATTACHMENT";
export const REMOVE_ATTACHMENT = "exchangeAttachment/REMOVE_ATTACHMENT";
export const DOWNLOAD_ATTACHMENT = "exchangeAttachment/DOWNLOAD_ATTACHMENT";
export const LOAD_ATTACHMENT_FOLDERS = "exchangeAttachment/LOAD_ATTACHMENT_FOLDERS";
export const UPDATE_SELECTED_FOLDER_FIELD = "exchangeAttachment/UPDATE_SELECTED_FOLDER_FIELD";
export const SELECT_FOLDER = "exchangeAttachment/SELECT_FOLDER";
export const CLEAR_SELECTED_FOLDER = "exchangeAttachment/CLEAR_SELECTED_FOLDER";
export const SAVE_ATTACHMENT_FOLDER = "exchangeAttachment/SAVE_ATTACHMENT_FOLDER";
export const DELETE_FOLDER = "exchangeAttachment/DELETE_FOLDER";
export const ADD_TEMP_ATTACHMENT = "exchangeAttachment/ADD_TEMP_ATTACHMENT";
export const REMOVE_TEMP_ATTACHMENT = "exchangeAttachment/REMOVE_TEMP_ATTACHMENT";
export const UPDATE_TEMP_ATTACHMENT = "exchangeAttachment/UPDATE_TEMP_ATTACHMENT";
export const MOVE_ATTACHMENT = "exchangeAttachment/MOVE_ATTACHMENT";

export const SEND_MESSAGE = "message/SEND_MESSAGE";
export const UPDATE_MESSAGE_FIELD = "message/UPDATE_MESSAGE_FIELD";
export const LOAD_TOPICS = "message/LOAD_TOPICS";
export const SELECT_MESSAGE_TOPIC = "message/SELECT_MESSAGE_TOPIC";
export const RESET_MESSAGE_SCREEN = "message/RESET_MESSAGE_SCREEN";
export const START_NEW_TOPIC = "message/START_NEW_TOPIC";
export const ARCHIVE_TOPIC = "message/ARCHIVE_TOPIC";
export const UNARCHIVE_TOPIC = "message/UNARCHIVE_TOPIC";
export const DELETE_TOPIC = "message/DELETE_TOPIC";
export const TOGGLE_ARCHIVED_TOPICS = "message/TOGGLE_ARCHIVED_TOPICS";

export const UPDATE_TEMPLATE_FIELD = "emailTemplate/UPDATE_TEMPLATE_FIELD";
export const LOAD_TEMPLATE_LIST = "emailTemplate/LOAD_TEMPLATE_LIST";
export const SELECT_TEMPLATE = "emailTemplate/SELECT_TEMPLATE"
export const CLEAR_SELECTED_TEMPLATE = "emailTemplate/CLEAR_SELECTED_TEMPLATE";
export const SAVE_TEMPLATE = "emailTemplate/SAVE_TEMPLATE";

export const LOAD_DOCUMENT_MAPPINGS = "documentMappings/LOAD_DOCUMENT_MAPPINGS";
export const UPDATE_DOCUMENT_FIELD = "documentMappings/UPDATE_DOCUMENT_FIELD";
export const SELECT_DOCUMENT_MAPPING = "documentMappings/SELECT_DOCUMENT_MAPPING";
export const CLEAR_SELECTED_MAPPING = "documentMappings/CLEAR_SELECTED_MAPPING";
export const SAVE_DOCUMENT_MAPPING = "documentMappings/SAVE_DOCUMENT_MAPPING";
export const REMOVE_DOCUMENT_MAPPING = "documentMappings/REMOVE_DOCUMENT_MAPPING";
export const LOAD_FIELD_MAPPINGS = "documentMappings/LOAD_FIELD_MAPPINGS";
export const UPDATE_CURRENT_FIELD_MAPPING = "documentMappings/UPDATE_CURRENT_FIELD_MAPPING";
export const SAVE_FIELD_MAPPING = "documentMappings/SAVE_FIELD_MAPPING";
export const CLEAR_SELECTED_FIELD_MAPPING = "documentMappings/CLEAR_SELECTED_FIELD_MAPPING";
export const REMOVE_FIELD_MAPPING = "documentMappings/REMOVE_FIELD_MAPPING";
export const LOAD_CLASS_MAPPING_FIELDS = "documentMappings/LOAD_CLASS_MAPPING_FIELDS";
export const LOAD_DOCUMENT_FIELDS = "documentMappings/LOAD_DOCUMENT_FIELDS";
export const GENERATE_DOCUMENT = "documentMappings/GENERATE_DOCUMENT";
export const DOWNLOAD_DOCUMENT = "documentMappings/DOWNLOAD_DOCUMENT";
export const SELECT_FIELD_MAPPING = "documentMappings/SELECT_FIELD_MAPPING";

export const SET_PDF_CANVAS_SIZE = "markup/SET_PDF_CANVAS_SIZE";
export const SET_NUMBER_OF_PAGES = "markup/SET_NUMBER_OF_PAGES";
export const SET_PAGE_NUMBER = "markup/SET_PAGE_NUMBER";
export const SET_DRAGGING_MOUSE = "markup/SET_DRAGGING_MOUSE";
export const UPDATE_START_POSITION = "markup/UPDATE_START_POSITION";
export const SET_RECTANGLE_SIZE = "markup/SET_RECTANGLE_SIZE";
export const CLEAR_RECTANGLE = "markup/CLEAR_RECTANGLE";
export const RECTANGLE_SELECTED = "markup/RECTANGLE_SELECTED";

export const LOAD_USERS = "userAdmin/LOAD_USERS";
export const LOAD_ROLES = "userAdmin/LOAD_ROLES";
export const SELECT_USER = "userAdmin/SELECT_USER";
export const LOAD_SELECTED_USER_ROLE = "userAdmin/LOAD_SELECTED_USER_ROLE";
export const CLEAR_USER_FORM = "userAdmin/CLEAR_USER_FORM";
export const UPDATE_CURRENT_USER_FIELD = "userAdmin/UPDATE_CURRENT_USER_FIELD";
export const SAVE_USER = "userAdmin/SAVE_USER";
export const TOGGLE_USER_MODAL = "userAdmin/TOGGLE_USER_MODAL";
export const REMOVE_USER = "userAdmin/REMOVE_USER";

export const LOAD_ALL_CONTACTS = "contact/LOAD_ALL_CONTACTS";
export const LOAD_CONTACT_BY_ID = "contact/LOAD_CONTACT_BY_ID";
export const UPDATE_CONTACT_PROPERTY = "contact/UPDATE_CONTACT_PROPERTY";
export const SAVE_CONTACT = "contact/SAVE_CONTACT";
export const CLEAR_CONTACT = "contact/CLEAR_CONTACT";
export const LOAD_EXCHANGES_FOR_USER = "contact/LOAD_EXCHANGES_FOR_USER";
export const CREATE_CONTACT_USER = "contact/CREATE_CONTACT_USER";
export const COPY_CONTACT = "contact/COPY_CONTACT";
export const DELETE_CONTACT = "contact/DELETE_CONTACT";

export const LOAD_SMART_TAGS = "smartTags/LOAD_SMART_TAGS";
export const SHOW_SMART_TAG_MODAL = "smartTags/SHOW_SMART_TAG_MODAL";
export const HIDE_SMART_TAG_MODAL = "smartTags/HIDE_SMART_TAG_MODAL";
export const SELECT_SMART_TAG = "smartTags/SELECT_SMART_TAG";
export const CLEAR_SELECTED_SMART_TAG = "smartTags/CLEAR_SELECTED_SMART_TAG";
export const UPDATE_SELECTED_SMART_TAG_PROPERTY = "smartTags/UPDATE_SELECTED_SMART_TAG_PROPERTY";
export const LOAD_MAPPING_OBJECT_TYPES = "smartTags/LOAD_MAPPING_OBJECT_TYPES";
export const UPDATE_SMART_TAG = "smartTags/UPDATE_SMART_TAG";
export const ADD_SMART_TAG = "smartTags/ADD_SMART_TAG";
export const DELETE_SMART_TAG = "smartTags/DELETE_SMART_TAG";

export const UPDATE_REGISTRATION_FIELD = "registration/UPDATE_REGISTRATION_FIELD";
export const SUBMIT_REGISTRATION = "registration/SUBMIT_REGISTRATION";
export const LOAD_ALL_REGISTRATIONS = "registration/LOAD_ALL_REGISTRATIONS";
export const LOAD_REGISTRATION_BY_ID = "registration/LOAD_REGISTRATION_BY_ID";
export const CONVERT_REGISTRATION = "registration/CONVERT_REGISTRATION";

export const GET_BANK = "application-setting/GET_BANK";
export const GET_QI = 'application-setting/GET_QI';
export const UPSERT_BANK = "application-setting/UPSERT_BANK";
export const UPSERT_QI = 'application-setting/UPSERT_QI';
export const UPDATE_BANK_FIELD = "application-setting/UPDATE_BANK_FIELD";
export const UPDATE_QI_FIELD = 'application-setting/UPDATE_QI_FIELD';
