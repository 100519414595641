import {
    ADD_SMART_TAG,
    CLEAR_SELECTED_SMART_TAG, DELETE_SMART_TAG,
    HIDE_SMART_TAG_MODAL, LOAD_MAPPING_OBJECT_TYPES,
    LOAD_SMART_TAGS,
    LOG_OUT, SELECT_SMART_TAG, SHOW_SMART_TAG_MODAL, UPDATE_SELECTED_SMART_TAG_PROPERTY, UPDATE_SMART_TAG
} from '../actions/action-types';

const initialState = {
    tags: [],
    showModal: false,
    selectedTag: { name: "", smartTag: "", contactTypeId: null, contactTabId: null, propertyTypeId: null, field: "", mappingObjectId: null},
    mappingObjectTypes: []
};

const smartTags = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case LOAD_SMART_TAGS:
            newState = Object.assign({}, state);
            newState.tags = action.payload;
            return newState;

        case SHOW_SMART_TAG_MODAL:
            newState = Object.assign({}, state);
            newState.showModal = true;
            return newState;

        case HIDE_SMART_TAG_MODAL:
            newState = Object.assign({}, state);
            newState.showModal = false;
            return newState;

        case CLEAR_SELECTED_SMART_TAG:
            newState = Object.assign({}, state);
            newState.selectedTag = Object.assign({}, initialState.selectedTag);
            return newState;

        case SELECT_SMART_TAG:
            newState = Object.assign({}, state);
            newState.selectedTag = action.payload;
            return newState;

        case UPDATE_SELECTED_SMART_TAG_PROPERTY:
            newState = Object.assign({}, state);
            const newTag = Object.assign({}, state.selectedTag);
            newTag[action.payload.property] = action.payload.value;
            newState.selectedTag = newTag;
            return newState;

        case LOAD_MAPPING_OBJECT_TYPES:
            newState = Object.assign({}, state);
            newState.mappingObjectTypes = action.payload;
            return newState;

        case UPDATE_SMART_TAG:
            newState = Object.assign({}, state);
            return newState;

        case ADD_SMART_TAG:
            newState = Object.assign({}, state);
            return newState;

        case DELETE_SMART_TAG:
            newState = Object.assign({}, state);
            return newState;

        default:
            return state
    }
}

export default smartTags;