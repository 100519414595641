import React, {useEffect, useRef} from "react";
import { Document, Page } from 'react-pdf';
import {useDispatch, useSelector} from "react-redux";
import {
    rectangleSelected,
    setDraggingMouse,
    setNumberOfPages,
    setPageNumber,
    setPdfCanvasSize, setRectangleSize,
    updateStartPosition
} from "../../actions/markup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {selectFieldMapping} from "../../actions/document-mappings";

export default function SinglePage(props) {
    const numPages = useSelector(state => state.markup.numPages);
    const pageNumber = useSelector(state => state.markup.pageNumber);
    const pdfCanvasSize = useSelector(state => state.markup.pdfCanvas);
    const isDown = useSelector(state => state.markup.isDragging);
    const startPosition = useSelector(state => state.markup.startPosition);
    const smartTags = useSelector(state => state.documentMappings.fields);
    const multiplier = useSelector(state => state.markup.multiplier);
    const dispatch = useDispatch();

    let pdfWrapper = useRef();
    let canvas = useRef();
    let readOnlyCanvas = useRef();

    const drawSmartTags = function (cvs) {
        const tempCtx = canvas.current.getContext("2d");
        tempCtx.clearRect(0, 0, cvs.width * multiplier, cvs.height * multiplier);
        const ctx = readOnlyCanvas.current.getContext("2d");
        ctx.clearRect(0, 0, cvs.width * multiplier, cvs.height * multiplier);
        smartTags.forEach(t => {
            ctx.strokeStyle = "darkgreen";
            ctx.lineWidth = 8;
            const x = Math.round(t.x / t.scaleWidth * cvs.width * multiplier);
            const y = Math.round(t.y / t.scaleHeight * cvs.height * multiplier);
            const width = Math.round(t.width/ t.scaleWidth * cvs.width * multiplier);
            const height = Math.round(t.height/t.scaleHeight * cvs.height * multiplier);
            ctx.strokeRect(x, y, width, height);
            ctx.font = 'bold 32pt Arial';
            ctx.fillStyle = '#000';
            ctx.textAlign="left";
            wrapText(ctx, t.contents, x, y + 40, width, 40);
        })
    }

    useEffect(() => {
        if(pdfCanvasSize.width > 0){
            drawSmartTags(pdfCanvasSize);
        }
        // eslint-disable-next-line
    }, [pdfCanvasSize.width, smartTags.length]);


    function wrapText(ctx, text, x, y, maxWidth, lineHeight) {
        var words = text.split('');
        var line = '';
        ctx.fillStyle = "black";
        ctx.lineWidth = 1;
        for(var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = ctx.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                ctx.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            }
            else {
                line = testLine;
            }
        }
        ctx.fillText(line, x, y);
    }

    function onDocumentLoadSuccess({ numPages }) {
        dispatch(setNumberOfPages(numPages));
        dispatch(setPageNumber(1));
    }

    function onDocumentRenderSuccess() {
        const tmpCanvas = pdfWrapper.current.querySelector('canvas');
        const obj = tmpCanvas.getBoundingClientRect();
        dispatch(setPdfCanvasSize(obj, multiplier));
        drawSmartTags(obj);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function handleMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();
        // save the starting x/y of the rectangle
        const x = Math.round(e.pageX - pdfCanvasSize.x) * multiplier;
        const y = Math.round(e.pageY - pdfCanvasSize.y) * multiplier;
        dispatch(updateStartPosition({x, y}));
        // set a flag indicating the drag has begun
        dispatch(setDraggingMouse(true));
    }

    function handleMouseUp(e) {
        e.preventDefault();
        e.stopPropagation();

        const x = Math.round(e.pageX - pdfCanvasSize.x) * multiplier;
        const y = Math.round(e.pageY - pdfCanvasSize.y) * multiplier;
        if (x === startPosition.x && y === startPosition.y){
            const selectedTag = getclickedRegion(startPosition);
            if (selectedTag) {
                dispatch(selectFieldMapping(selectedTag));
                dispatch(rectangleSelected());
            }
        } else {
            dispatch(rectangleSelected());
        }
        dispatch(setDraggingMouse(false));
    }

    function handleMouseOut(e) {
        e.preventDefault();
        e.stopPropagation();

        // the drag is over, clear the dragging flag
        dispatch(setDraggingMouse(false));
    }

    function handleMouseMove(e) {
        e.preventDefault();
        e.stopPropagation();
        const ctx = canvas.current.getContext("2d");
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 8;
        // if we're not dragging, just return
        if (!isDown) {
            return;
        }

        // get the current mouse position
        const mouseX = Math.round(e.pageX - pdfCanvasSize.x) * multiplier;
        const mouseY = Math.round(e.pageY - pdfCanvasSize.y) * multiplier;

        // Put your mousemove stuff here

        // clear the canvas
        ctx.clearRect(0, 0, pdfCanvasSize.width * multiplier, pdfCanvasSize.height * multiplier);

        // calculate the rectangle width/height based
        // on starting vs current mouse position
        let width = mouseX - startPosition.x;
        let height = mouseY - startPosition.y;

        // draw a new rect from the start position
        // to the current mouse position
        ctx.strokeRect(startPosition.x, startPosition.y, width, height);
        dispatch(setRectangleSize({width, height}));
    }

    const getclickedRegion = function (position) {
        let tag, inXrange, inYrange, x, xMax, y, yMax;
        smartTags.forEach(t => {
            x = Math.round(t.x / t.scaleWidth * pdfCanvasSize.width * multiplier);
            const width = Math.round(t.width / t.scaleWidth * pdfCanvasSize.width * multiplier);
            y = Math.round(t.y / t.scaleHeight * pdfCanvasSize.height * multiplier);
            const height = Math.round(t.height / t.scaleHeight * pdfCanvasSize.height * multiplier);
            xMax = x + width;
            yMax = y + height;
            if (x < xMax){
                inXrange = position.x >= x && position.x <= xMax;
            } else {
                inXrange = position.x >= xMax && position.x <= x;
            }
            if (y < yMax){
                inYrange = position.y >= y && position.y <= yMax;
            } else {
                inYrange = position.y >= yMax && position.y <= y;
            }
            if(inYrange && inXrange) {
                tag = t;
            }
        });

        return tag;
    }

    const { pdf } = props;

    return (
        <div className={"row m-0 p-0"}>
            <div className={"col-12 m-0 p-0"}>
                <div style={{display: "grid"}}>
                    <Document
                        file={pdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className={"grid"}>
                        <Page pageNumber={pageNumber} inputRef={pdfWrapper} onRenderSuccess={onDocumentRenderSuccess} />
                    </Document>
                    <canvas key={"readOnly"} top={pdfCanvasSize.y}
                            left={pdfCanvasSize.x}
                            width={pdfCanvasSize.width * multiplier}
                            height={pdfCanvasSize.height * multiplier}
                            ref={readOnlyCanvas}
                            dir={"ltr"}
                            style={{border: "1px solid #333", zIndex: "95", position: "absolute", width: pdfCanvasSize.width, height: pdfCanvasSize.height}}>&nbsp;</canvas>
                    <canvas key={"drawing"} top={pdfCanvasSize.y}
                            left={pdfCanvasSize.x}
                            width={pdfCanvasSize.width * multiplier}
                            height={pdfCanvasSize.height * multiplier}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}
                            onMouseDown={handleMouseDown}
                            onMouseOut={handleMouseOut}
                            ref={canvas}
                            dir={"ltr"}
                            style={{border: "1px solid #333", zIndex: "100", position: "absolute", width: pdfCanvasSize.width, height: pdfCanvasSize.height}}>&nbsp;</canvas>
                </div>
                <div className={"row p-0 m-0 mt-3"}>
                    <div className={"col-10 text-end"}>
                        <button className={pageNumber <= 1 ? "disabled btn btn-outline-dark" : "btn btn-outline-dark"}
                                onClick={previousPage}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </button>
                        <div className={"d-inline-block px-3"}>
                            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                        </div>
                        <button
                            className={pageNumber >= numPages ? "disabled btn btn-outline-dark" : "btn btn-outline-dark"}
                            onClick={nextPage}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
