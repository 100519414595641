import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "../home/home";
import ExchangeInfo from "../exchange-info";
import MessagePortal from "../messages/message-portal";
import Settings from "../settings";
import EmailTemplate from "../email-template";
import DocumentMappings from "../document-mappings";
import {useSelector} from "react-redux";
import {ADMIN_ROLE, USER_ROLE, CONTACT_ROLE} from "../../constants/settings";
import UserAdmin from "../user-admin";
import Contacts from "../contacts";
import ContactInfo from "../contact-info";
import Calendar from "../calendar";
import Notifications from "../notifications";
import Help from "../help";
import NewOrder from "../new-order";
import SmartTags from "../smart-tags";
import Registration from "../registration";
import RegistrationAdmin from "../registration-admin";
import NoAccount from "../no-account";
import DocumentFolderEditor from "../document-folder-editor";
import Info from "../info";

const AppRoutes = function () {
    const roles = useSelector(state => state.session.roles);

    const isAdmin = roles.indexOf(ADMIN_ROLE) >= 0;
    const isUser = roles.indexOf(USER_ROLE) >= 0;
    const isReadOnly = roles.indexOf(CONTACT_ROLE) >= 0;

    if (roles.length === 0){
        return(
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="registration" element={<Registration/>}/>
                <Route exact path="help" element={<Help/>}/>
                <Route exact path="noaccount" element={<NoAccount/>}/>
            </Routes>
        )
    }

    return (
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="registration" element={<Registration/>}/>
            <Route exact path="help" element={<Help/>}/>
            <Route exact path="noaccount" element={<NoAccount/>}/>

            { (isUser || isReadOnly || isAdmin) && <Route path="order/:id" element={<ExchangeInfo/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route path="order/:id/:section" element={<ExchangeInfo/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route exact path="orders" element={<NewOrder/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route exact path="messages" element={<MessagePortal/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route exact path="settings" element={<Settings/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route exact path="contacts" element={<Contacts/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route exact path="contact" element={<ContactInfo/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route exact path="contact/:contactId" element={<ContactInfo/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route exact path="calendar" element={<Calendar/>}/>}
            { (isUser || isReadOnly || isAdmin) && <Route exact path="notifications" element={<Notifications/>}/>}

            { isAdmin && <Route path="addexchange/:taxpayerId" element={<ExchangeInfo />}/>}
            { isAdmin && <Route exact path="tags" element={<SmartTags/>}/>}
            { isAdmin && <Route path="registration/:registrationId" element={<Registration/>}/>}
            { isAdmin && <Route exact path="emailtemplates" element={<EmailTemplate/>}/>}
            { isAdmin && <Route exact path="documentMappings" element={<DocumentMappings/>}/>}
            { isAdmin && <Route exact path="useradmin" element={<UserAdmin/>}/>}
            { isAdmin && <Route exact path={"orderregistrations"} element={<RegistrationAdmin/>}/>}
            { isAdmin && <Route exact path={"foldereditor"} element={<DocumentFolderEditor/>}/>}
            { isAdmin && <Route exact path={"info"} element={<Info/>}/>}
        </Routes>
    );

}

export default AppRoutes;
