import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {
    addProperty, removePropertyAddress,
    updateProperty, updatePropertyAddressProperty
} from "../../actions/exchange";

export default function PropertyAddress (props) {
    const dispatch = useDispatch();
    const address = useSelector(state => state.exchange.selectedPropertyAddress);
    const unitedStates = useSelector(state => state.unitedStates.fullNames);
    const { exchangeId, typeId, saveProperty, closeModal } = props;

    const savePropertyAddress = async function () {
        address.propertyTypeId = typeId;
        address.exchangeId = exchangeId;
        if (address.id === 0) {
            await dispatch(addProperty(address, exchangeId));
        } else {
            await dispatch(updateProperty(address, exchangeId));
        }
        saveProperty();
        closeModal();
    }

    const handleValueChange = function (e) {
        dispatch(updatePropertyAddressProperty(e.target.name, e.target.value));
    }

    const handleDeleteAddress = async function (e) {
        if (window.confirm("This address will be permanently deleted.  Are you sure?")){
            await dispatch(removePropertyAddress(address.id));
            closeModal();
        }

    }

    return (<div>
        <div className="row m-0 p-0 justify-content-center">
            <div className="col-10 float-end pt-1 pb-0">
                <div className={"row"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Address</label>
                    <input name="address1" className="form-control" onChange={handleValueChange} value={address.address1 ? address.address1 : ""}/>
                </div>
                <div className={"row"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Apt, Suite etc.</label>
                    <input name="address2" className="form-control" onChange={handleValueChange} value={address.address2 ? address.address2 : ""}/>
                </div>
                <div className={"row"}>
                    <div className={"col-4 m-0 p-0 me-4"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">City</label>
                        <input name="city" className="form-control" onChange={handleValueChange} value={address.city ? address.city : ""}/>
                    </div>
                    <div className={"col-4 m-0 p-0 ms-1"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">State</label>
                        <select name="state" className="form-select" onChange={handleValueChange} value={address.state ? address.state : ""}>
                            <option disabled value="">Select a State</option>
                            {unitedStates.map((us, idx) => <option key={idx} value={us}>{us}</option>)}
                        </select>
                    </div>
                    <div className={"col-3 m-0 p-0 ms-auto"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Postal Code</label>
                        <input name="postalCode" className="form-control" onChange={handleValueChange} value={address.postalCode ? address.postalCode : ""}/>
                    </div>
                </div>
                <div className={"row"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">County</label>
                    <input name="county" className="form-control" onChange={handleValueChange} value={address.county ? address.county : ""}/>
                </div>
                <div className={"row"}>
                    <label className="form-label small m-0 p-0 mt-2 text-secondary">Legal Description</label>
                    <textarea name="legalDescription" rows={5} className="form-control" onChange={handleValueChange} value={address.legalDescription ? address.legalDescription : ""}/>
                </div>
            </div>
        </div>
        <div className="card-footer p-0 m-0 border-0">
            <div className="row m-0 p-0">
                <div className="col-12 float-end pt-3 text-end pb-2">
                    <button className={address.id !== 0 ? "btn btn-outline-danger me-2" : "display-none"} title="Delete" onClick={handleDeleteAddress}><FontAwesomeIcon icon={faTimesCircle}/>&nbsp;Remove Address</button>
                    <button className={"btn btn-outline-primary me-2"} title="Save" onClick={savePropertyAddress}><FontAwesomeIcon icon={faSave}/>&nbsp;Save</button>
                    <button className={"btn btn-outline-secondary me-2"} title="Save" onClick={closeModal}><FontAwesomeIcon icon={faTimesCircle}/>&nbsp;Cancel</button>
                </div>
            </div>
        </div>
    </div>);
}