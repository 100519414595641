import React from "react";
import PropertyList from "./property-list";
import {useSelector} from "react-redux";
import {ExchangeContactList} from "./exchange-contact-list";

export const ExchangeOverview = (props) => {

    const { exchangeId } = props;
    const exchange = useSelector(state => state.exchange.currentExchange);

    return (<div className="card col-12 border-1 p-1 m-0 mt-2 border-dark shadow">
        <div style={{minHeight: "70vh"}} className={"m-0 p-2"}>
            <div className={"row text-center mb-3"}>
                <div className={"col-3"}>
                    <div className={"border border-1 rounded bg-primary text-white"}>
                        <div className={"fs-5"}>Open Date<br/>&nbsp;</div>
                        <div className={"fs-3"}>{exchange.openDate ? new Date(exchange.openDate).toLocaleDateString() : "Not Set"}</div>
                    </div>
                </div>
                <div className={"col-3"}>
                    <div className={"border border-1 rounded bg-primary text-white"}>
                        <div className={"fs-5"}>Close of Escrow/<br/>Funds Received</div>
                        <div className={"fs-3"}>{exchange.closeOfEscrowFundsReceived ? new Date(exchange.closeOfEscrowFundsReceived).toLocaleDateString() : "Not Set"}</div>
                    </div>
                </div>
                <div className={"col-3"}>
                    <div className={"border border-1 rounded bg-primary text-white"}>
                        <div className={"fs-5"}>45 Days<br/>&nbsp;</div>
                        <div className={"fs-3"}>{exchange.fortyFiveDays ? new Date(exchange.fortyFiveDays).toLocaleDateString() : "Not Set"}</div>
                    </div>
                </div>
                <div className={"col-3"}>
                    <div className={"border border-1 rounded bg-primary text-white"}>
                        <div className={"fs-5"}>180 Days<br/>&nbsp;</div>
                        <div className={"fs-3"}>{exchange.oneEightyDays ? new Date(exchange.oneEightyDays).toLocaleDateString() : "Not Set"}</div>
                    </div>
                </div>
            </div>
            <div className={"row text-center mb-3"}>
                <div className={"col-12"}>
                    <div className={"fs-3 text-end pe-5"}>Properties</div>
                    <PropertyList exchangeId={exchangeId} rowClickable={false}/>
                </div>
            </div>
            <div className={"row text-center mb-3"}>
                <div className={"col-12"}>
                    <div className={"fs-3 text-end pe-5"}>Contacts</div>
                    <ExchangeContactList exchangeId={exchangeId}/>
                </div>
            </div>
        </div>
    </div>);
}