import {
    CLEAR_SESSION, INSERT_SETTING, LOAD_SETTINGS,
    SET_TOKEN, SET_USER_ROLES, UPDATE_SETTING
} from './action-types';
import {baseUrl} from "../constants/settings";
import toast from "react-hot-toast";
import {UserApi, UserSettingApi} from "../api";

const userSettingApi = new UserSettingApi(undefined, baseUrl);
const userApi = new UserApi(undefined, baseUrl);

export const clearSession = () => {
    return dispatch => {
        dispatch({type: CLEAR_SESSION});
    }
}

export const setToken = token => {
    return dispatch => {
        dispatch({type: SET_TOKEN,
            payload: token});
    }
}

export const loadUserSettings = function() {
    return async dispatch => {
        try {
            const response = await userSettingApi.apiUserSettingGet();
            dispatch({ type: LOAD_SETTINGS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const insertUserSetting = function(setting) {
    return async dispatch => {
        try {
            const response = await userSettingApi.apiUserSettingPost(setting);
            setting.id = response.data;
            dispatch({ type: INSERT_SETTING, payload: setting });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const updateUserSetting = function(setting) {
    return async dispatch => {
        try {
            // eslint-disable-next-line
            const response = await userSettingApi.apiUserSettingUpdatePost(setting);
            dispatch({ type: UPDATE_SETTING, payload: setting });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const setUserRoles = function(navigate) {
    return async dispatch => {
        try {
            const response = await userApi.apiUserAssignedRolesGet()
            dispatch({type: SET_USER_ROLES, payload: response.data});
        } catch(err) {
            if(err.response.status !== 403){
                toast.error(err.message);
            } else {
                navigate("noaccount");
            }
            console.log(err);
        }

    }
}
