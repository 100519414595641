import {ListApi, MappingOptionApi} from "../api";
import {baseUrl} from "../constants/settings";
import {
    ADD_SMART_TAG,
    CLEAR_SELECTED_SMART_TAG, DELETE_SMART_TAG,
    HIDE_SMART_TAG_MODAL, LOAD_MAPPING_OBJECT_TYPES,
    LOAD_SMART_TAGS,
    SELECT_SMART_TAG,
    SHOW_SMART_TAG_MODAL, UPDATE_SELECTED_SMART_TAG_PROPERTY, UPDATE_SMART_TAG
} from "./action-types";
import toast from "react-hot-toast";

const smartTagApi = new MappingOptionApi(undefined, baseUrl);
const listApi = new ListApi(undefined, baseUrl);

export const loadAllSmartTags = function() {
    return async dispatch => {
        try
        {
            const response = await smartTagApi.apiMappingOptionGet();
            dispatch({ type: LOAD_SMART_TAGS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const showSmartTagModal = function () {
    return async dispatch => {
        dispatch({ type: SHOW_SMART_TAG_MODAL });
    }
}

export const hideSmartTagModal = function () {
    return async dispatch => {
        dispatch({ type: HIDE_SMART_TAG_MODAL });
    }
}

export const clearSelectedSmartTag = function () {
    return async dispatch => {
        dispatch({ type: CLEAR_SELECTED_SMART_TAG });
    }
}

export const selectSmartTag = function (tag) {
    return async dispatch => {
        dispatch({ type: SELECT_SMART_TAG, payload: tag });
    }
}

export const updateSelectedSmartTagProperty = function (property, value) {
    return async dispatch => {
        dispatch({ type: UPDATE_SELECTED_SMART_TAG_PROPERTY, payload: {property, value} });
    }
}

export const loadMappingObjectTypes = function() {
    return async dispatch => {
        try
        {
            const response = await listApi.apiListMappingObjectsGet();
            dispatch({ type: LOAD_MAPPING_OBJECT_TYPES, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const updateSmartTag = function(tag) {
    return async dispatch => {
        try
        {
            await smartTagApi.apiMappingOptionUpdatePost(tag);
            dispatch({ type: UPDATE_SMART_TAG });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const addSmartTag = function(tag) {
    return async dispatch => {
        try
        {
            await smartTagApi.apiMappingOptionPost(tag);
            dispatch({ type: ADD_SMART_TAG });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const deleteSmartTag = function(tag) {
    return async dispatch => {
        try
        {
            await smartTagApi.apiMappingOptionDeleteGet(tag.id);
            dispatch({ type: DELETE_SMART_TAG });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}