import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    downloadFile,
    loadDocumentMappings,
    saveDocumentMapping,
    updateDocumentMappingField
} from "../../actions/document-mappings";
import AttachedDocument from "./attachment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-regular-svg-icons";

export default function DocumentBody () {

    const dispatch = useDispatch();
    const currentDocument = useSelector(state => state.documentMappings.currentDocument);
    const handleFieldChange = function (e) {
        dispatch(updateDocumentMappingField(e.target.name, e.target.value));
    }

    const save = async function (){
        await dispatch(saveDocumentMapping());
        await dispatch(loadDocumentMappings());
    }

    const downloadCopy = async function (mappingId, filename) {
        await dispatch(downloadFile(mappingId, filename));
    }

    return (<div>
        <div className={"row p-0 m-0 mb-1"}>
            <div className={"col-12"}>
                <label className={"form-label"}>Name</label>
                <input className={"form-control"} name={"name"} value={currentDocument.name} onChange={handleFieldChange}/>
            </div>
        </div>
        <div className={"row p-0 m-0 mb-1"}>
            <div className={"col-12"}>
                <label className={"form-label"}>Description</label>
                <textarea rows={3} className={"form-control"} name={"description"} value={currentDocument.description} onChange={handleFieldChange}/>
            </div>
        </div>
        <div className={"row p-0 m-0"}>
            <div className={"col-12"}>
                <div className={"row m-0 p-0"}>
                    <div className={"col-4 m-0 p-0"}><AttachedDocument/></div>
                    <div className={"col-6 m-0 pt-3 text-center fw-bold font-small"}>
                        <button className={"btn btn-link text-dark"} onClick={() => downloadCopy(currentDocument.id, currentDocument.fileName)}>{currentDocument.fileName.substring(currentDocument.fileName.lastIndexOf("/") + 1)}</button>
                    </div>
                    <div className={"col-2 m-0 p-0 text-end mt-3"}><button className={"btn btn-outline-dark"} title="Save" onClick={save}><FontAwesomeIcon icon={faSave}/>&nbsp;Save</button></div>
                </div>

            </div>
        </div>
    </div>);
}