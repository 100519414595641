import React, {useEffect} from 'react';
import {AgGridReact} from "ag-grid-react";
import {useDispatch, useSelector} from "react-redux";
import {defaultColDef, defaultRow} from "../../constants/settings";
import {loadContactsForExchange} from "../../actions/exchange";

export const ExchangeContactList = (props) => {

    const dispatch = useDispatch();
    const contacts = useSelector(state => state.exchange.contacts);
    const {exchangeId} = props;

    const columnDefs = [
        {
            headerName: 'Type',
            field: 'type',
        },
        {
            headerName: 'Name',
            cellRenderer: 'renderName',
            field: 'firstName',
        },
        {
            headerName: 'Phone',
            cellRenderer: 'renderPhone',
            field: 'workPhone',
        },
        {
            headerName: 'Email',
            cellRenderer: 'renderEmail',
            field: 'emailAddress',
        }
    ];

    useEffect(() => {
        dispatch(loadContactsForExchange(exchangeId));
    }, [dispatch, exchangeId]);

    const renderName = function (ex) {
        const data = ex.data;
        if(data.companyName) return <div>{data.companyName}</div>;
        if(!data.firstName && !data.lastName) return <div>No Name Entered</div>
        return <div>{data.firstName + " " + data.lastName}</div>
    }

    const renderPhone = function (ex) {
        const data = ex.data;
        if (data.workPhone) return <div>{data.workPhone}</div>
        if (data.homePhone) return <div>{data.homePhone}</div>
        if (data.mobilePhone) return <div>{data.mobilePhone}</div>
        return <div className={"opacity-25"}>No Phone</div>
    }

    const renderEmail = function (ex) {
        const data = ex.data;
        if (data.emailAddress) return <div>{data.emailAddress}</div>
        return <div className={"opacity-25"}>No Email</div>
    }

    return (<div className="card border-1 border-dark shadow">
                    <div className={defaultRow}>
                        <div className={"col-12 text-start"}>
                            <div className={"ag-theme-alpine"}  style={{height: '30vh'}}>
                                <AgGridReact
                                    components={{
                                        renderName,
                                        renderPhone,
                                        renderEmail
                                    }}
                                    defaultColDef={defaultColDef}
                                    suppressCellFocus={true}
                                    rowData={contacts}
                                    columnDefs={columnDefs}>
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
    </div>);
}