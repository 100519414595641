import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
const Loading = () => (
    <div className={"pt-5 text-center text-secondary"}>
        <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} size={'4x'} />
    </div>
);

export default Loading;
