import {baseUrl} from "../constants/settings";
import {
    CLEAR_SELECTED_FIELD_MAPPING,
    CLEAR_SELECTED_MAPPING,
    DOWNLOAD_DOCUMENT,
    GENERATE_DOCUMENT,
    LOAD_CLASS_MAPPING_FIELDS,
    LOAD_DOCUMENT_FIELDS,
    LOAD_DOCUMENT_MAPPINGS,
    LOAD_FIELD_MAPPINGS,
    REMOVE_DOCUMENT_MAPPING,
    REMOVE_FIELD_MAPPING,
    SAVE_DOCUMENT_MAPPING,
    SAVE_FIELD_MAPPING,
    SELECT_DOCUMENT_MAPPING, SELECT_FIELD_MAPPING,
    UPDATE_CURRENT_FIELD_MAPPING,
    UPDATE_DOCUMENT_FIELD
} from "./action-types";
import toast from "react-hot-toast";
import {DocumentMappingApi, FieldMappingApi} from "../api";

const documentMappingApi = new DocumentMappingApi(undefined, baseUrl);
const fieldMappingApi = new FieldMappingApi(undefined, baseUrl);

export const loadDocumentMappings = function() {
    return async dispatch => {
        try
        {
            const response = await documentMappingApi.apiDocumentMappingGet();
            dispatch({ type: LOAD_DOCUMENT_MAPPINGS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const saveDocumentMapping = function() {
    return async (dispatch, getState) => {
        try{
            const mapping = getState().documentMappings.currentDocument;
            let response;
            if (mapping.id > 0)
                response = await documentMappingApi.apiDocumentMappingUpdatePost(mapping);
            else
                response = await documentMappingApi.apiDocumentMappingAddPost(mapping);
            mapping.id = response.data;
            toast.success("Saved Successfully");
            dispatch({ type: SAVE_DOCUMENT_MAPPING, payload: mapping });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const updateDocumentMappingField = function (field, value) {
    return dispatch => {
        dispatch({type: UPDATE_DOCUMENT_FIELD, payload : {field, value}});
    }
}

export const selectDocumentMapping = function (mappingId) {
    return dispatch => {
        dispatch({type: SELECT_DOCUMENT_MAPPING, payload: mappingId});
    }
}

export const clearSelectedMapping = function () {
    return dispatch => {
        dispatch({type: CLEAR_SELECTED_MAPPING});
    }
}

export const removeDocumentMapping = function(documentId) {
    return async dispatch => {
        try {
            // eslint-disable-next-line
            const response = await documentMappingApi.apiDocumentMappingRemoveDocumentMappingIdGet(documentId);
            dispatch({ type: REMOVE_DOCUMENT_MAPPING, payload: documentId });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadFieldMappings = function(documentId) {
    return async dispatch => {
        try {
            const response = await fieldMappingApi.apiFieldMappingDocumentIdGet(documentId);
            dispatch({ type: LOAD_FIELD_MAPPINGS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const updateCurrentFieldMapping = function (field, value) {
    return dispatch => {
        dispatch({type: UPDATE_CURRENT_FIELD_MAPPING, payload : {field, value}});
    }
}

export const saveFieldMapping = function() {
    return async (dispatch, getState) => {
        try {
            let response;
            const mapping = getState().documentMappings.currentDocument;
            const fieldMapping = getState().documentMappings.currentField;
            fieldMapping.documentMappingId = mapping.id;
            if (fieldMapping.id > 0) {
                response = await fieldMappingApi.apiFieldMappingUpdatePost(fieldMapping);
            }
            else {
                const markup = getState().markup;
                fieldMapping.x = markup.startPosition.x;
                fieldMapping.y = markup.startPosition.y;
                fieldMapping.width = markup.rectangleSize.width;
                fieldMapping.height = markup.rectangleSize.height;
                fieldMapping.scaleWidth = markup.pdfCanvas.width * markup.multiplier;
                fieldMapping.scaleHeight = markup.pdfCanvas.height * markup.multiplier;
                fieldMapping.pageNumber = markup.pageNumber;
                response = await fieldMappingApi.apiFieldMappingAddPost(fieldMapping);
                fieldMapping.id = response.data;
            }

            toast.success("Saved Successfully");
            dispatch({ type: SAVE_FIELD_MAPPING, payload: fieldMapping });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const clearFieldMappingForm = function () {
    return dispatch => {
        dispatch({type: CLEAR_SELECTED_FIELD_MAPPING});
    }
}

export const removeFieldMapping = function(fieldId) {
    return async (dispatch) => {
        try {
            // eslint-disable-next-line
            const response = await fieldMappingApi.apiFieldMappingRemoveFieldMappingIdGet(fieldId);
            toast.success("Field Removed");
            dispatch({ type: REMOVE_FIELD_MAPPING, payload: fieldId });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}
//Obsolete
export const loadDocumentFields = function(documentMappingId) {
    return async dispatch => {
        try {
            const response = await documentMappingApi.apiDocumentMappingDocumentFieldsDocumentMappingIdGet(documentMappingId);
            dispatch({ type: LOAD_DOCUMENT_FIELDS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadClassMappingFields = function(className) {
    return async dispatch => {
        try {
            const response = await documentMappingApi.apiDocumentMappingClassFieldsClassNameGet(className);
            dispatch({ type: LOAD_CLASS_MAPPING_FIELDS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const generateDocument = function(documentMappingId, exchangeId, propertyAddressId) {
    return async (dispatch, getState) => {
        try {
            const folderId = getState().exchangeAttachment.selectedFolder.id;
            const response = await documentMappingApi.apiDocumentMappingGenerateExchangeGet(documentMappingId, exchangeId, folderId, propertyAddressId);
            dispatch({ type: GENERATE_DOCUMENT, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const arrayBufferToBase64 = function( buffer ) {
    let binary = '';
    let bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export const downloadDocument = function (mappingId){
    return async dispatch => {
        try {
            const response = await documentMappingApi.apiDocumentMappingDownloadGet(mappingId, {responseType: 'blob'});
            const blob = await response.data;
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                const base64String = reader.result;
                dispatch({ type: DOWNLOAD_DOCUMENT, payload: base64String });
            }

        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}
export const downloadFile = function (mappingId, fileName){
    return async dispatch => {
        try {
            const response = await documentMappingApi.apiDocumentMappingDownloadGet(mappingId, {responseType: 'blob'});
            const blob = await response.data;
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            dispatch({ type: DOWNLOAD_DOCUMENT, payload:"" });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}


export const selectFieldMapping = function (mapping) {
    return dispatch => {
        dispatch({type: SELECT_FIELD_MAPPING, payload: mapping});
    }
}
