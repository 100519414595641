import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";
import {UserGrid} from "./user-grid";
import {UserDetail} from "./user-detail";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {defaultRow} from "../../constants/settings";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {clearUserForm, toggleUserModal} from "../../actions/user-admin";
import Popup from "reactjs-popup";

const UserAdmin = () => {

    const dispatch = useDispatch();
    const showModal = useSelector(state => state.userAdmin.showModal);
    const clearUser = async function () {
        await dispatch(clearUserForm());
        await dispatch(toggleUserModal(true));
    }

    const closeModal = async function () {
        await dispatch(toggleUserModal(false));
    }

    return (
        <div className="card border-0 shadow my-5 p-2">
            <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
                <div className="row m-0 p-0">
                    <div className="col-1 p-0 pt-3 pt-2 header-column">
                        <h1 className="m-0 p-0 py-2 px-4">Users</h1>
                    </div>
                    <div className="col-11 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                        <div className={defaultRow}>
                            <div className={"col-12 text-end"}>
                                <button className={"btn btn-outline-dark p-1 m-0"} title={"Add User"} onClick={clearUser}><FontAwesomeIcon icon={faPlus}/>&nbsp;Add User</button>
                            </div>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0"}>
                                <UserGrid/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popup open={showModal} closeOnDocumentClick onClose={closeModal}>
                <UserDetail/>
            </Popup>
        </div>
    )
}


export default withAuthenticationRequired(UserAdmin, {
    onRedirecting: () => <Loading />,
});