import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {updateExchange, updateExchangeProperty} from "../../actions/exchange";
import DatePicker from "react-datepicker";
import CurrencyFormat from "../../utilities/currency-format";

export default function Exchange (props) {
    const dispatch = useDispatch();
    const exchangeData = useSelector(state => state.exchange.currentExchange);
    const exchangeTypes = useSelector(state => state.exchange.exchangeTypes);

    const saveExchange = async function () {
        await dispatch(updateExchange(exchangeData));
    }

    const handleValueChange = function (e) {
        let value = e.target.value;
        if (e.target.type === "checkbox") value = e.target.checked;
        dispatch(updateExchangeProperty(e.target.name, value));
    }

    const handleCurrencyChange = function (field, values){
        const {value} = values;
        dispatch(updateExchangeProperty(field, value));
    }

    const handleDateChange = function(name, value) {
        if (name === "closeOfEscrowFundsReceived"
            && !exchangeData.fortyFiveDays
            && !exchangeData.oneEightyDays){
            dispatch(updateExchangeProperty("fortyFiveDays", new Date(value).addDays(45)));
            dispatch(updateExchangeProperty("oneEightyDays", new Date(value).addDays(180)));
        }
        dispatch(updateExchangeProperty(name, value))
    }

    return (<div className="card col-12 border-1 p-0 m-0 mt-2 border-dark shadow">
        <div className="row p-0 m-0">
            <div className="col-12">
                <div className={"row m-0 p-0"}>
                    <div className={"col-6"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Order Number</label>
                        <input name="exchangeId" className="form-control" onChange={handleValueChange} value={exchangeData.exchangeId ? exchangeData.exchangeId : ""}/>
                    </div>
                    <div className={"col-6"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Exchange Type</label>
                        <select name="exchangeTypeId" className="form-select" onChange={handleValueChange} value={exchangeData.exchangeTypeId ? exchangeData.exchangeTypeId : ""}>
                            <option disabled value="">Select an Exchange Type</option>
                            {exchangeTypes.map((ex, idx) => <option key={idx} value={ex.id}>{ex.name}</option>)}
                        </select>
                    </div>

                </div>
                <div className={"row m-0 p-0"}>
                    <div className={"col-6"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Exchange Officer</label>
                        <input name="exchangeOfficer" className="form-control" onChange={handleValueChange} value={exchangeData.exchangeOfficer ? exchangeData.exchangeOfficer : ""}/>
                    </div>
                    <div className={"col-6"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Agency</label>
                        <input name="agency" className="form-control" onChange={handleValueChange} value={exchangeData.agency ? exchangeData.agency : ""}/>
                    </div>
                </div>
                <hr/>
                <div className={"row m-0 p-0"}>
                    <div className={"col-3"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Open Date</label>
                        <DatePicker className="form-control" dateFormat="MM/dd/yyyy" selected={exchangeData.openDate && new Date(exchangeData.openDate)} onChange={(e) => handleDateChange("openDate", e)} />
                    </div>
                    <div className={"col-3"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Close of Escrow/Funds Received</label>
                        <DatePicker className="form-control" dateFormat="MM/dd/yyyy" selected={exchangeData.closeOfEscrowFundsReceived && new Date(exchangeData.closeOfEscrowFundsReceived)} onChange={(e) => handleDateChange("closeOfEscrowFundsReceived", e)} />
                    </div>
                    <div className={"col-3"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">45 Days</label>
                        <DatePicker className="form-control" dateFormat="MM/dd/yyyy" selected={exchangeData.fortyFiveDays && new Date(exchangeData.fortyFiveDays)} onChange={(e) => handleDateChange("fortyFiveDays", e)} />
                    </div>
                    <div className={"col-3"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">180 Days</label>
                        <DatePicker className="form-control" dateFormat="MM/dd/yyyy" selected={exchangeData.oneEightyDays && new Date(exchangeData.oneEightyDays)} onChange={(e) => handleDateChange("oneEightyDays", e)} />
                    </div>
                </div>
                <hr/>
                <div className={"row m-0 p-0"}>
                    <div className={"col-3"}>
                        <label className="form-label small m-0 p-0 mt-2 text-secondary">Amount Received From Sale</label>
                        <CurrencyFormat decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} name="amountReceived" className="form-control" onValueChange={(values) => handleCurrencyChange("amountReceived", values)} value={exchangeData.amountReceived ? exchangeData.amountReceived : ""}/>
                    </div>
                    <div className={"col-3 pt-3"}>
                        <label className="form-label small m-0 p-0 mt-4 text-secondary">
                            <input type={"checkbox"} name="interestAccountOpened" className="form-check-input" onChange={handleValueChange} checked={exchangeData.interestAccountOpened ? exchangeData.interestAccountOpened : false}/>
                            &nbsp;Interest Account Opened
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className="card-footer p-0 m-0 pt-2 border-0">
            <div className="row m-0 p-0">
                <div className="col-12 float-end pt-3 text-end pb-2">
                    <button className={"btn btn-outline-dark me-2"} title="Save" onClick={saveExchange}><FontAwesomeIcon icon={faSave}/>&nbsp;Save</button>
                </div>
            </div>
        </div>
    </div>);
}