import React, {useEffect} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useDispatch, useSelector} from "react-redux";
import {
    clearSelectedTemplate,
    loadEmailTemplates, saveEmailTemplate,
    selectTemplate,
    updateTemplateField
} from "../../actions/email-template";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-regular-svg-icons";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";

export const EmailTemplate = function () {

    const dispatch = useDispatch();
    const currentTemplate = useSelector(state => state.emailTemplate.currentTemplate);
    const templates = useSelector(state => state.emailTemplate.templates);

    useEffect(() => {
        dispatch(clearSelectedTemplate());
        dispatch(loadEmailTemplates());
    }, [dispatch])

    const bodyChanged = function (content) {
        dispatch(updateTemplateField("template", content));
    }

    const fieldChanged = function (e) {
        dispatch(updateTemplateField(e.target.name, e.target.value));
    }

    const editTemplate = function (templateId) {
        dispatch(selectTemplate(templateId));
    }

    const newTemplate = function () {
        dispatch(clearSelectedTemplate());
    }

    const saveTemplate = function () {
        dispatch(saveEmailTemplate(currentTemplate))
    }

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="row m-0 p-0 mb-1">
            <div className="card col-12 float-end border-1 border-dark blue-body"><h4>Email Templates</h4></div>
        </div>
        <div className={"row m-0 p-0"}>
            <div className={"col-12 card border-1 p-0 m-0 border-dark shadow"}>
                <div className={"row p-0 m-0"}>
                    <div className="col-2 p-0 pt-3 menu-column">
                        <div className={"row p-0 m-0 pb-3 text-end"}>
                            <div className={"col-12"}>
                                <button className={!currentTemplate.id ? "visually-hidden" : "btn btn-outline-dark m-0 p-0 px-1"} onClick={newTemplate} title={"New Template"}><FontAwesomeIcon icon={faPlus} />&nbsp;New Template</button>
                            </div>
                        </div>

                        {templates.map((t, idx) => <div className={"text-center"} key={idx}><button className={t.id === currentTemplate.id ? "btn btn-link text-dark fw-bold text-decoration-none" : "btn btn-link text-dark text-decoration-none"} onClick={() => editTemplate(t.id)}>{t.name}</button></div> )}
                    </div>
                    <div className="col-10 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                        <div className={"row m-0 mt-2 p-0"}>
                            <div className={"col-1 m-0 p-0 pe-2 pt-1 text-end"}>
                                <label className={"form-label small"}>Name</label>
                            </div>
                            <div className={"col-11 m-0 p-0 text-end"}>
                                <input className={"form-control"} name={"name"} value={currentTemplate.name} onChange={fieldChanged} disabled={currentTemplate.id ? true : false}/>
                            </div>
                        </div>
                        <div className={"row m-0 mt-2 p-0"}>
                            <div className={"col-1 m-0 p-0 pe-2 pt-1 text-end"}>
                                <label className={"form-label small"}>Description</label>
                            </div>
                            <div className={"col-11 m-0 p-0 text-end"}>
                                <textarea rows={2} className={"form-control"} name={"description"} value={currentTemplate.description} onChange={fieldChanged} />
                            </div>
                        </div>
                        <div className={"row m-0 mt-2 p-0"}>
                            <div className={"col-1 m-0 p-0 pe-2 pt-1 text-end"}>
                                <label className={"form-label small"}>Subject</label>
                            </div>
                            <div className={"col-11 m-0 p-0 text-end"}>
                                <input className={"form-control"} name={"subjectTemplate"} value={currentTemplate.subjectTemplate} onChange={fieldChanged} />
                            </div>
                        </div>
                        <div className={"row m-0 mt-2 p-0"}>
                            <div className={"col-1 m-0 p-0 pe-2 pt-1 text-end"}>
                                <label className={"form-label small"}>Body</label>
                            </div>
                            <div className={"col-11 m-0 mb-2 p-0 text-end"}>
                                <ReactQuill className={"form-control m-0 p-0"} theme="snow" value={currentTemplate.template} onChange={bodyChanged}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-2 p-0 m-0 menu-column">&nbsp;</div>
                    <div className="col-10 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-3 text-end pb-2">
                        <button className={"btn btn-outline-dark"} title="Save" onClick={saveTemplate}><FontAwesomeIcon icon={faSave}/>&nbsp;Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(EmailTemplate, {
    onRedirecting: () => <Loading />,
});