import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    convertRegistration,
    loadRegistrationById,
    submitRegistration,
    updateRegistrationField
} from "../../actions/registration";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import DatePicker from "react-datepicker";
const Registration = function (props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registration = useSelector(state => state.registration.orderRegistration);
    const unitedStates = useSelector(state => state.unitedStates.states);
    const exchangeId = useSelector(state => state.registration.exchangeId);
    const [showModal, setShowModal] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const {registrationId} = useParams();
    const {isAuthenticated} = useAuth0();

    useEffect(() => {
        async function loadRegistration() {
            await dispatch(loadRegistrationById(registrationId));
        }
        if (isAuthenticated && exchangeId === 0){
            loadRegistration();
        }
        if (isAuthenticated && exchangeId > 0) {
            navigate("/order/" + exchangeId + "/detail");
        }
    }, [dispatch, isAuthenticated, registrationId, exchangeId, navigate]);

    const handleFieldChange = async function (e) {
        await dispatch(updateRegistrationField(e.target.name, e.target.value));
    }

    const handleSubmitForm = async function (e) {
        setSubmitting(true);
        await dispatch(submitRegistration(registration));
        setSubmitting(false);
    }

    const handleConvertToExchange = async function() {
        setSubmitting(true);
        await dispatch(convertRegistration(registration));
        setShowModal(false);
        setSubmitting(false);
    }

    const handleDateChange = function(name, value) {
        dispatch(updateRegistrationField(name, value))
    }

    return (<div className="card border-1 shadow my-5 col container-card p-2">

        <div className="row p-0 m-0 mt-2">
            <div className={"col-8"}><h4>{isAuthenticated ? "Order Registration" : "Exchange Request"}</h4></div>
            { isAuthenticated ? <div className={"col-4 text-end"}><Link className={"btn btn-link text-dark"} to={"/orderregistrations"}><FontAwesomeIcon icon={faChevronCircleLeft}/> Back to Registrations</Link></div> : <div>&nbsp;</div> }
        </div>
        <div className="row p-0 m-0 mt-2">
            <div className={"col"}>
                <div className={"row"}>
                    <div className="col m-2 p-4 card">
                        <h5 className={"text-end"}>Exchanger</h5>
                        <div className="mb-1">
                            <label className="form-label">Entity</label>
                            <input name="exchangerEntity" className="form-control form-control-sm" value={registration.exchangerEntity} onChange={handleFieldChange}/>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Name</label>
                            <input name="exchangerName" className="form-control form-control-sm" value={registration.exchangerName} onChange={handleFieldChange}/>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Address</label>
                            <input name="exchangerAddress" className="form-control form-control-sm" value={registration.exchangerAddress} onChange={handleFieldChange}/>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">City</label>
                                    <input name="exchangerCity" className="form-control form-control-sm" value={registration.exchangerCity} onChange={handleFieldChange}/>
                                </div>
                            </div>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">State</label>
                                    <select name="exchangerState" className="form-select form-select-sm" onChange={handleFieldChange} value={registration.exchangerState}>
                                        <option disabled value="">Select a State</option>
                                        {unitedStates.map((us, idx) => <option key={idx} value={us.name}>{us.name}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className={"col m-0 p-0"}>
                                <div className="mb-1">
                                    <label className="form-label">Zip</label>
                                    <input name="exchangerZip" className="form-control form-control-sm" value={registration.exchangerZip} onChange={handleFieldChange}/>
                                </div>
                            </div>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">Phone</label>
                                    <input name="exchangerPhone" className="form-control form-control-sm" value={registration.exchangerPhone} onChange={handleFieldChange}/>
                                </div>
                            </div>
                            <div className={"col m-0 p-0"}>
                                <div className="mb-1">
                                    <label className="form-label">Fax</label>
                                    <input name="exchangerFax" className="form-control form-control-sm" value={registration.exchangerFax} onChange={handleFieldChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Email</label>
                            <input name="exchangerEmail" className="form-control form-control-sm" value={registration.exchangerEmail} onChange={handleFieldChange}/>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col m-2 p-4 card">
                        <h5 className={"text-end"}>Buyer</h5>
                        <div className="mb-1">
                            <label className="form-label">Entity</label>
                            <input name="buyerEntity" className="form-control form-control-sm" value={registration.buyerEntity} onChange={handleFieldChange}/>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Name</label>
                            <input name="buyerName" className="form-control form-control-sm" value={registration.buyerName} onChange={handleFieldChange}/>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Address</label>
                            <input name="buyerAddress" className="form-control form-control-sm" value={registration.buyerAddress} onChange={handleFieldChange}/>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">City</label>
                                    <input name="buyerCity" className="form-control form-control-sm" value={registration.buyerCity} onChange={handleFieldChange}/>
                                </div>
                            </div>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">State</label>
                                    <select name="buyerState" className="form-select form-select-sm" onChange={handleFieldChange} value={registration.buyerState}>
                                        <option disabled value="">Select a State</option>
                                        {unitedStates.map((us, idx) => <option key={idx} value={us.name}>{us.name}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className={"col m-0 p-0"}>
                                <div className="mb-1">
                                    <label className="form-label">Zip</label>
                                    <input name="buyerZip" className="form-control form-control-sm" value={registration.buyerZip} onChange={handleFieldChange}/>
                                </div>
                            </div>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">Phone</label>
                                    <input name="buyerPhone" className="form-control form-control-sm" value={registration.buyerPhone} onChange={handleFieldChange}/>
                                </div>
                            </div>
                            <div className={"col m-0 p-0"}>
                                <div className="mb-1">
                                    <label className="form-label">Fax</label>
                                    <input name="buyerFax" className="form-control form-control-sm" value={registration.buyerFax} onChange={handleFieldChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Email</label>
                            <input name="buyerEmail" className="form-control form-control-sm" value={registration.buyerEmail} onChange={handleFieldChange}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"col"}>
                <div className={"row"}>
                    <div className="col m-2 p-4 card">
                        <h5 className={"text-end"}>Relinquished Property</h5>
                        <div className="mb-1">
                            <label className="form-label">Street Address</label>
                            <input name="relinquishedStreetAddress" className="form-control form-control-sm" value={registration.relinquishedStreetAddress} onChange={handleFieldChange}/>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col-8 m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">City</label>
                                    <input name="relinquishedCity" className="form-control form-control-sm" value={registration.relinquishedCity} onChange={handleFieldChange}/>
                                </div>
                            </div>
                            <div className={"col m-0 p-0"}>
                                <div className="mb-1">
                                    <label className="form-label">State</label>
                                    <select name="relinquishedState" className="form-select form-select-sm" onChange={handleFieldChange} value={registration.relinquishedState}>
                                        <option disabled value="">Select a State</option>
                                        {unitedStates.map((us, idx) => <option key={idx} value={us.name}>{us.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">Percent Ownership</label>
                                    <input type="number" name="relinquishedPercentOwnership" className="form-control form-control-sm" value={registration.relinquishedPercentOwnership} onChange={handleFieldChange}/>
                                </div>
                            </div>
                            <div className={"col m-0 p-0"}>
                                <div className="mb-1">
                                    <label className="form-label">Sale Price</label>
                                    <input type="number" step=".01" name="relinquishedSalePrice" className="form-control form-control-sm" value={registration.relinquishedSalePrice} onChange={handleFieldChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col m-2 p-4 card">
                        <h5 className={"text-end"}>Settlement Agency</h5>
                        <div className="mb-1">
                            <label className="form-label">Settlement Agent</label>
                            <input name="settlementAgentName" className="form-control form-control-sm" value={registration.settlementAgentName} onChange={handleFieldChange}/>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Agency</label>
                            <input name="settlementAgency" className="form-control form-control-sm" value={registration.settlementAgency} onChange={handleFieldChange}/>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Address</label>
                            <input name="settlementAgencyAddress" className="form-control form-control-sm" value={registration.settlementAgencyAddress} onChange={handleFieldChange}/>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">City</label>
                                    <input name="settlementAgencyCity" className="form-control form-control-sm" value={registration.settlementAgencyCity} onChange={handleFieldChange}/>
                                </div>
                            </div>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">State</label>
                                    <select name="settlementAgencyState" className="form-select form-select-sm" onChange={handleFieldChange} value={registration.settlementAgencyState}>
                                        <option disabled value="">Select a State</option>
                                        {unitedStates.map((us, idx) => <option key={idx} value={us.name}>{us.name}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className={"col m-0 p-0"}>
                                <div className="mb-1">
                                    <label className="form-label">Zip</label>
                                    <input name="settlementAgencyZip" className="form-control form-control-sm" value={registration.settlementAgencyZip} onChange={handleFieldChange}/>
                                </div>
                            </div>
                        </div>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0 me-1"}>
                                <div className="mb-1">
                                    <label className="form-label">Phone</label>
                                    <input name="settlementAgencyPhone" className="form-control form-control-sm" value={registration.settlementAgencyPhone} onChange={handleFieldChange}/>
                                </div>
                            </div>
                            <div className={"col m-0 p-0"}>
                                <div className="mb-1">
                                    <label className="form-label">Email</label>
                                    <input name="settlementAgencyEmail" className="form-control form-control-sm" value={registration.settlementAgencyEmail} onChange={handleFieldChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <label className="form-label">Escrow Number</label>
                            <input name="escrowNumber" className="form-control form-control-sm" value={registration.escrowNumber} onChange={handleFieldChange}/>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col text-end"}>
                        {isAuthenticated ? <button type="button" className={"btn btn-sm btn-outline-primary me-2"} onClick={() => setShowModal(true)}>Convert to Order</button> : <span>&nbsp;</span>}
                        <button type="button" className={ submitting ? "btn btn-sm btn-outline-primary btn-disabled" : "btn btn-sm btn-outline-primary"} onClick={handleSubmitForm}>{submitting ? "Saving Changes" : registration.id > 0 ? "Save Changes" : "Save"}</button>
                    </div>
                </div>
            </div>
        </div>
        <Popup open={showModal} closeOnDocumentClick onClose={() => setShowModal(false)}>
            <div className={"tag-modal"}>
                <button className="btn btn-link link-dark text-decoration-none m-0 p-0 close" onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimesCircle}/>
                </button>
                <div className="header">Create Order</div>
                <div className="content">
                    <div className={"row m-0 p-0"}>
                        <div className={"col m-0 p-0 me-3"}>
                            <div className="mb-1">
                                <label className="form-label">Exchange Number</label>
                                <input name="exchangeNumber" className="form-control form-control-sm" value={registration.exchangeNumber || ""} onChange={handleFieldChange}/>
                            </div>
                        </div>
                        <div className={"col m-0 p-0"}>
                            <div className="mb-1">
                                <label className="form-label">Agreement Date</label>
                                <DatePicker className="form-control form-control-sm" dateFormat="MM/dd/yyyy" selected={(registration.agreementDate && new Date(registration.agreementDate))} onChange={(e) => handleDateChange("agreementDate", e)} />
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col text-end"}>
                            <button type="button" className={ submitting ? "btn btn-sm btn-outline-primary me-2 bnt-disabled" : "btn btn-sm btn-outline-primary me-2"} onClick={handleConvertToExchange}>{submitting ? "Converting" : "Convert to Order"}</button>
                            <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    </div>);
}

export default Registration;