import {
    CLEAR_SELECTED_FIELD_MAPPING,
    CLEAR_SELECTED_MAPPING, DOWNLOAD_DOCUMENT, LOAD_CLASS_MAPPING_FIELDS, LOAD_DOCUMENT_FIELDS,
    LOAD_DOCUMENT_MAPPINGS,
    LOAD_FIELD_MAPPINGS,
    LOG_OUT,
    REMOVE_DOCUMENT_MAPPING, REMOVE_FIELD_MAPPING,
    SAVE_DOCUMENT_MAPPING, SAVE_FIELD_MAPPING,
    SELECT_DOCUMENT_MAPPING, SELECT_FIELD_MAPPING,
    UPDATE_CURRENT_FIELD_MAPPING,
    UPDATE_DOCUMENT_FIELD
} from '../actions/action-types';

const initialState = {
    documents: [],
    currentDocument: { name: "", description: "", fileName: ""},
    currentField: {contents: "", description: "", object: "", field: "", x: 0, y: 0, width: 0, height: 0},
    fields: [],
    documentFields: [],
    classFields: []
}

const documentMappings = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case LOAD_DOCUMENT_MAPPINGS:
            newState = Object.assign({}, state);
            newState.documents = action.payload;
            return newState;

        case UPDATE_DOCUMENT_FIELD:
            newState = Object.assign({}, state);
            let currentDoc = Object.assign({}, state.currentDocument);
            currentDoc[action.payload.field] = action.payload.value;
            newState.currentDocument = currentDoc;
            return newState;

        case SELECT_DOCUMENT_MAPPING:
            newState = Object.assign({}, state);
            newState.currentDocument = Object.assign({}, state.documents.find(d => d.id === action.payload));
            return newState;

        case SAVE_DOCUMENT_MAPPING:
            newState = Object.assign({}, state);
            newState.currentDocument = action.payload;
            return newState;

        case REMOVE_DOCUMENT_MAPPING:
            newState = Object.assign({}, state);
            newState.documents = Object.assign([], state.documents.filter(d => d.id !== action.payload));
            return newState;

        case CLEAR_SELECTED_MAPPING:
            newState = Object.assign({}, state);
            newState.currentDocument = Object.assign({}, { name: "", description: "", fileName: "", className: ""});
            return newState;

        case LOAD_FIELD_MAPPINGS:
            newState = Object.assign({}, state);
            newState.fields = action.payload;
            return newState;

        case UPDATE_CURRENT_FIELD_MAPPING:
            newState = Object.assign({}, state);
            let currentField = Object.assign({}, state.currentField);
            currentField[action.payload.field] = action.payload.value;
            newState.currentField = currentField;
            return newState;

        case SAVE_FIELD_MAPPING:
            newState = Object.assign({}, state);
            const tempFields = Object.assign([], state.fields.filter(f => f.id !== action.payload.id));
            tempFields.push(action.payload);
            newState.fields = tempFields;
            newState.currentField = Object.assign({}, initialState.currentField);
            return newState;

        case CLEAR_SELECTED_FIELD_MAPPING:
            newState = Object.assign({}, state);
            newState.currentField = Object.assign({}, {contents: "", description: "", object: "", field: "", id: 0});
            return newState;

        case REMOVE_FIELD_MAPPING:
            newState = Object.assign({}, state);
            newState.fields = Object.assign([], state.fields.filter(f => f.id !== action.payload));
            return newState;

        case LOAD_DOCUMENT_FIELDS:
            newState = Object.assign({}, state);
            newState.documentFields = action.payload;
            return newState;

        case LOAD_CLASS_MAPPING_FIELDS:
            newState = Object.assign({}, state);
            newState.classFields = action.payload;
            return newState;

        case DOWNLOAD_DOCUMENT:
            newState = Object.assign({}, state);
            newState.documentUrl = action.payload;
            return newState;

        case SELECT_FIELD_MAPPING:
            newState = Object.assign({}, state);
            newState.currentField = Object.assign({}, action.payload);
            return newState;

        default:
            return state
    }
}

export default documentMappings