import React, {useEffect} from 'react';
import {
    loadBank,
    loadQI,
    updateBankField,
    updateQIField,
    upsertBank,
    upsertQI
} from "../../actions/application-setting";
import {useDispatch, useSelector} from "react-redux";

const Info = function (props) {

    const dispatch = useDispatch();
    const bank = useSelector(state => state.applicationSetting.bank);
    const qi = useSelector(state => state.applicationSetting.qi);

    useEffect(() => {
        async function loadData () {
            await dispatch(loadBank());
            await dispatch(loadQI());
        }
        loadData();
    }, [dispatch]);

    const handleQualifiedIntermediaryFieldChanged = async function (e) {
        await dispatch(updateQIField(e.target.name, e.target.value));
    }

    const handleBankFieldChanged = async function (e) {
        await dispatch(updateBankField(e.target.name, e.target.value));
    }

    const handleCancelBank = async function () {
        await dispatch(loadBank());
    }

    const handleCancelQi = async function (){
        await dispatch(loadQI());
    }

    const handleSaveBank = async function () {
        await dispatch(upsertBank(bank));
    }

    const handleSaveQi = async function () {
        await dispatch(upsertQI(qi));
    }

    return (<div className="card border-0 shadow my-5 p-2" style={{minHeight: '85vh'}}>
        <div className={"row p-0 m-0 pt-3"}>
            <div className="col-6">
                <h4 className={'text-center'}>Qualified Intermediary</h4>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" onChange={handleQualifiedIntermediaryFieldChanged} name={"name"} value={qi.name}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Address</label>
                    <input type="text" className="form-control" onChange={handleQualifiedIntermediaryFieldChanged} name={"address"} value={qi.address}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">City</label>
                    <input type="text" className="form-control" onChange={handleQualifiedIntermediaryFieldChanged} name={"city"} value={qi.city}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">State</label>
                    <input type="text" className="form-control" onChange={handleQualifiedIntermediaryFieldChanged} name={"state"} value={qi.state}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Zip Code</label>
                    <input type="text" className="form-control" onChange={handleQualifiedIntermediaryFieldChanged} name={"zip"} value={qi.zip}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input type="text" className="form-control" onChange={handleQualifiedIntermediaryFieldChanged} name={"phone"} value={qi.phone}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fax Number</label>
                    <input type="text" className="form-control" onChange={handleQualifiedIntermediaryFieldChanged} name={"fax"} value={qi.fax}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Point of Contact</label>
                    <input type="text" className="form-control" onChange={handleQualifiedIntermediaryFieldChanged} name={"pointOfContact"} value={qi.pointOfContact}/>
                </div>
                <div className={"text-end"}>
                    <button type={'button'} className={"btn btn-sm btn-primary"} onClick={handleSaveQi}>Save Qualified Intermediary</button>
                    <button type={'button'} className={"btn btn-sm btn-secondary ms-2"} onClick={handleCancelQi}>Cancel</button>
                </div>
            </div>
            <div className="col-6">
                <h4 className={'text-center'}>Bank</h4>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" onChange={handleBankFieldChanged} name={"name"} value={bank.name}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Address</label>
                    <input type="text" className="form-control" onChange={handleBankFieldChanged} name={"address"} value={bank.address}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">City</label>
                    <input type="text" className="form-control" onChange={handleBankFieldChanged} name={"city"} value={bank.city}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">State</label>
                    <input type="text" className="form-control" onChange={handleBankFieldChanged} name={"state"} value={bank.state}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Zip Code</label>
                    <input type="text" className="form-control" onChange={handleBankFieldChanged} name={"zip"} value={bank.zip}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Routing Number</label>
                    <input type="text" className="form-control" onChange={handleBankFieldChanged} name={"routingNumber"} value={bank.routingNumber}/>
                </div>
                <div className={"text-end"}>
                    <button type={'button'} className={"btn btn-sm btn-primary"} onClick={handleSaveBank}>Save Bank</button>
                    <button type={'button'} className={"btn btn-sm btn-secondary ms-2"} onClick={handleCancelBank}>Cancel</button>
                </div>
            </div>
        </div>
    </div>);
}

export default Info;
