import React from 'react';

export const AvatarFormatter = (props) => (
    <div><img alt={""} src={props.value} style={{width: "30px", height: "30px", borderRadius: "15px"}}/></div>
);

export const BooleanFormatter = (props) => {
    return (<div className={"text-center"} style={{width: "80%"}}><input type="checkbox" readOnly={true} checked={props.value} className={"disabled"}/></div>);
};

export const DateFormatter = (props) => {
    return (<div>{props.value ? new Date(props.value).toLocaleDateString() : <span>&nbsp;</span>}</div>);
};

export const DateTimeFormatter = (props) => {
    return (<div>{props.value ? new Date(props.value).toLocaleString([], {timeZoneName: "short"}) : <span>&nbsp;</span>}</div>);
};