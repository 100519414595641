import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getExchangeById, toggleExchangeStatus} from "../../actions/exchange";

export default function ExchangeToolbar (props) {

    const dispatch = useDispatch();
    const { section } = props;

    const exchange = useSelector(state => state.exchange.currentExchange);
    const closed = useSelector(state => state.exchange.currentExchange.closed);

    const handleChange = async function(){
        await dispatch(toggleExchangeStatus(exchange.id, !closed));
        await dispatch(getExchangeById(exchange.id));
    }

    const renderSection = () => {
        switch(section) {

            case "detail":       return <div className={"fs-4"}>Order Details</div>;
            case "basic":       return <div className={"fs-4"}>Order Information</div>;
            case "properties":    return <div className={"fs-4"}>Properties</div>;
            case "taxpayer":    return <div className={"fs-4"}>Taxpayer</div>;
            case "accounting":    return <div className={"fs-4"}>Accounting</div>;
            case "attachments":    return <div className={"fs-4"}>Documents</div>;

            default:          return <h1>{section}</h1>
        }
    }
    return (<div className="col-12 p-0 m-0">
        <div className="row m-0 p-0 flex-nowrap justify-content-around">
            <div className="col-12 m-0 p-0">
                <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
                    <div className="row m-0 p-0">
                        <div className="col-12 float-end border-0 p-2 m-0">
                            <div className="row m-0 p-0">
                                <div className="col-4">
                                    <div className="fs-4">{exchange.exchangeId}</div>
                                </div>
                                <div className={"col-6 text-end pe-2"}>
                                    {renderSection()}
                                </div>
                                <div className={"col-2 p-0 m-0 pt-1 text-end"} title={"Toggle Closed Status"}>
                                    <button className={!closed ? "btn btn-sm btn-outline-secondary" : "btn btn-sm btn-danger"} onClick={handleChange}>{closed ? "Order Closed" : "Close Order"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}