import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadExchangeProperties, loadExchangePropertiesForType, selectPropertyAddress} from "../../actions/exchange";
import {AgGridReact} from "ag-grid-react";
import {defaultColDef} from "../../constants/settings";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

export default function PropertyList (props) {
    const dispatch = useDispatch();
    const { exchangeId, typeId, openModal } = props;
    const gridRef = useRef();
    const properties = useSelector(state => state.exchange.properties);

    useEffect(() => {
        if(exchangeId > 0){
            if(typeId){
                dispatch(loadExchangePropertiesForType(exchangeId, typeId))
            } else {
                dispatch(loadExchangeProperties(exchangeId));
            }
        }
    }, [exchangeId, typeId, dispatch]);

    const columnDefs = [
        {
            headerName: 'Address',
            field: 'address1',
        },
        {
            headerName: 'City',
            field: 'city',
        },
        {
            headerName: 'County',
            field: 'county',
        },
        {
            headerName: 'State',
            field: 'state',
        }
    ];

    if (!typeId){
        columnDefs.push({
            headerName: 'Type',
            field: 'addressType',
        });
    }

    if (openModal !== undefined){
        columnDefs.push({
            headerName: '',
            field: 'id',
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: async function(e) {
                    await openModal(e);
                },
            }
        })
    }

    const handleRowClicked = (e) => {
        dispatch(selectPropertyAddress(e.data));
    }

    const handleGridReady = async function (e) {
        const property = gridRef.current.api.getDisplayedRowAtIndex(0)
        if(!property) return;
        property.setSelected(true);
        await dispatch(selectPropertyAddress(property.data));
    }

    return (<div className="card col-12 border-1 border-dark p-0 m-0 mt-2 shadow">
        <div className="row m-0 p-0">
            <div className="col-12 pt-1 pb-0">
                <div className={"ag-theme-alpine"}  style={{height: '20vh'}}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        ref={gridRef}
                        rowData={properties}
                        onRowClicked={handleRowClicked}
                        onFirstDataRendered={handleGridReady}
                        onRowDataUpdated={handleGridReady}
                        suppressCellFocus={true}
                        rowSelection={openModal !== undefined ? 'single' : ''}
                        columnDefs={columnDefs}/>
                </div>
            </div>
        </div>
    </div>);
}

function BtnCellRenderer(props) {
        const handleButtonClicked = async (e) => {
            await props.clicked(e)
        }

    return <div className={"text-end"}><button type={"button"} className={"btn btn-link text-decoration-none"} title={"Edit Property"} onClick={() => handleButtonClicked(props)}><FontAwesomeIcon icon={faPencil} size={"lg"}/></button></div>
}
