import {
    CLEAR_RECTANGLE,
    LOG_OUT, RECTANGLE_SELECTED, SAVE_FIELD_MAPPING,
    SET_DRAGGING_MOUSE,
    SET_NUMBER_OF_PAGES,
    SET_PAGE_NUMBER,
    SET_PDF_CANVAS_SIZE,
    SET_RECTANGLE_SIZE,
    UPDATE_START_POSITION
} from '../actions/action-types';

const initialState = {
    pdfCanvas: {x: 0, y: 0, width: 0, height: 0},
    numPages: 0,
    pageNumber: 1,
    isDragging: false,
    startPosition: {x: 0, y: 0},
    rectangleSize: {height: 0, width: 0},
    rectangleSelected: false,
    multiplier: 4
}

const markup = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case SET_PDF_CANVAS_SIZE:
            newState = Object.assign({}, state);
            newState.pdfCanvas = action.payload;
            return newState;

        case SET_NUMBER_OF_PAGES:
            newState = Object.assign({}, state);
            newState.numPages = action.payload;
            return newState;

        case SET_PAGE_NUMBER:
            newState = Object.assign({}, state);
            newState.pageNumber = action.payload;
            return newState;

        case SET_DRAGGING_MOUSE:
            newState = Object.assign({}, state);
            newState.isDragging = action.payload;
            return newState;

        case UPDATE_START_POSITION:
            newState = Object.assign({}, state);
            newState.startPosition = action.payload;
            return newState;

        case SET_RECTANGLE_SIZE:
            newState = Object.assign({}, state);
            newState.rectangleSize = action.payload;
            return newState;

        case RECTANGLE_SELECTED:
            newState = Object.assign({}, state);
            newState.rectangleSelected = true;
            return newState;

        case CLEAR_RECTANGLE:
        case SAVE_FIELD_MAPPING:
            newState = Object.assign({}, state);
            newState.startPosition = Object.assign({}, initialState.startPosition);
            newState.rectangleSize = Object.assign({}, initialState.rectangleSize);
            newState.rectangleSelected = false;
            return newState;

        default:
            return state
    }
}

export default markup