import React from "react";

export default function Help () {

    return (<div className="card border-1 shadow my-5 col container-card p-2">
        <div className="row p-0 m-0 mt-2">
            <div className="col-12 m-0 p-0">
                <div className={"row p-0 m-0 justify-content-center"}>
                    <div className={"col-4 text-center"}>Help coming soon</div>
                </div>
            </div>
        </div>
    </div>);
}