import React from "react";
import {Link} from "react-router-dom";

export default function Anonymous () {

    return (<div>
        <div className={"row p-0 m-0 justify-content-center"}>
            <div className={"col-4 text-center"}>
                <h5>Looking to set up an exchange?</h5>  <Link to={"registration"}>Click Here</Link> to provide some information
            </div>
        </div>
    </div>);
}