import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import Anonymous from "./anonymous";
import {ExchangeList} from "../exchange-list";
import {useSelector} from "react-redux";

export default function Home () {

    const { isAuthenticated } = useAuth0();
    const roles = useSelector(state => state.session.roles);

    if (isAuthenticated && roles.length > 0) {
        return (<ExchangeList/>);
    } else {
        return (<div className="card border-1 shadow my-5 col container-card p-2">
            <div className="row p-0 m-0 mt-2">
                <div className="col-12 m-0 p-0">
                    <Anonymous/>
                </div>
            </div>
        </div>);
    }
}