import {
    ADD_TEMP_ATTACHMENT, CLEAR_SELECTED_FOLDER,
    DOWNLOAD_ATTACHMENT,
    LIST_ATTACHMENTS_FOR_EXCHANGE,
    LOAD_ATTACHMENT_FOLDERS,
    LOG_OUT, MOVE_ATTACHMENT,
    REMOVE_ATTACHMENT,
    REMOVE_TEMP_ATTACHMENT, SELECT_FOLDER,
    START_CREATING_EXCHANGE,
    UPDATE_SELECTED_FOLDER_FIELD,
    UPDATE_TEMP_ATTACHMENT,
    UPLOAD_ATTACHMENT
} from '../actions/action-types'

const initialState = {
    tempAttachments: [],
    attachments: [],
    folders: [],
    selectedFolder: {id: 0, name: ""}
}

const exchangeAttachment = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case START_CREATING_EXCHANGE:
            newState = Object.assign({}, initialState);
            return newState;

        case UPLOAD_ATTACHMENT:
            newState = Object.assign({}, state);
            newState.tempAttachments = Object.assign([], state.tempAttachments.filter(a => a.name !== action.payload.name)).sort((a, b) => a.name.localeCompare(b.name));
            newState.attachments.push(action.payload);
            newState.attachments.sort((a, b) => a.name.localeCompare(b.name));
            return newState;

        case LIST_ATTACHMENTS_FOR_EXCHANGE:
            newState = Object.assign({}, state);
            newState.attachments = action.payload;
            return newState;

        case REMOVE_ATTACHMENT:
            newState = Object.assign({}, state);
            newState.attachments = Object.assign([], state.attachments.filter(a => a.name !== action.payload.name)).sort((a, b) => a.name.localeCompare(b.name));
            return newState;

        case DOWNLOAD_ATTACHMENT:
            newState = Object.assign({}, state);
            return newState;

        case ADD_TEMP_ATTACHMENT:
            newState = Object.assign({}, state);
            newState.tempAttachments = Object.assign([], state.tempAttachments);
            newState.tempAttachments.push(action.payload);
            newState.tempAttachments.sort((a, b) => a.name.localeCompare(b.name));
            return newState;

        case REMOVE_TEMP_ATTACHMENT:
            newState = Object.assign({}, state);
            newState.tempAttachments = Object.assign([], state.tempAttachments.filter(a => a.name !== action.payload)).sort((a, b) => a.name.localeCompare(b.name));
            return newState;

        case UPDATE_TEMP_ATTACHMENT:
            newState = Object.assign({}, state);
            newState.tempAttachments = Object.assign([], state.tempAttachments.filter(a => a.name !== action.payload.name));
            newState.tempAttachments.push(action.payload);
            newState.tempAttachments.sort((a, b) => a.name.localeCompare(b.name));
            return newState;

        case LOAD_ATTACHMENT_FOLDERS:
            newState = Object.assign({}, state);
            newState.folders = action.payload;
            return newState;

        case UPDATE_SELECTED_FOLDER_FIELD:
            newState = Object.assign({}, state);
            let folder = Object.assign({}, state.selectedFolder);
            folder[action.payload.field] = action.payload.value;
            newState.selectedFolder = folder;
            return newState;

        case SELECT_FOLDER:
            newState = Object.assign({}, state);
            newState.selectedFolder = state.folders.find(f => f.id === action.payload);
            return newState;

        case CLEAR_SELECTED_FOLDER:
            newState = Object.assign({}, state);
            newState.selectedFolder = Object.assign({}, initialState.selectedFolder);
            return newState;

        case MOVE_ATTACHMENT:
            newState = Object.assign({}, state);
            return newState;

        default:
            newState = Object.assign({}, state);
            return newState;
    }
}

export default exchangeAttachment