import {
    CONVERT_REGISTRATION,
    LOAD_ALL_REGISTRATIONS,
    LOAD_REGISTRATION_BY_ID,
    SUBMIT_REGISTRATION,
    UPDATE_REGISTRATION_FIELD
} from "./action-types";
import {OrderRegistrationApi} from "../api";
import {baseUrl} from "../constants/settings";
import toast from "react-hot-toast";

const api = new OrderRegistrationApi(undefined, baseUrl);

export const updateRegistrationField = function (name, value) {
    return dispatch => {
        dispatch({type: UPDATE_REGISTRATION_FIELD, payload: {name, value}});
    }
}

export const submitRegistration = function (registration) {
    return async dispatch => {
        try {
            let response;
            if (registration.id > 0) {
                response = await api.apiOrderRegistrationUpdatePost(registration);
                if (response.data){
                    toast.success("Exchange information submitted successfully");
                    dispatch({type: SUBMIT_REGISTRATION, payload: registration.id})
                } else {
                    toast.error("Error Updating Exchange Information");
                }
            } else {
                response = await  api.apiOrderRegistrationAddPost(registration);
                if(response.data > 0){
                    toast.success("Exchange information submitted successfully");
                    dispatch({type: SUBMIT_REGISTRATION, payload: response.data});
                } else {
                    toast.error("Error Submitting Exchange Information");
                }
            }

        } catch (err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadAllRegistrations = function () {
    return async dispatch => {
        try {
            let response;
            response = await  api.apiOrderRegistrationGet();
            dispatch({type: LOAD_ALL_REGISTRATIONS, payload: response.data});
        } catch (err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadRegistrationById = function (registrationId) {
    return async dispatch => {
        try {
            let response;
            response = await  api.apiOrderRegistrationOrderIdGet(registrationId);
            dispatch({type: LOAD_REGISTRATION_BY_ID, payload: response.data});
        } catch (err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const convertRegistration = function (registration) {
    return async dispatch => {
        try {
            let response;
            response = await  api.apiOrderRegistrationConvertPost(registration);
            dispatch({type: CONVERT_REGISTRATION, payload: response.data});
        } catch (err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}