import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {loadContactById, loadContactByUserId} from "../../actions/contact";
import {useParams} from "react-router-dom";
import Contact from "./contact";
import {defaultRow, CONTACT_ROLE} from "../../constants/settings";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";

const ContactInfo = () => {

    const dispatch = useDispatch();
    const { contactId } = useParams();

    const roles = useSelector(state => state.session.roles);
    const isReadOnly = roles.indexOf(CONTACT_ROLE) >= 0;

    useEffect(() => {
        if(contactId){
            dispatch(loadContactById(contactId));
        } else {
            if(isReadOnly) {
                dispatch(loadContactByUserId());
            }
        }
    }, [dispatch, contactId, isReadOnly]);

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
            <div className="row m-0 p-0" style={{height: '85vh'}}>
                <div className="col-1 p-0 pt-3 pt-2 header-column">
                    <h1 className="m-0 p-0 py-2 px-4">Contact Info</h1>
                </div>
                <div className="col-11 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                    <div className={defaultRow}>
                        <div className={"col-12"}>
                            <Contact contactId={contactId}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(ContactInfo, {
    onRedirecting: () => <Loading />,
});