import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

const NoAccount = function () {

    const { isAuthenticated } = useAuth0();
    const roles = useSelector(state => state.session.roles);
    const navigate = useNavigate();

    useEffect( () => {
        if(isAuthenticated && roles.length > 0){
            navigate("/");
        }

    }, [isAuthenticated, navigate, roles.length]);

    return (<div className="card border-1 shadow my-5 col container-card p-2">
        <div className="row p-0 m-0 mt-2">
            <div className="col-12 m-0 p-0">
                <h4 className={"text-center"}>An account for the signed in user does not exist.</h4>
                <div className={"pt-3 text-center"}>Please contact your system administrator for assistance.</div>
            </div>
        </div>
    </div>);

}

export default NoAccount;