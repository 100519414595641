import React, {useEffect} from "react";
import {Scheduler} from '@aldabil/react-scheduler';
import {useDispatch, useSelector} from "react-redux";
import {getAllExchanges} from "../../actions/exchange";

export default function Calendar () {

    const dispatch = useDispatch();
    const exchangeDates = useSelector(state => state.exchange.exchangeDates);

    useEffect(() => {
        dispatch(getAllExchanges());
    }, [dispatch]);

    const handleClick = function (e) {
    }

    return (<div className="card border-1 shadow my-5 col container-card p-2">
        <div className="row p-0 m-0 mt-2">
            <div className="col-12 m-0 p-0">
                <div className={"row p-0 m-0"}>
                    <h3>Calendar</h3>
                </div>
                <div className={"row p-0 m-0 justify-content-center"}>
                    <Scheduler view={"month"}
                               fields={[{
                                   name: "exchangeId",
                                   type: "input"
                                   },{
                                   name: "address",
                                   type: "input"
                               }]}
                               events={exchangeDates}
                               viewerExtraComponent={(fields, event) => {
                                   return (
                                       <div>
                                           <div><span className={"fw-bold"}>Exchange Id:</span> {event.exchangeId}</div>
                                           <div>{event.address}&nbsp;</div>
                                       </div>
                                   );
                               }}
                                editable={false}
                                deletable={false}
                                onClick={handleClick}
                                month={{
                                    weekDays: [0, 1, 2, 3, 4, 5, 6],
                                    weekStartOn: 0,
                                    startHour: 9,
                                    endHour: 17,
                                    navigation: true
                                }}></Scheduler>
                </div>
            </div>
        </div>
    </div>);
}
