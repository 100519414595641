import React, {useEffect} from "react";
import Contact from "../contact-info/contact";
import {useDispatch, useSelector} from "react-redux";
import {loadContactForTab, loadRepresentativesForContact, setContactTab, setContactType} from "../../actions/exchange";
import {clearCurrentContact} from "../../actions/contact";
import {signatureAndVestingTabName, TAXPAYER_ID} from "../../constants/settings";
import Representatives from "./representatives";

export const AddressContacts = function (props) {

    const dispatch = useDispatch();
    const {exchangeId} = props;
    const tabs = useSelector(state => state.exchange.contactTabs);
    const types = useSelector(state => state.exchange.contactTypes);
    const selectedType = useSelector(state => state.exchange.selectedType);
    const selectedTab = useSelector(state => state.exchange.selectedTab);
    const currentTab = tabs.find(t => t.id === selectedTab);
    const currentAddress = useSelector(state => state.exchange.selectedPropertyAddress);
    const selectedPropertyType = useSelector(state => state.exchange.selectedPropertyType);

    useEffect(() => {
        const lookupParams = {
            exchangeId,
            typeId: selectedType,
            tabId: selectedTab,
            propertyAddressId: currentAddress.id
        };
        dispatch(clearCurrentContact());
        if (currentTab && currentTab.name !== signatureAndVestingTabName) {
            dispatch(loadContactForTab(lookupParams));
        }
    }, [exchangeId, selectedType, selectedTab, dispatch, currentTab, currentAddress]);

    const generateTabs = function () {
        return tabs.map((t, idx) => <li key={idx} className="nav-item" role="presentation">
            <button className={t.id === selectedTab ? "nav-link active small" : "nav-link small"} type="button"
                    role="tab" onClick={() => dispatch(setContactTab(t.id))}>{t.name}
            </button>
        </li>)
    }

    const generateVerticalTabs = function () {
        return types.filter(t => t.id !== TAXPAYER_ID).map((t, idx) => <li key={idx} className="nav-item"
                                                                           role="presentation">
            <div
                className={t.id === selectedType ? "nav-link tab-clickable active small" : "nav-link tab-clickable small"}
                onClick={() => dispatch(setContactType(t.id))}>{selectedPropertyType === 2 && t.id === 2 ? "Seller" : t.name}
            </div>
        </li>)
    }

    const handleContactSaved = function (e) {
        if (currentTab && currentTab.name === signatureAndVestingTabName) {
            dispatch(loadRepresentativesForContact({
                exchangeId,
                typeId: selectedType,
                tabId: selectedTab,
                propertyAddressId: currentAddress.id
            }));
            dispatch(clearCurrentContact());
        }
    }

    if (currentAddress.id === 0) {
        return (<div className="card col-12 border-1 p-0 m-0 mt-2 pt-2 px-1 border-dark shadow vtabs">
            <div className={"fw-bold text-center"}>Select an address from above</div>
        </div>)
    } else {
        return (<div className="card col-12 border-1 p-0 m-0 mt-2 pt-2 px-1 border-dark shadow vtabs">
            <div className={"row m-0 p-0"}>
                <div className={"col-10 p-0"}>
                    <ul className="nav nav-tabs">
                        {generateTabs()}
                    </ul>
                </div>
            </div>
            <div className="tab-content border-end-1 p-0">
                <div className={"row m-0 p-0"}>
                    <div className={"col-10 p-0 pt-1"}>
                        {currentTab && currentTab.name === signatureAndVestingTabName ? <div>
                                <Representatives exchangeId={exchangeId} typeId={selectedType} tabId={selectedTab}/>
                                <hr/>
                                <Contact hideUserButton={true} exchangeId={exchangeId} typeId={selectedType}
                                         tabId={selectedTab} contactSaved={handleContactSaved}/>
                            </div> :
                            <Contact hideUserButton={true} exchangeId={exchangeId} typeId={selectedType}
                                     tabId={selectedTab}/>}
                    </div>
                    <div className={"col-2 m-0 p-0"}>
                        <ul className="nav nav-tabs right-tabs" style={{marginTop: "-1px"}}>
                            {generateVerticalTabs()}
                        </ul>
                    </div>
                </div>
            </div>
        </div>);
    }
}


export default AddressContacts;
