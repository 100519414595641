import React, {useEffect} from "react";
import {
    faArchive, faFolderMinus,
    faFolderPlus,
    faPencilAlt,
    faPlus, faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {
    archiveTopic,
    loadTopics,
    selectMessageTopic, startNewTopic, toggleArchivedTopics, unArchiveTopic
} from "../../actions/message";
import {toggleLoadingMessages, toggleLoadingTopics, toggleMessageArchiveButtons} from "../../actions/ui";
import {CONTACT_ROLE} from "../../constants/settings";

export default function Topics () {

    const dispatch = useDispatch();

    const topics = useSelector(state => state.message.topics);
    const selectedTopic = useSelector(state => state.message.selectedTopic);
    const showArchiveButtons = useSelector(state => state.ui.showMessageArchiveButtons);
    const showArchivedTopics = useSelector(state => state.message.showArchived);
    const loadingTopics = useSelector(state => state.ui.loadingTopics);

    const roles = useSelector(state => state.session.roles);
    const isReadOnly = roles.indexOf(CONTACT_ROLE) >= 0;

    useEffect(() => {
        async function load() {
            dispatch(toggleLoadingTopics());
            await dispatch(loadTopics(showArchivedTopics));
            dispatch(toggleLoadingTopics());
        }
        load();
        // eslint-disable-next-line
    }, [dispatch]);

    const selectTopic = async function (topic) {
        dispatch(toggleLoadingMessages());
        await dispatch(selectMessageTopic(topic));
        dispatch(toggleLoadingMessages());
    }

    const newTopic = function () {
        dispatch(startNewTopic());
    }

    const toggleEdit = function (){
        dispatch(toggleMessageArchiveButtons());
    }

    const archive = async function (topic) {
        dispatch(toggleLoadingTopics());
        await dispatch(archiveTopic(topic));
        await dispatch(loadTopics(showArchivedTopics));
        dispatch(toggleLoadingTopics());
    }

    const unArchive = async function (topic) {
        dispatch(toggleLoadingTopics());
        await dispatch(unArchiveTopic(topic));
        await dispatch(loadTopics(showArchivedTopics));
        dispatch(toggleLoadingTopics());
    }

    const toggleArchived = async function () {
        dispatch(toggleLoadingTopics());
        dispatch(toggleArchivedTopics(!showArchivedTopics));
        await dispatch(loadTopics(!showArchivedTopics));
        dispatch(toggleLoadingTopics());
    }

    const renderArchiveButton = function (topic) {
        if(!showArchiveButtons) { return <span>&nbsp;</span>; }

        if(topic.archived){
            return (<button className={"btn btn-outline-secondary p-0 px-1 m-0 mx-1"}
                            title={"Make Thread Active"}
                            onClick={() => unArchive(topic)}>
                <FontAwesomeIcon icon={faFolderMinus} size={"1x"} />
            </button>);
        } else {
            return (<button className={"btn btn-outline-dark p-0 px-1 m-0 mx-1"}
                            title={"Archive Thread"}
                            onClick={() => archive(topic)}>
                <FontAwesomeIcon icon={faFolderPlus} size={"1x"} />
            </button>);
        }
    }

    const renderTopicLink = function (t) {
        if  (t.archived){
            return (<button className={selectedTopic.topic === t.topic ? "btn btn-link text-secondary text-decoration-none fw-bold m-0 p-0" : "btn btn-link text-secondary text-decoration-none m-0 p-0"}
                            onClick={() => selectTopic(t)}>{t.topic}</button>);
        } else {
            return (<button className={selectedTopic.topic === t.topic ? "btn btn-link text-dark text-decoration-none fw-bold m-0 p-0" : "btn btn-link text-dark text-decoration-none m-0 p-0"}
                            onClick={() => selectTopic(t)}>{t.topic}</button>);
        }
    }

    const renderTopics = function () {
        if (loadingTopics) {
            return <div className={"text-center fw-normal"}><FontAwesomeIcon icon={faSpinner} className={"fa-spin"}
                                                                   size={"1x"}/></div>
        } else {
            return topics.map((t, idx) => <div className="p-2 m-0"
                                               key={idx}>{renderArchiveButton(t)}{renderTopicLink(t)}</div>)
        }
    }

    return (<div>
        <div className={"row m-0 p-0"}>
            <div className={"col-12 p-0 m-0 text-end"}>
                <button className={isReadOnly ? "display-none" :"btn btn-outline-dark p-0 px-1 m-0"}
                        onClick={newTopic}
                        title={"New Thread"}>
                    <FontAwesomeIcon icon={faPlus}/>&nbsp;New</button>
                <button className={showArchiveButtons ? "btn btn-dark p-0 px-1 m-0 ms-1" : "btn btn-outline-dark p-0 px-1 m-0 ms-1"}
                        title={"Manage Threads"}
                        onClick={toggleEdit}>
                    <FontAwesomeIcon icon={faPencilAlt} size={"1x"}/>&nbsp;Manage
                </button>
                <button className={showArchivedTopics ? "btn btn-dark p-0 px-1 m-0 ms-1 me-2" : "btn btn-outline-dark p-0 px-1 m-0 ms-1 me-2"}
                        title={"View Archived Threads"}
                        onClick={toggleArchived}>
                    <FontAwesomeIcon icon={faArchive} size={"1x"}/>&nbsp;Archived
                </button>
            </div>
        </div>
        {renderTopics()}
    </div>);
}