import {baseUrl} from "../constants/settings";
import {
    ADD_TEMP_ATTACHMENT,
    CLEAR_SELECTED_FOLDER, DELETE_FOLDER,
    DOWNLOAD_ATTACHMENT,
    LIST_ATTACHMENTS_FOR_EXCHANGE,
    LOAD_ATTACHMENT_FOLDERS, MOVE_ATTACHMENT,
    REMOVE_ATTACHMENT,
    REMOVE_TEMP_ATTACHMENT,
    SAVE_ATTACHMENT_FOLDER,
    SELECT_FOLDER,
    UPDATE_SELECTED_FOLDER_FIELD,
    UPDATE_TEMP_ATTACHMENT,
    UPLOAD_ATTACHMENT
} from "./action-types";
import toast from "react-hot-toast";
import {DocumentFolderApi, ExchangeAttachmentApi} from "../api";

const exchangeAttachmentApi = new ExchangeAttachmentApi(undefined, baseUrl);
const documentFolderApi = new DocumentFolderApi(undefined, baseUrl);

export const uploadAttachment = function (attachment){
    return async (dispatch, getState) => {
        try {
            const documentFolderId = getState().exchangeAttachment.selectedFolder.id;
            attachment.documentFolderId = documentFolderId;
            const response = await exchangeAttachmentApi.apiExchangeAttachmentPost(attachment);
            attachment.id = response.data;
            attachment.fileData = "";
            dispatch({ type: UPLOAD_ATTACHMENT, payload: attachment });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const downloadAttachment = function (attachment){
    return async dispatch => {
        try {
            const response = await exchangeAttachmentApi.apiExchangeAttachmentDownloadAttachmentIdGet(attachment.id, {responseType: 'blob'});
            const blob = await response.data;
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = attachment.filename;
            a.click();
            dispatch({ type: DOWNLOAD_ATTACHMENT });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const removeAttachment = function (attachment){
    return async dispatch => {
        try {
            // eslint-disable-next-line
            const response = await exchangeAttachmentApi.apiExchangeAttachmentRemoveAttachmentIdGet(attachment.id);
            dispatch({ type: REMOVE_ATTACHMENT, payload: attachment });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const listAttachmentsForExchange = function (exchangeId, folderId){
    return async (dispatch, getState) => {
        try {
            if (!folderId)
                folderId = getState().exchangeAttachment.selectedFolder.id;
            const response = await exchangeAttachmentApi.apiExchangeAttachmentMetaForExchangeIdFolderIdGet(exchangeId, folderId);
            dispatch({ type: LIST_ATTACHMENTS_FOR_EXCHANGE, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadAttachmentFolders = function (){
    return async dispatch => {
        try {
            const response = await documentFolderApi.apiDocumentFolderGet();
            dispatch({ type: LOAD_ATTACHMENT_FOLDERS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const saveAttachmentFolder = function (folder){
    return async dispatch => {
        try {
            let response;
            if(folder.id === 0){
                response = await documentFolderApi.apiDocumentFolderPost(folder);
                if (response.data > 0){
                    dispatch({ type: SAVE_ATTACHMENT_FOLDER, payload: folder });
                } else {
                    toast.error("Error saving folder.");
                }
            } else {
                response = await documentFolderApi.apiDocumentFolderIdPut(folder.id, folder);
                if (response.data){
                    dispatch({ type: SAVE_ATTACHMENT_FOLDER, payload: folder });
                } else {
                    toast.error("Error saving folder.");
                }
            }

        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const deleteFolder = function (folderId){
    return async dispatch => {
        try {
            const response = await documentFolderApi.apiDocumentFolderIdDelete(folderId);
            if (response.data){
                dispatch({ type: DELETE_FOLDER, payload: folderId });
            } else {
                toast.error("Error deleting folder.");
            }
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const moveAttachment = function (attachmentId, folderId, folderName){
    return async dispatch => {
        try {
            const response = await exchangeAttachmentApi.apiExchangeAttachmentMoveAttachmentAttachmentIdFolderIdGet(attachmentId, folderId);
            if (response.data){
                dispatch({ type: MOVE_ATTACHMENT });
                toast.success(`Document moved to ${folderName}`);
            } else {
                toast.error("Error moving attachment.");
            }
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const addTemporaryAttachment = function (attachment) {
    return dispatch => {
        dispatch({type: ADD_TEMP_ATTACHMENT, payload: attachment});
    }
}

export const updateTemporaryAttachment = function (attachment) {
    return dispatch => {
        dispatch({type: UPDATE_TEMP_ATTACHMENT, payload: attachment});
    }
}

export const removeTemporaryAttachment = function (name) {
    return dispatch => {
        dispatch({type: REMOVE_TEMP_ATTACHMENT, payload: name});
    }
}

export const updateSelectedFolderField = function (field, value) {
    return dispatch => {
        dispatch({type: UPDATE_SELECTED_FOLDER_FIELD, payload: {field, value}});
    }
}

export const selectFolder = function (folderId) {
    return dispatch => {
        dispatch({type: SELECT_FOLDER, payload: folderId});
    }
}

export const clearSelectedFolder = function () {
    return dispatch => {
        dispatch({type: CLEAR_SELECTED_FOLDER});
    }
}