import {
    CLEAR_USER_FORM,
    LOAD_ROLES,
    LOAD_SELECTED_USER_ROLE,
    LOAD_USERS, REMOVE_USER, SAVE_USER,
    SELECT_USER, TOGGLE_USER_MODAL,
    UPDATE_CURRENT_USER_FIELD
} from "./action-types";
import toast from "react-hot-toast";
import {UserApi} from "../api";
import {baseUrl} from "../constants/settings";

const userApi = new UserApi(undefined, baseUrl);

export const loadUsers = function() {
    return async dispatch => {
        try
        {
            const response = await userApi.apiUserGet();
            dispatch({ type: LOAD_USERS, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadAllRoles = function() {
    return async dispatch => {
        try
        {
            const response = await userApi.apiUserRolesGet();
            dispatch({ type: LOAD_ROLES, payload: response.data });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadUserRole = function(userId) {
    return async dispatch => {
        try
        {
            const response = await userApi.apiUserRolesForUserUserIdGet(userId);
            dispatch({ type: LOAD_SELECTED_USER_ROLE, payload: response.data[0] });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const removeUserFromApplication = function(userId) {
    return async dispatch => {
        try
        {
            await userApi.apiUserRemoveFromApplicationGet(userId);
            dispatch({ type: REMOVE_USER, payload: userId });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const selectUser = function(user) {
    return dispatch => {
            dispatch({ type: SELECT_USER, payload: user });
    }
}

export const clearUserForm = function() {
    return dispatch => {
        dispatch({ type: CLEAR_USER_FORM });
    }
}

export const updateCurrentUserField = function(field, value) {
    return dispatch => {
        dispatch({ type: UPDATE_CURRENT_USER_FIELD, payload: {field, value} });
    }
}

export const toggleUserModal = function(showModal){
    return dispatch => {
        dispatch({type: TOGGLE_USER_MODAL, payload: showModal});
    }
}

export const saveUser = function() {
    return async (dispatch, getState) => {
        try
        {
            let response
            const user = getState().userAdmin.selectedUser;
            if (user.userId){
                response = await userApi.apiUserUpdatePost(user);
                await userApi.apiUserRemoveRoleUserIdRoleIdGet(user.userId, user.roleId);
            } else {
                response = await userApi.apiUserCreatePost(user);
                user.userId = response.data.userId;
            }
            await userApi.apiUserAssignToRoleRoleIdUserIdGet(user.roleId, user.userId);
            dispatch({ type: SAVE_USER, payload: user });
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}