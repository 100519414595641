import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrip, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useDropzone} from "react-dropzone";
import {useDispatch, useSelector} from "react-redux";
import {
    downloadAttachment,
    listAttachmentsForExchange, loadAttachmentFolders, moveAttachment,
    removeAttachment, selectFolder,
    uploadAttachment
} from "../../actions/exchange-attachment";
import {toggleSpinner} from "../../actions/ui";
import {AgGridReact} from "ag-grid-react";
import {defaultColDef, replacementPropertyTypeId} from "../../constants/settings";
import toast from "react-hot-toast";
import {generateDocument, loadDocumentMappings} from "../../actions/document-mappings";
import {loadExchangeProperties, selectPropertyAddress} from "../../actions/exchange";

export default function ExchangeAttachments(props) {

    const { exchangeId } = props;
    const dispatch = useDispatch();
    const attachments = useSelector(state => state.exchangeAttachment.attachments);
    const masterDocs = useSelector(state => state.documentMappings.documents);
    const folders = useSelector(state => state.exchangeAttachment.folders);
    const selectedFolder = useSelector(state => state.exchangeAttachment.selectedFolder);
    const properties = useSelector(state => state.exchange.properties);
    const replacementProperties = properties.filter(p => p.propertyTypeId === replacementPropertyTypeId);
    const [firstLoad, setFirstLoad] = useState(true);
    const selectedPropertyAddress = useSelector(state => state.exchange.selectedPropertyAddress);

    const DOWNLOAD_CONTROL = "ATTACHMENT_DOWNLOAD_CONTROL";
    const REMOVE_CONTROL = "ATTACHMENT_REMOVE_CONTROL";

    const columnDefs = [

        {
            headerName: '',
            cellRenderer: 'renderHandle',
            field: 'id',
            maxWidth: 100
        },
        {
            headerName: 'Document',
            cellRenderer: 'renderFilename',
            field: 'name'
        },
        {
            headerName: '',
            field: 'id',
            cellRenderer: 'renderDelete',
            maxWidth: 60
        }
    ];

    const generationColumns = [
        {
            headerName: 'Document',
            field: 'name'
        },
        {
            headerName: '',
            field: 'id',
            cellRenderer: 'GenerateButton',
            maxWidth: 100
        }
    ];

    useEffect(() => {
        async function loadScreen() {
            await dispatch(loadDocumentMappings());
            await dispatch(loadAttachmentFolders(true));
            if(firstLoad){
                await dispatch(loadExchangeProperties(exchangeId));
            } else {
                if(replacementProperties && replacementProperties.length > 0){
                    await dispatch(selectPropertyAddress(replacementProperties[0]))
                }
            }
            setFirstLoad(false);
        }
        loadScreen();
        // eslint-disable-next-line
    }, [dispatch, firstLoad, exchangeId]);



    useEffect(() => {
        if(exchangeId > 0 && selectedFolder.id > 0) {
            dispatch(listAttachmentsForExchange(exchangeId, selectedFolder.id));
        } else {
            if(folders.length > 0){
                dispatch(selectFolder(folders[0].id));
            }
        }
    }, [dispatch, exchangeId, selectedFolder.id, folders, folders.length]);

    const filesDropped = function (files) {
        files.forEach(f => {
            convertBase64(f)
                .then(base64 => {
                    let upload = {fileData: window.btoa(base64), name: f.name, exchangeId};
                    return dispatch(uploadAttachment(upload));
                }).then( r => {
                if(exchangeId > 0) {
                    toast.success("Document uploaded");
                    dispatch(listAttachmentsForExchange(exchangeId, selectedFolder.id));
                }
                }).catch(err => console.log(err));
        });
    }

    const {getRootProps, getInputProps, isDragAccept, isDragReject, open} = useDropzone({
        onDrop: filesDropped,
        accept: {'application/pdf' : ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-word.document.macroEnabled.12': ['.docm']},
        noClick: true
    });

    const renderFilename = function (ex) {
        const data = ex.data;
        return <div className={"btn btn-link text-xs btn-sm"} title={data.filename} onClick={(e) => downloadFile(data, e)}>{data.filename}</div>;

    }

    const renderDelete = function (ex) {
        const data = ex.data;
        return <button className={"btn-link text-danger btn m-0 p-0 ms-1"} title="Remove File" onClick={() => handleRemoveFile(data)}><FontAwesomeIcon icon={faTimesCircle}/></button>;
    }

    const downloadFile = async function (attachment, e) {
        e.stopPropagation();
        dispatch(toggleSpinner(DOWNLOAD_CONTROL + attachment.name))
        await dispatch(downloadAttachment(attachment));
        dispatch(toggleSpinner(DOWNLOAD_CONTROL + attachment.name))
    }

    const handleRemoveFile = function (attachment) {
        dispatch(toggleSpinner(REMOVE_CONTROL + attachment.name))
        dispatch(removeAttachment(attachment));
    }

    const handleSelectFolder = async function (folderId) {
        await dispatch(selectFolder(folderId));
    }

    const convertBase64 = file => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsBinaryString(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    const getActiveClass = function () {
        if (isDragAccept) {
            return {className: 'dropzone border-success p-2 my-1'}
        } else if (isDragReject) {
            return {className: 'dropzone border-danger p-2 my-1'}
        } else {
            return {className: 'dropzone border-white p-2 my-1'}
        }

    }

    const handleOpenFileDialog = function () {
        open();
    }

    const handleDrag = function (row, e) {
        e.dataTransfer.setData("documentId", row.id);
        e.target.classList.add("fw-bold");
    }

    const handleDragEnd = function (e) {
        e.target.classList.remove("fw-bold");
    }

    const handleDragEnter = async (e) => {
        e.preventDefault();
        //e.stopImmediatePropagation();
        e.target.classList.add("bg-light");
    }

    const handleDragLeave = async (e) => {
        e.preventDefault();
        //e.stopImmediatePropagation();
        e.target.classList.remove("bg-light");
    }

    const handleDrop = async (e, node) => {
        e.preventDefault();
        e.target.classList.remove("bg-light");
        const attachmentId = parseInt(e.dataTransfer.getData("documentId"));
        const folderId = parseInt(node.id);
        await dispatch(moveAttachment(attachmentId, folderId, node.name));
        await dispatch(listAttachmentsForExchange(exchangeId, selectedFolder.id));
    }

    const handleSelectedPropertyChanged = async function (e) {
        const address = replacementProperties.filter(rp => rp.id === parseInt(e.target.value))[0];
        dispatch(selectPropertyAddress(address))
    }

    const renderHandle = (ex) => {
        return <div className={"pt-1"} draggable="true" onDragStart={(e) => handleDrag(ex.data, e)} onDragEnd={handleDragEnd} title={"Drag to folder to move file"}><FontAwesomeIcon icon={faGrip} size={"2xl"}/></div>
    }

    return (<div className="card col-12 border-1 p-0 m-0 mt-2 border-dark shadow">
        <div className={"row m-0 p-0"}>
            <div className={"col-12"}>
                <div className={"fs-4"}>Attached Documents</div>
            </div>
        </div>
        <div className="row m-0 p-0 border-bottom">
            <div className={"col-3"}>
                <ul className={"nav nav-tabs left-tabs"}>
                    {folders.map((f, idx) => <li key={idx} className="nav-item" role="presentation" onDrop={(e) => handleDrop(e, f)} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
                        <div style={f.id !== selectedFolder.id ? {cursor: "pointer"} : {cursor: "default"}} className={f.id === selectedFolder.id ? "nav-link tab-clickable active small" : "nav-link tab-clickable small"} onClick={() => handleSelectFolder(f.id)}>{f.name}
                        </div>
                    </li>)}
                </ul>
            </div>
            <div className="col-8">
                { exchangeId ? <div {...getRootProps(getActiveClass())}>
                    <input {...getInputProps()} />
                    <div className={"text-center fw-bold"}>Drop file or <button className={"btn btn-link fw-bold link-dark p-0 m-0 pb-1"} onClick={handleOpenFileDialog}>click here</button> to upload a file to the selected folder.</div>
                    <div className={"ag-theme-alpine"}  style={{height: '30vh'}}>
                        <AgGridReact
                            components={{
                                renderFilename,
                                renderDelete,
                                renderHandle
                            }}
                            defaultColDef={defaultColDef}
                            suppressCellFocus={true}
                            rowData={attachments}
                            columnDefs={columnDefs}>
                        </AgGridReact>
                    </div>
                </div> : <div>&nbsp;</div>}
            </div>
            <div className="col-5">

            </div>
        </div>
        <div className={"row m-0 p-0"}>
            <div className={"col-4 text-center"}>
                <div className={"fs-4 mt-2"}>Generate New Document</div>
                <div className={"fst-italic"} style={{fontSize: ".7em"}}>Select Replacement Property from list at right to generate documents for that property</div>
            </div>
            <div className={"col-8 text-end pt-4"}>
                <select onChange={handleSelectedPropertyChanged} value={selectedPropertyAddress.id} className={"form-select form-select-sm"}>
                    {replacementProperties ? replacementProperties.map((p, idx) => <option key={idx} value={p.id}>{p.oneLineAddress}</option>) : null}
                </select>
            </div>
        </div>
        <div className={"row m-0 p-0"}>
            <div className={"col-12"}>
                <div className={"ag-theme-alpine"}  style={{height: '35vh'}}>
                    <AgGridReact
                        components={{
                            GenerateButton
                        }}
                        defaultColDef={defaultColDef}
                        suppressCellFocus={true}
                        rowData={masterDocs}
                        columnDefs={generationColumns}>
                    </AgGridReact>
                </div>
            </div>
        </div>
    </div>);
}

function GenerateButton (params) {
    const dispatch = useDispatch();

    const propertyAddressId = useSelector(state => state.exchange.selectedPropertyAddress.id);
    const exchangeId = useSelector(state => state.exchange.currentExchange.id);
    const generateNewDocument = async function (documentId){
        await dispatch(generateDocument(documentId, exchangeId, propertyAddressId));
        await dispatch(listAttachmentsForExchange(exchangeId));
    }

    return <button className={"btn-link text-dark btn-sm btn m-0 p-0 ms-1"} title="Generate File" onClick={() => generateNewDocument(params.data.id)}>Generate</button>;

}
