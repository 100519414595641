import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    clearSelectedFolder, deleteFolder,
    loadAttachmentFolders, saveAttachmentFolder,
    selectFolder,
    updateSelectedFolderField
} from "../../actions/exchange-attachment";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlus, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const DocumentFolderEditor = function (props) {

    const dispatch = useDispatch();

    const folders = useSelector(state => state.exchangeAttachment.folders);
    const selectedFolder = useSelector(state => state.exchangeAttachment.selectedFolder);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        async function setupScreen (){
            await dispatch(clearSelectedFolder());
            await dispatch(loadAttachmentFolders());
        }
        setupScreen();
    }, [dispatch]);

    const closeModal = async function () {
        setShowModal(false);
        await dispatch(clearSelectedFolder());
    }

    const handleFieldChange = async function (e) {
        dispatch(updateSelectedFolderField(e.target.name, e.target.value));
    }

    const handleSaveFolder = async function () {
        if(!selectedFolder.name){
            window.alert("Folder must have a name.");
            return;
        }
        await dispatch(saveAttachmentFolder(selectedFolder));
        await dispatch(loadAttachmentFolders());
        setShowModal(false);
    }

    const handleCancelEdit = async function () {
        await closeModal();
    }

    const handleDeleteFolder = async function () {
        if(window.confirm("This folder will be permanently deleted.")){
            await dispatch(deleteFolder(selectedFolder.id));
            await dispatch(loadAttachmentFolders());
            setShowModal(false);
        }
    }

    const handleSelectFolder = async function (folder) {
        if(folder.name === "General") return;
        await dispatch(selectFolder(folder.id));
        setShowModal(true);
    }

    return (<div className="card border-1 shadow my-5 col container-card p-2">
        <div className="row p-0 m-0 mt-2">
            <div className="col-12 m-0 p-0">
                <div className={"row p-0 m-0 justify-content-center"}>
                    <div className={"col-6"}>
                        <div className={"row m-0 p-0 mb-3"}>
                            <div className={"col-9"}>
                                <h4>Attachment Folder Editor</h4>
                            </div>
                            <div className={"col-3"}>
                                <button className={'btn btn-sm btn-primary'} onClick={() => setShowModal(true)}><FontAwesomeIcon icon={faPlus}/>&nbsp;Add Folder</button>
                            </div>
                        </div>
                        {folders.map((f, idx) => <div key={idx}>
                            <div className={"row m-0 p-1 mb-1 border-bottom border-dark"}>
                                <div className={"col-9"}>
                                    {f.name}
                                </div>
                                <div className={"col-3"}>
                                    <button className={f.name === "General" ? 'display-none' : 'btn btn-sm btn-primary'} onClick={() => handleSelectFolder(f)}><FontAwesomeIcon icon={faPencil}/>&nbsp;Edit</button>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
        <Popup open={showModal} closeOnDocumentClick onClose={closeModal}>
            <div className={"tag-modal"}>
                <button className="btn btn-link link-dark text-decoration-none m-0 p-0 close" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimesCircle}/>
                </button>
            <div className={"header"}>{selectedFolder.id === 0 ? "Add New Folder" : "Update Folder"}</div>
                <div className={"content"}>
                    <div className="mb-1">
                        <input name="name" className="form-control form-control-sm" placeholder={"Name"} value={selectedFolder.name} onChange={handleFieldChange}/>
                    </div>
                    <div className={"text-end"}>
                        <button className={'btn btn-sm btn-primary me-1'} onClick={handleSaveFolder}>Save Changes</button>
                        <button className={'btn btn-sm btn-danger me-1'} onClick={handleDeleteFolder}>Delete Folder</button>
                        <button className={'btn btn-sm btn-secondary'} onClick={handleCancelEdit}>Cancel</button>
                    </div>
                </div>
            </div>
        </Popup>
    </div>);
}

export default DocumentFolderEditor;
