import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllExchanges} from "../../actions/exchange";
import {faPlusCircle, faSpinner, faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../loading";
import {AgGridReact} from "ag-grid-react";
import {Link, useNavigate} from "react-router-dom";
import {defaultColDef, headerHeightSetter} from "../../constants/settings";
import {clearExchange} from "../../actions/ui";

export const ExchangeList = function () {

    const dispatch = useDispatch();
    const allExchanges = useSelector(state => state.exchange.allExchanges);
    const spinningControl = useSelector(state => state.ui.spinning);
    const currentControl = "ExchangeList";
    const isSpinning = spinningControl === currentControl;
    const gridRef = useRef();
    const navigate = useNavigate();
    const token = useSelector(state => state.session.token);
    const [showClosed, setShowClosed] = useState(false);

    const columnDefs = [
        {
            headerName: 'Order No.',
            cellRenderer: 'renderColumn',
            field: 'exchangeId',
        },
        {
            headerName: 'Exchange Officer',
            cellRenderer: 'renderColumn',
            field: 'exchangeOfficer',
        },
        {
            headerName: 'Relinquished Property Address',
            cellRenderer: 'renderAddress',
            field: 'propertyAddressAddress1',
        },
        {
            headerName: 'Taxpayer',
            cellRenderer: 'renderColumn',
            field: 'taxpayer',
        },
        {
            headerName: 'Buyer',
            cellRenderer: 'renderColumn',
            field: 'buyer',
        },
        {
            headerName: 'Transaction Type',
            field: 'exchangeType',
        },
        {
            headerName: '45 Days',
            cellRenderer: 'renderDate',
            field: 'fortyFiveDays',
        },
        {
            headerName: '180 Days',
            cellRenderer: 'renderDate',
            field: 'oneEightyDays',
        }
    ];


    useEffect(() => {
        async function loadScreen() {
            if(token){
                await dispatch(clearExchange());
                await dispatch(getAllExchanges());
            }
        }
        loadScreen();
    }, [dispatch, token]);

    const sizeToFit = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const handleRowClicked = (e) => {
        navigate("/order/" + e.data.id + "/detail");
    }

    const renderAddress = function (ex) {
        const obj = ex.data;
        if (obj.address1){
            return <div>{obj.address1} {obj.city}, {obj.state} {obj.postalCode}</div>
        } else {
            return <div className={"opacity-25"}>Not Set</div>
        }

    }

    const renderDate = function (ex) {
        if(ex.value) return <div>{new Date(ex.value).toLocaleDateString()}</div>
        return <div className={"opacity-25"}>Not Set</div>
    }

    const renderColumn = function (ex) {
        if(ex.value) return <div>{ex.value}</div>
        return <div className={"opacity-25"}>Not Set</div>
    }

    const handleClosedFilter = async function (newfilter){
        await dispatch(getAllExchanges(newfilter))
        setShowClosed(newfilter);
    }

    return (<div className="card border-0 shadow my-5 p-2">
        <div className="card col-12 border-1 border-dark p-0 m-0 shadow">
            <div className="row m-0 p-0">
                <div className="col-1 p-0 pt-3 pt-2 header-column">
                    <h1 className="m-0 p-0 py-2 px-4">Orders</h1>
                </div>
                <div className="col-11 float-end border-start border-dark border-end-0 border-top-0 border-bottom-0 blue-body pt-1 pb-0">
                    {isSpinning ? <div className="text-center mt-5"><FontAwesomeIcon size="1x" className={"fa-spin"} icon={faSpinner} /></div> :
                        <div className={"ag-theme-alpine"}  style={{height: '80vh'}}>
                            <div className={"row p-0 m-0 pb-2"}>
                                <div className={"col-2 p-0 m-0"}>
                                    <button className={showClosed ? "btn btn-sm btn-success" : "btn btn-sm btn-outline-secondary"} onClick={() => handleClosedFilter(!showClosed)}>Closed Orders Only</button>
                                </div>
                                <div className={"col-10 p-0 m-0"}>
                                    <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-outline-dark"} onClick={() => sizeToFit()}><FontAwesomeIcon size="1x" icon={faUndo} />&nbsp;Reset Columns</button>
                                        <Link className={"btn btn-sm btn-outline-dark ms-2"} to={"/orders"}><FontAwesomeIcon size="1x" icon={faPlusCircle} />&nbsp;New Order</Link>
                                    </div>
                                </div>
                            </div>
                            <AgGridReact
                                components={{
                                    renderAddress,
                                    renderDate,
                                    renderColumn
                                }}
                                defaultColDef={defaultColDef}
                                ref={gridRef}
                                rowData={allExchanges}
                                onRowClicked={handleRowClicked}
                                suppressCellFocus={true}
                                onFirstDataRendered={headerHeightSetter}
                                onColumnResized={headerHeightSetter}
                                columnDefs={columnDefs}/>
                        </div>
                     }
                </div>
            </div>
        </div>
    </div>);
}

export default withAuthenticationRequired(ExchangeList, {
    onRedirecting: () => <Loading />,
});
